import { type IDateText, type IRankingDateText } from '../interfaces';
// This is used in main banner to advertise the next tournament
export const SPACE = '     ';

export const dates = {
  chinese: `1月25–26日${SPACE}上午10:00 – 下午10:00`,
  english: `2024.01.25–26${SPACE}10:00am – 10:00pm`
};

// This is used to keep history of tournament dates
export const datesArray: IDateText[] = [
  {
    chinese: `1月25–26日${SPACE}上午10:00 – 下午10:00`,
    english: `2024.01.25–26${SPACE}10:00am – 10:00pm`
  },
  {
    chinese: `12月21–22日${SPACE}上午10:00 – 下午10:00`,
    english: `2023.12.21–22${SPACE}10:00am – 10:00pm`
  },
  {
    chinese: `11月23–24日${SPACE}上午10:00 – 下午10:00`,
    english: `2023.11.23–24${SPACE}10:00am – 10:00pm`
  },
  {
    chinese: '10月26-27日     上午10:00-下午10:00',
    english: '2023.10.26-27   10:00am-10:00pm'
  },
  {
    chinese: '9月27-28日 | 上午10:00-下午10:00',
    english: '27-28 Sep | 10:00 a.m - 10:00 p.m'
  },
  {
    chinese: '8月24-25日 | 上午10:00-下午10:00',
    english: '24-25 Aug | 10:00 a.m - 10:00 p.m'
  }
];

// This is used in the rankings banner to flick through tournament dates
export const rankingDatesArray: IRankingDateText[] = [
  {
    chinese: '2024 年 1 月',
    english: 'Jan 2024',
    date1Chinese: '25日',
    date2Chinese: '26日',
    date1English: '25th',
    date2English: '26th'
  },
  {
    chinese: '2023 年 12 月',
    english: 'Dec 2023',
    date1Chinese: '21日',
    date2Chinese: '22日',
    date1English: '21st',
    date2English: '22nd'
  },
  {
    chinese: '2023 年 11 月',
    english: 'Nov 2023',
    date1Chinese: '23日',
    date2Chinese: '24日',
    date1English: '23rd',
    date2English: '24th'
  },
  {
    chinese: '2023 年 10 月',
    english: 'Oct 2023',
    date1Chinese: '26日',
    date2Chinese: '27日',
    date1English: '26th',
    date2English: '27th'
  },
  {
    chinese: '2023 年 9 月',
    english: 'Sep 2023',
    date1Chinese: '27日',
    date2Chinese: '28日',
    date1English: '27th',
    date2English: '28th'
  },
  {
    chinese: '2023 年 8 月',
    english: 'Aug 2023',
    date1Chinese: '24日',
    date2Chinese: '25日',
    date1English: '24th',
    date2English: '25th'
  }
];

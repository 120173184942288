export const jp = {
  gog: 'ギャンブラーの神',
  introduction: '序章',
  award: '賞',
  details: '詳細',
  faq: 'よくある質問',
  new: '新しい',
  topMaster: 'トップマスター',
  backToTop: 'トップに戻る',
  introductionDetails: 'AGマスタートーナメントは、定番ゲーム、' +
    'バカラの中で一番人気の”シット&ゴー”をベースにしています。' +
    '一般的な公平かつ透明性のあるルールを採用しているため、全てのタイプのプレイヤーが楽しめる内容になっています。',
  howToEnter: '参加方法',
  gameProcess: 'プロセス',
  step: 'ステップ',
  step1: '本トーナメントではAG現金アカウントのみご使用いただけます。',
  step2: 'マスタートーナメントのロビーおよびお好きなトーナメントを選択してください。',
  howToJoin1: '本トーナメントではAG現金アカウントのみご使用いただけます。',
  howToJoin2: 'マスタートーナメントのロビーおよびお好きなトーナメントを選択してください。',
  satelliteTournament: 'サテライトトーナメントの開始時刻は決まっていません。テーブルに人数が揃い次第、' +
    'シット&ゴーを開始します。ゲームに勝つとマスタートーナメント券がもらえます。',
  masterTournament: 'マスタートーナメントは開始時刻が決まっています。',
  seasonTournament: '四半期トーナメントは指定の時間にて開始します。' +
    'シーズン中のマスター・トーナメント勝者を招待し、ポイントを保持しているプレイヤー同士が競います。チャンピオンには賞金を進呈致します。',
  awardDetails: 'すべての賞金は、直ちに該当者のアカウントに直接振り込まれます。',
  qualifyingRound: 'サテライトトーナメント',
  qualRoundText: 'サテライトトーナメントの開始時刻は決まっていません。テーブルに人数が揃い次第、シット&ゴーを開始します。ゲームに勝つとマスタートーナメント券がもらえます。',
  gogTickets: 'マスター トーナメント チケット\nまたは必要なユーザー ポイント',
  gogMasterTournament: 'マスタートーナメント',
  gogStep3: 'マスタートーナメントは開始時刻が決まっています。',
  tournamentRules: 'トーナメント規則',
  fairness: '公平性',
  regAndWithdrawal: '登録 / 登録取消',
  inGame: 'ルール',
  ranking: 'ランキング',
  withdraw3: '登録後（定員に達していない場合）、大会開始前に辞退する場合',
  withdraw3Child: 'プレイヤーはすぐに辞退することができ、3 分以内に同じ種類のトーナメントに再度登録することはできず、システムにより差し引かれたポイント/チケットが返還されます。',
  withdraw4: '登録（参加者数に達した）後にトーナメントが開始されていない場合に辞退した場合:',
  withdraw4Child: '参加者が棄権するにはトーナメントに参加する必要があり、参加者はシステムによって敗退したものとしてカウントされます。 差し引かれたユーザーポイントやチケットは返金されません。',
  withdraw5: '大会途中で引退を決意する参加者たち',
  withdraw5Child: 'トーナメント中に、参加者の誰かが別のゲームを選択するか、トーナメントから退出した場合、順位と勝利の機会は、参加者が行った有効なベット数とラウンド数によって判断されます。',
  withdraw6: 'プレイヤーは、そのゲームを終了した後、同じゲームを再登録することはできません。',
  withdraw7: '各プレーヤーは、同じ日に登録されたトーナメントから最大 5 回までのみ辞退できます。5 回を超えてプレーヤーが同じ種類のトーナメントに登録することはできません。',
  tournamentRules1: 'プレイヤーがトーナメント参加登録を行った後、それぞれのトーナメント/テーブルごとに定められているスタートチップ（以下「ユーザーポイント」）でゲームが開催されます。トーナメントチップはアカウント残高、ポイントとは関係ありません。また、トーナメントチップが次のセッションへ繰越されることはありません。プレイヤーのアカウントポイントが不足している場合、通常テーブルで有効なベットを行うことによりポイントを獲得できます;',
  tournamentRules2: 'システムの公平性を保つため、プレイヤーがトーナメントに参加登録をした後、シートはランダムに決定されます。シートの選択、交換はできません。',
  tournamentRules3: 'キャッシュプライズ（賞金）は記載されている賞金配分表に従い、アカウントにクレジットされます;',
  tournamentRules4: 'トーナメントはノン・コミッションバカラのルールに基づきます。バカラのルール詳細はメニュー > ゲームからご確認いただけます。参加者は各ハンドにつき25秒間の持ち時間が与えられ、各ハンドごとに必ずバンカー/プレイヤーのいづれかにベットしなければなりません。また、バンカー/プレイヤーのいづれかにベットする前にタイ/ペアにはベットできません。',
  tournamentRules5Part1: '予選ラウンドの開始前、各テーブルに着いた際、100,000相当のトーナメントチップが与えられます（トーナメントチップはトーナメント内のみ使用されるチップであり換金できません）このトーナメント中のベット上限は1,000-10,000となります。',
  tournamentRules5Part2: '手持ちのトーナメントチップを超えるベットはできません。各ハンドのベット上限は以下をご覧ください；',
  betItem: 'ベットアイテム',
  minBet: '最小ベット',
  maxBet: '最大ベット ',
  banker: 'バンカー',
  player: 'プレイヤー',
  tie: 'タイ',
  pair: 'ペア',
  tournamentRules6: '全てのトーナメントでは、最初の2ハンドをスキップしたの後にゲーム開始となります。シューターが再度シャッフルされることはありません。',
  tournamentRules7: '公平性を保つため、トーナメントは以下の昇格、除外ルールを設けています:',
  tournamentRules7_1: 'ゲーム中、システムはバーチャルテーブルから一定数のプレイヤーが昇格するようルールが設定され、それぞれのラウンドで昇格できなかったプレイヤーは自動的に除外となります。',
  tournamentRules7_2: '（例）: 参加者56人が8つのバーチャルテーブル(A、B、C、D、E、F、G 、H )に振り分けられ、ゲームは6つのセクションに分けられます（各セクション6ゲーム）。それぞれのセクションの上位3名が昇格することができます。 ( A ~ H テーブルで上位3名がトーナメント終了まで昇格。）',
  tournamentRules7_3: '昇格したプレイヤーはトーナメントチップにおいてアドバンテージを受けることができます: 各テーブルから2名が昇格できるとした場合。プレイヤーが次のラウンドで1位となり昇格した場合、各テーブルの1位のプレイヤーは一定%スターティングチップ（トーナメントチップ）が増加します (1位のプレイヤーは他のプレイヤーよりも多くのスターティングチップを受け取れます。)',
  tournamentRules7_4: 'システムは可能な限り各テーブルの人数が同数となるように振り分けます。各ラウンドで昇格したプレイヤーは再度、システムがランダムで次のテーブルに振り分けます。',
  tournamentRules8: 'トーナメント開始前に、以下の特別ベット機能が参加者に配られます:',
  tournamentRules8_1: 'ブラインドベットはベット戦術の一つで、主にベット額を他のプレイヤーに隠すために用いられます。隠されたベット額はハンドが完成する前に公開されます。ブラインドベットができる回数はトーナメントのサイクル次第です。（例）合計サイクル : 5 ブラインドベットの回数: 5',
  tournamentRules8_2: '“パスベット”はそのラウンドのベットをスキップすることができます。プレー中のラウンドでベットしたくない場合、\'パスベット\'ボタンをクリックすることでそのラウンドのベットをスキップできます。例）合計サイクル : 5 パスベットの回数: 5',
  tournamentRules9: 'トーナメントセッション終了後、全ての使用/未使用チップや、特別ベット機能はリセットされます。また、トーナメントチップは参加者間での貸し借りなどの移動はできません。',
  tournamentRules10: '下の優先条件を元にランキングを決定します: ',
  tournamentRules10_1: '昇格プレイヤー＞未昇格プレイヤー　その次に最終的に残ったチップ数に応じてランキンングが決定されます。',
  tournamentRules10_2: 'トーナメントチップ数が同数だった場合、有効なトーナメントチップ数が多い方が高い順位となります：',
  tournamentRules10_3: '上記2つのルールを適用しても尚、複数のプレイヤーが同立順位となった場合、トーナメントへの参加登録が早かったプレイヤーが優位とします。',
  tournamentRules11: 'なんらかの理由によりテーブルを離れる場合、最大5ハンドまでの期間、離れることができます；5ハンドを超えた場合、賞金獲得の権利は失われますが、ランキングには参加できます。',
  tc: '利用規約',
  tc1: 'トーナメントに参加できるのはAGリアルマネーアカウントのみです。',
  tc2: 'トーナメント期間中に\'サテライトトーナメント\'に参加する場合、プレイヤーアカウントのポイントを使用する必要があります;',
  tc3: 'トーナメントは各トーナメントロビーに記載されている開始時間に開始しますが、188BETは予告なくこの時間の変更、トーナメントの中止を行う権利を有します。',
  tc4: 'ユーザーポイントはライブゲームで有効ベットを行うことで獲得できます（全タイプのバカラ、ドラゴンタイガー、シックボー、ルーレット、ブラックジャック、ウィン・スリーカード、BID、マルチプレー、VIP、グッドロード・ベッティング）ユーザーポイントの詳細は”ユーザーポイント”をクリックしてください。',
  tc5: '“サテライトトーナメント” の勝者は”マスタートーナメント”のチケットを獲得できます。（チケット数には限りがあるため無くなり次第終了となります。） チケットは今月のイベントでご使用いただけます。',
  tc6: '各アカウント、IPアドレスにつき1名まで参加可能です。違反した場合は失格とし、厳正に対処します。',
  tc7: 'ヘッジングなどの不正行為はポリシーポイント 6 違反となります；',
  tc8: '競技ルールを違反した場合、参加権を剥奪します。',
  tc9: 'AGのアクティビティに参加した時点でそれらの定められ利用規約に同意したものとします。',
  tc10: 'AG はこのアクティビティに関する全権限を有します。',
  remark: '備考',
  registration: '登録',
  registration1: 'プレイヤーがマスタートーナメントのチケット又は十分なユーザーポイントの両方を持っている場合、ユーザーポイント又はマスタートーナメントチケットどちらを引くか選択できます。',
  registration2: 'トーナメント開始20分前以内はサテライト、マスタートーナメントのいづれか一方しか参加登録できません。',
  registration2_1: '参加登録したトーナメントの開始20分前からは、他のサテライトトーナメントに参加登録できません。',
  registration2_2: 'マスタートーナメントが20分後、もしくはそれ以降に開始した場合、プレイヤーはサテライトトーナメントに参加登録できます。マスタートーナメントが20分以内に開始した場合、事前に登録していたトーナメントで使用されたアカウントポイントは返還されます。',
  cancelReg: '登録取消',
  cancelReg1: '各プレイヤーはトーナメント開始前に登録を取り消すことができます。登録を取り消してから3分以内は同じトーナメントセッションに再度登録することができません。',
  cancelReg2: 'トーナメント中にプレイヤーが他のゲームを選択したりトーナメントから抜けた場合、ランキング、賞金の獲得が可能かどうかはそれまでに行われた有効ベット数、ラウンド数を判断材料とし決定されます。',
  watch: '観戦',
  watch1: '“観戦”を選択することで、トーナメントのゲームを観戦することができます。観戦モード中はベットすることはできませんが、テキストメッセージ機能は使用できます。',
  exception: '例外',
  exception1: 'サーバーのクラッシュやシステム異常なででトーナメントが中止、または円滑に行うことができなくなった場合、弊社はトーナメントのやり直しを行うとともに、ポイント、チケットの返却を行います。',
  exception2: 'トーナメント開始後、プレイヤー側のネットワーク障害により切断された場合、可能な限り早急に再ログインし、５ラウンドが経過する前にトーナメントに戻ってください。この５ラウンド間は自動ベット機能により最小ベット額がベットされ続けます。５ラウンド以内に戻らなかった場合、自動的にトーナメント敗退となります。',
  exception3: 'AGはヘッジングを継続的に行ったユーザーをトーナメント失格とし、賞金獲得の権利も剥奪する権利を有します。また、そのようなユーザーはブラックリスト入りとなり、2度とAG開催のトーナメントに参加できません。',
  q1: '四半期トーナメントへの参加方法は？',
  faq1: '四半期トーナメントへの参加方法：四半期トーナメントの参加チケットを1枚入手、またはポイントを貯めれば参加は可能です。\n\n四半期トーナメント・チケット入手方法：シーズンのマスター・トーナメント勝者は、トーナメント開催前にチケットを受け取ります。尚、チケットは今期のトーナメントでのみ有効です。',
  q2: 'トーナメントロビーはどこですか？',
  faq2: 'AGデスクトップ版にログインすると、マスタートーナメントタブがありますので、クリックするとトーナメントロビーにアクセスできます。トーナメントタブが表示されていない場合、オペレイター、またはカスタマーサポートにお問い合わせください。モバイルHTML5版はデスクトップ版と似ており、トーナメントロビーへのアクセスボタンが見つかります。',
  q3: '参加登録方法は?',
  faq3: 'トーナメントロビーに行き”登録をクリックしてください。',
  q4: 'トーナメントへの参加条件はありますか？',
  faq4: 'AGリアルマネーアカウント開設し、アカウントにトーナメント参加に必要なポイント、チケットを保有している必要があります。',
  q5: 'ポイントの換算レートは？',
  faq5: 'RMB100 有効ベット = 1 ポイント。詳細はユーザーポイントをご確認ください。\n例:\nプレイヤー A：有効ベット 10,000 の場合100ポイント獲得。\nプレイヤー B：有効ベット8,650の場合86ポイント獲得。\nポイントは少数以下切り捨ての整数で数えられます。',
  q6: 'ポイント残高はどこで確認できますか？',
  faq6: 'アバターの横にあるインフォメーションボタン (i) をクリックすると情報が表示されます。\nまたはマスタートーナメントロビーの右上にもポイント残高が表示されます。',
  q7: 'もっとポイントを獲得する方法は?',
  faq7: 'アカウントポイントは、全ライブベットゲームにおける有効ベット額に応じて獲得できます。バカラ、ドラゴンタイガー、シックボー、ルーレット、ブラックジャック、ブルブル、ウィン・スリー・カード、BIDバカラ、マルチプレー、VIPバカラ、グッド・ロード・ベッティング\n\n※ アンカーへのチップやギフト、または非ライブゲームへのベットはポイントとなりません。',
  q8: '有効/無効ベットとは？',
  faq8: '有効ベット：全ての勝ち負けの結果が出たベット\n無効ベット：確率が2分の１のゲームにおいて勝ち負けの両方にベットしたもの。\n（例）：バカラにおいてプレイヤー、バンカーの両方にベットした場合。',
  q9: 'サテライトトーナメントとマスタートーナメントの違いは？',
  faq9: 'サテライトトーナメント: 決まった開始時間はなく、テーブルに必要人数が集まった時点でゲームが開始。\n勝者はマスタートーナメントの参加チケットを獲得可能：マスタートーナメントはあらかじめ設定された時間にトーナメントが開始します。',
  q10: '参加費はいくら?',
  faq10: 'AGリアルマネーアカウントを保有しているプレイヤーは参加費を支払う必要はありません。トーナメントに参加する場合はアカウントポイント、チケットが必要になります。',
  q11: 'マスタートーナメントチケットの入手方法は？',
  faq11: 'ユーザーポイントを使用してサテライトーナメントに参加し、ランキング1位になるとマスタートーナメントへのチケットを獲得できます。\n※ マスタートーナメントのチケットはおひとり様10枚までとなります。マスタートーナメントに参加するための今月のイベントでのみご使用いただけます。イベント終了時に未使用のチケットは無効となります。チケットの返金やポイント交換等はできかねます。',
  q12: '.ユーザーポイントだけを使って マスタートーナメントを獲得できますか？',
  faq12: '可能ですが、大量のユーザーポイントを消費します。\nマスタートーナメント参加ボタンをクリックすると、システムが自動的に参加に必要なユーザーポイントをアカウントから引き落とします。',
  q13: 'ユーザーポイント又はチケットのどちらが先に引かれるか選べますか？',
  faq13: 'サテライトトーナメントはユーザーポイントのみ引かれます。マスタートーナメントは、ユーザーポイント又はマスタートーナメントチケットのどちらが引かれるか選べます。',
  q14: 'トライアルアカウントでトーナメントに参加できますか？',
  faq14: 'トーナメントに参加できるのはAGリアルマネーカウントのみとなり、トライアルアカウントではトーナメント観戦ノードしか利用できません。AGリアルマネーアカウントを開設されたい場合はカスタマーサポートまでご連絡ください。',
  q15: 'なぜ参加登録失敗のアラートがくるのですか？',
  faq15: '参加登録の失敗は以下の理由が考えられます：\nアカウントがAGリアルマネーアカウントではない、または参加に必要な条件が揃っていない。\n事前に参加登録したが開始前にキャンセルしたため、3分間の待機ペネルティーがかかっている。',
  q16: '参加登録後に参加をキャンセルできますか？',
  faq16: '参加登録後で (必要参加人数が集まっていない状態)トーナメント開始前：\nキャンセル可能ですが、キャンセル後３分間は同じトーナメントに参加登録できません。\n参加登録後で (必要人数が集まっている場合) トーナメント開始前：\n一旦トーナメントに参加し、トーナメントをキャンセル必要があります。\nその際、ユーザーポイント、チケットは返却されません：\n途中棄権する場合、ランキングおよび勝率は、有効ベットおよびラウンド数により決定します。\n注意：\n1. 一度キャンセルしたトーナメントは再度参加することはできません;\n2. 参加登録済みのトーナメントをキャンセルできるのは日に５回までです。５回以上キャンセルを行なった場合は同じタイプのトーナメントに参加登録できません。',
  q17: '参加登録後、トーナメントが開始するまでロビーで待つ必要がありますか？',
  faq17: 'その必要はありません、他のゲームをプレーすることも可能です。開始時間が近づくと通知があります。',
  q18: '各トーナメントの開催時間は？',
  faq18: '各トーナメント5分から20分間開催されます。',
  q19: 'トーナメントではリアルマネーを使用しますか？',
  faq19: 'いいえ、トーナメントチップのみです。トーナメントチップとリアルマネーの残高は関係ありません。',
  q20: 'トーナメントチップとは?',
  faq20: 'AG マスタートーナメントでは全ての参加者が公平な条件の下でゲームできるよう、トーナメントのみで使用する独自のチップシステムを採用しています。リアルマネーの残高とは関係なく、トーナメントが終了すると削除されます。',
  q21: '観戦モード中にベットできますか？',
  faq21: '参加登録をして参加しているプレイヤーのみベットできます。参加したい場合は”登録”ボタンをクリックしてくだささい。',
  q22: '”オンライン”の表す意味は?',
  faq22: 'トーナメントに参加登録している人数の合計数。',
  q23: 'ベットエリアごとのベット上限とは？',
  betLimit: 'ベットリミット',
  bankerPair: 'バンカーペア',
  playerPair: 'プレイヤーペア',
  q24: 'なぜ “ペア” と “タイ”にベットできないのですか?',
  faq24: 'ペア、またはタイにベットする前に、バンカーかプレイヤーにベットする必要があります。',
  q25: 'ブラインドとは？',
  faq25: 'ブラインドはベットテクニックの一つで、主にベット額を隠すことが目的です。ハンドが完成する前にベット額は公開されます。',
  q26: 'パスとは？',
  faq26: 'パスとは、そのラウンドにベットせずスキップすることです。”パス”ボタンをクリックすることでパスできます。',
  q27: 'パス、ブラインドの数は蓄積されますか？',
  faq27: '使用、未使用に関わらず、トーナメント期間のみ蓄積され、トーナメント終了と同時にリセットされます。',
  q28: 'マスタートーナメントでの昇格、除外はどのようにして決定されますか？',
  faq28: '公平に進行するため、 マスタートーナメント では以下の昇格、除外に関するルールを設けています：\n各ラウンドで一定数の昇格プレイヤーが出るようシステムが管理し、昇格されなかったプレイヤーは除外となります。（例）: 参加者56人が8つのバーチャルテーブル(A、B、C、D、E、F、G 、H )に振り分けられ、ゲームは6つのセクションい分けられます（各セクション6ゲーム）。それぞれのセクションの上位3名が昇格することができます。 ( A ~ H テーブルで上位3名がトーナメント終了まで昇格。',
  q29: 'ランキングの決定方法は？',
  faq29: 'ランキング決定の際は以下の優先度通りに決定します:\n昇格した参加者はその他の参加者よりも高い順位となり、最終的に残ったトーナメントチップ数に応じて決定されます：\nトーナメントチップ数が同数だった場合、有効なトーナメントチップ数が多い方が高い順位となります：\n上記2つのルールが適用された後に複数のプレイヤーが同順位となった場合、トーナメント参加登録が早かったプレイヤーが優位とします;',
  q30: 'プライズ、キャッシュの配布方法は？',
  faq30: '”詳細”ボタンをクリックしてご確認ください。',
  q31: 'トーナメントで獲得した賞金の受け取り方法は？',
  faq31: 'トーナメント中に発生した賞金は即座にアカウントにクレジットされます。',
  q32: '2ndラウンドが始まった際に自分よりスターティングチップが多いプレイヤーがいるのはなぜですか？',
  faq32: '全てのプレイヤーのチップは次のラウンドが開始する前に増加します。',
  q33: 'マッチがキャンセルとなった場合、引き落とされたポイントやチケットは返却されますか？',
  faq33: 'はい、返却されます。',
  q34: 'ベット履歴の確認方法は？',
  faq34: ': “カスタムサービス” > “アカウントレコード” >  “マッチベット履歴”からご確認いただけます。',
  q35: '”トップマスター”とは?',
  faq35: 'Tトップマスターはそれぞれのマスタートーナメントで優勝したプレイヤーの名前、賞金を表示しています。名前をクリックすると、そのプレイヤーが優勝したトーナメント、賞金額を確認できます。',
  q36: 'トーナメント中にネットワークが切断れた場合はどうなりますか？',
  faq36: 'この場合はシステムの自動ベット機能が作動し、5ラウンドまでは最小ベットでベットし続けます。5ラウンドを経過してもトーナメントに戻らなかった場合じゃ除外となります。ランキング、賞金が獲得可能かどうかはプレーしたラウンド、セッションを判断材料として決定されます。',
  topMasterCongrats: 'AGマスタートーナメント勝者の皆様おめでとうございます。',
  tournamentSetting: '設定',
  others: '他の',
  regQ3Faq: 'トーナメント中、すべての AG リアルマネー アカウントは登録料や手数料を支払う必要がありません。 マスター トーナメントに登録するには、十分なポイントまたはマスター トーナメント チケットが必要です。'
};

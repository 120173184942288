import React, { useEffect, useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import mobileStep1 from '../assets/introduction/mob_mt_misc_step_btnoff.webp';
import mobileStepOn from '../assets/introduction/mob_mt_misc_step_btnoff1.webp';
import mobileStep2 from '../assets/introduction/mob_mt_misc_step_btnoff2.webp';
import TournamentLogoEN from '../assets/introduction/web_mt_logo.webp';
import AGLogo from '../assets/introduction/web_mt_misc_logo_ag.svg';
import stepSkeleton from '../assets/introduction/web_mt_misc_step_btnoff.webp';
import stepOn from '../assets/introduction/web_mt_misc_step_on.webp';
import gogVideoEn from '../assets/videos/gog_mt_en_new_year_comp.mp4';
import gogVideo from '../assets/videos/gog_mt_hans_new_year_comp.mp4';
import { dates } from '../data';
import { type IIntroduction } from '../interfaces';

import '../styles/Introduction.scss';
export const Introduction =
  ({ isMobileView, t, isChinese }: IIntroduction): JSX.Element => {
    const [step, setStep] = useState<number>(1);
    const [moveCards, setMoveCards] = useState<boolean>(false);
    const videoRef = useRef<HTMLVideoElement>(null);

    const dateOfNextTournament = (): string => {
      // if (isCNTrad) return '敬請期待';
      // if (isCNSimp) return '敬请期待';
      // return 'Stay Tuned';

      if (isChinese) return dates.chinese;

      return dates.english;
    };

    useEffect(() => {
      function checkScroll (): void {
        let section = document.getElementById('mobile-steps');
        let cardsContainerSection = null;
        if (!isMobileView) {
          section = document.getElementById('step-skeleton');
          cardsContainerSection = document.getElementById('cards');
        }

        if (section == null) return;

        const scrollHeight = section.getBoundingClientRect().top;

        const point1 = isMobileView ? 125 : 190;
        const point2 = isMobileView ? -125 : 5;
        const point3 = isMobileView ? -250 : -160;

        if (scrollHeight >= point1) setStep(1);
        if (scrollHeight >= point2 && scrollHeight < point1) setStep(2);
        if (scrollHeight >= point3 && scrollHeight < point2) setStep(3);

        if (cardsContainerSection == null) return;

        const cardsScrollHeight = cardsContainerSection
          .getBoundingClientRect().top;

        if (cardsScrollHeight <= window.innerHeight * 0.5) {
          setMoveCards(true);
        }
      }

      const videoUpdate = (): void => {
        const currentTime = videoRef?.current?.currentTime;
        const videoText = document.getElementById('mt-date');

        if (currentTime !== undefined && currentTime >= 23) {
          if (videoText != null) {
            videoText.style.display = 'block';
            videoText.classList.add('fade-in-text');
          }
        };

        if (currentTime !== undefined && currentTime < 23) {
          if (videoText != null) {
            videoText.style.display = 'none';
            videoText.classList.remove('fade-in-text');
          }
        };
      };

      // Text obscures the player controls in mobile view
      const endedVideo = (): void => {
        if (!isMobileView) return;
        const videoText = document.getElementById('mt-date');

        if (videoText != null) videoText.style.display = 'none';
      };

      const videoElement = document.querySelector('#mt-video');
      videoElement?.addEventListener('timeupdate', videoUpdate);
      videoElement?.addEventListener('ended', endedVideo);
      window.addEventListener('scroll', checkScroll);
      return () => {
        window.removeEventListener('scroll', checkScroll);
        videoElement?.removeEventListener('timeupdate', videoUpdate);
        videoElement?.removeEventListener('ended', endedVideo);
      };
    }, []);

    return (
      <section className="introduction-container" id="introduction">
        <h2 style={{ marginTop: '26px' }}>{t('introduction')}</h2>
        <p>{isMobileView && isChinese ? t('introductionDetailsMobile') : t('introductionDetails')}</p>
        <div className="video-container">
          <video
            id="mt-video"
            src={ isChinese ? gogVideo : gogVideoEn }
            ref={videoRef}
            controls
            autoPlay
            muted />
          <div className="overlay-text">
            <p id="mt-date">{dateOfNextTournament()}</p>
          </div>
        </div>
        <div className="how-to-take-part">
          <h3>{t('howToEnter')}</h3>
        </div>
        <div className="cards-container" id="cards">
          <div className={`card-1 ${moveCards ? 'moved1' : ''} ${!isMobileView ? 'desktop-card-1' : ''}`}>
            <div className="card-1-content">
              <aside>
                <p>{t('step') + ' '}<strong className="card-header">1</strong></p>
                <div className="card-divider" />
                <div className="ag-logo">
                  <img src={AGLogo} width="200px" height="auto" alt="AG Logo"/>
                </div>
                <p className="step-instructions" style={{ marginTop: '40px' }}>{t('step1')}</p>
              </aside>
            </div>
          </div>
          <div className={`card-2 ${moveCards ? 'moved2' : ''} ${!isMobileView ? 'desktop-card-2' : ''}`}>
            <div className="card-2-content">
              <p>{t('step') + ' '}<strong className="card-header">2</strong></p>
              <div className="card-divider" />
              <div className="tournament-logo">
                <img src={TournamentLogoEN} alt="Asia Gaming Tournament Logo" width="250px" height="auto"/>
              </div>
              <p className="step-instructions" style={{ marginTop: '18px' }}>{t('step2')}</p>
            </div>
          </div>
        </div>
        <div className="game-process-title">
          <h3>{t('gameProcess')}</h3>
        </div>
        {!isMobileView && (
          <div className="step-skeleton-container" id="step-skeleton">
            <div className="skeleton-img-container">
              <LazyLoadImage src={stepSkeleton} alt="step skeleton" width="100px" height="539px"/>

              {step === 1 && <div className="skeleton-step-on-img-1">
                <LazyLoadImage src={stepOn} alt="step on" width="60px" height="60px" />
              </div>}
              {step === 2 && <div className="skeleton-step-on-img-2">
                <LazyLoadImage src={stepOn} alt="step on" width="60px" height="60px" />
              </div>}
              {step === 3 && <div className="skeleton-step-on-img-3">
                <LazyLoadImage src={stepOn} alt="step on" width="60px" height="60px" />
              </div>}
            </div>
            <aside className="steps-instructions">
              <div>
                <p className="step-skeleton-header">{t('qualifyingRound')}</p>
                <p className="step-skeleton-text">{t('qualRoundText')}</p>
              </div>
              <div>
                <p className="step-skeleton-header">{t('gogTickets')}</p>
              </div>
              <div>
                <p className="step-skeleton-header">{t('gogMasterTournament')}</p>
                <p className="step-skeleton-text">{t('gogStep3')}</p>
              </div>
            </aside>
          </div>
        )}
        {isMobileView && (
          <div className="mobile-steps-container" id="mobile-steps">
            <div className="mobile-first-step">
              {step === 1
                ? <LazyLoadImage className="fade-in" src={mobileStepOn} alt="step on" height="119px" width="119px"/>
                : <LazyLoadImage src={mobileStep1} alt="step 1" height="119px" width="118px"/>}
              <p className="step-skeleton-header">{t('qualifyingRound')}</p>
              <p className="step-skeleton-text">{t('qualRoundText')}</p>
            </div>
            <div>
              <div className="mobile-step-on-container">
                {step === 2
                  ? <>
                    <div className="mobile-step-on">
                      <LazyLoadImage className="fade-in" src={mobileStepOn} alt="step on" height="119px" width="119px" />
                    </div>
                    <LazyLoadImage src={mobileStep2} alt="step 2" height="152px" width="118px" />
                  </>
                  : <LazyLoadImage src={mobileStep2} alt="step 2" height="152px" width="118px"/>}
              </div>

              <p className="step-skeleton-header">{t('gogTickets')}</p>
            </div>
            <div>
              <div className="mobile-step-on-container">
                {step === 3
                  ? <>
                    <div className="mobile-step-on">
                      <LazyLoadImage className="fade-in" src={mobileStepOn} alt="step on" height="119px" width="119px" />
                    </div>
                    <LazyLoadImage src={mobileStep2} alt="step 3" height="152px" width="118px" />
                  </>
                  : <LazyLoadImage src={mobileStep2} alt="step 3" height="152px" width="118px"/>}
              </div>
              <p className="step-skeleton-header">{t('gogMasterTournament')}</p>
              <p className="step-skeleton-text" style={{ marginTop: '8px' }}>{t('gogStep3')}</p>
            </div>
          </div>
        )}

      </section>
    );
  };

import React from 'react';
import CircularProgress from '@mui/joy/CircularProgress';

import AGLogo from '../assets/introduction/web_mt_misc_logo_ag.svg';
export const LoadingBanner = (): JSX.Element => (
  <div className="loading-banner">
    <CircularProgress variant="plain" sx={{ '--CircularProgress-size': '250px' }}>
      <img src={AGLogo} width="200px" height="auto"/>
    </CircularProgress>
  </div>
);

import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { languageCodes } from './languages';
import {
  cnSimplified,
  cnTraditional,
  enTranslation,
  id,
  jp,
  kh,
  kr,
  th,
  vn
} from './translations';

void i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: languageCodes.chineseSimplified,
    debug: true,
    interpolation: {
      escapeValue: false
    },
    resources: {
      [languageCodes.english]: {
        translation: { ...enTranslation }
      },
      [languageCodes.chineseSimplified]: {
        translation: { ...cnSimplified }
      },
      [languageCodes.chineseTraditional]: {
        translation: { ...cnTraditional }
      },
      [languageCodes.indonesian]: {
        translation: { ...id }
      },
      [languageCodes.japanese]: {
        translation: { ...jp }
      },
      [languageCodes.khmer]: {
        translation: { ...kh }
      },
      [languageCodes.korean]: {
        translation: { ...kr }
      },
      [languageCodes.thai]: {
        translation: { ...th }
      },
      [languageCodes.vietnamese]: {
        translation: { ...vn }
      }
    }
  });

export default i18n;

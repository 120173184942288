/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import { Select, type SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { AnimatePresence, motion } from 'framer-motion';

import lantern from '../assets/banner/newYear/lantern_revised.webp';
import mobileBackground from '../assets/banner/newYear/mob_mt_cover_bg.webp';
import mobileTxtSloganHans from '../assets/banner/newYear/mob_mt_cover_slogan_hans_revised.webp';
import mobileRankingBackground from '../assets/banner/newYear/mob_mt_rb_bg_full.webp';
import mobileConfetti from '../assets/banner/newYear/mob_mt_rb_conffeti.webp';
import mobileStairs from '../assets/banner/newYear/mob_mt_rb_stairs.webp';
import mobileWave1 from '../assets/banner/newYear/mob_mt_rb_wave1.webp';
import mobileWave2 from '../assets/banner/newYear/mob_mt_rb_wave2.webp';
import mobileWave3 from '../assets/banner/newYear/mob_mt_rb_wave3.webp';
import mobileWave4 from '../assets/banner/newYear/mob_mt_rb_wave4.webp';
import mobileWave5 from '../assets/banner/newYear/mob_mt_rb_wave5.webp';
import mobileTxtSloganEn from '../assets/banner/newYear/mob_mt_title_en.webp';
import mobileTxtSloganHant from '../assets/banner/newYear/mob_mt_title_hant.webp';
import desktopCirle from '../assets/banner/newYear/mob_mt_web_cny_bg_badge_revised.webp';
import dragonEye from '../assets/banner/newYear/point_light.webp';
import desktopBackground from '../assets/banner/newYear/web_mt_cover_bg.webp';
import redGoldCloud2 from '../assets/banner/newYear/web_mt_cover_cloud_img_a.webp';
import blueGoldCloud from '../assets/banner/newYear/web_mt_cover_cloud_img_b.webp';
import redGoldCloud from '../assets/banner/newYear/web_mt_cover_cloud_img_c.webp';
import desktopStairs from '../assets/banner/newYear/web_mt_cover_stairs_revised.webp';
import desktopWave1 from '../assets/banner/newYear/web_mt_cover_wave1.png';
import desktopWave2 from '../assets/banner/newYear/web_mt_cover_wave2.webp';
import desktopWave3 from '../assets/banner/newYear/web_mt_cover_wave3.webp';
import desktopWave4 from '../assets/banner/newYear/web_mt_cover_wave4.webp';
import desktopWave5 from '../assets/banner/newYear/web_mt_cover_wave5.webp';
import mtLogo from '../assets/banner/newYear/web_mt_logo.webp';
import rankingBackground from '../assets/banner/newYear/web_mt_rb_bg.webp';
import desktopFans from '../assets/banner/newYear/web_mt_rb_bg_fan.webp';
import desktopConfetti from '../assets/banner/newYear/web_mt_rb_conffeti.webp';
import mtTxtSloganEn from '../assets/banner/newYear/web_mt_title_en_2.webp';
import mtTxtSloganHant from '../assets/banner/newYear/web_mt_title_hant_2.webp';
import newTopMasterTitleEn from '../assets/banner/newYear/web_mt_txt_rb_logo_en.webp';
import newTopMasterTitleCNSimp from '../assets/banner/newYear/web_mt_txt_rb_logo_hans_revised.webp';
import newTopMasterTitleCNTrad from '../assets/banner/newYear/web_mt_txt_rb_logo_hant_revised.webp';
import mtTxtSloganHans from '../assets/banner/newYear/web_mt_txt_slogan_hans_no_date.webp';
import goldCrown from '../assets/banner/web_mt_icon_crown_gold.webp';
import { rankingDatesArray } from '../data';
import { rankingsData1, rankingsData2, subText, subText2 } from '../data/rankingsData';
import { type IBanner, type ISubText } from '../interfaces';
import { languageCodes } from '../languages/languages';

import { LoadingBanner } from './LoadingBanner';

import '../styles/Banner.scss';

export default function Banner (
  { isMobileView, showRankings, t, i18n, isChinese }: IBanner): JSX.Element {
  const [isfirstRow, setIsFirstRow] = useState<boolean>(true);
  const [loading, setLoading] =
    useState({ titleLogo: false, background: false, rankingBackground: false });
  const [resultsPointer, setResultsPointer] = useState(0);

  const handleChangeOfDate = (event: SelectChangeEvent): void => {
    setResultsPointer(Number(event.target.value));
  };

  const isCNTrad: boolean =
    i18n.resolvedLanguage === languageCodes.chineseTraditional;
  const isCNSimp: boolean =
    i18n.resolvedLanguage === languageCodes.chineseSimplified;

  const TRANSITION_DURATION: number = 0.5;

  const selectBackground = '#801F15';

  const menuItemColor = '#FFF5D9';

  const checkTextSlogan = (): string => {
    if (isMobileView) {
      if (isCNSimp) return mobileTxtSloganHans;
      if (isCNTrad) return mobileTxtSloganHant;
      if (!isChinese) return mobileTxtSloganEn;
    }

    if (!isMobileView) {
      if (isCNSimp) return mtTxtSloganHans;
      if (isCNTrad) return mtTxtSloganHant;
      if (!isChinese) return mtTxtSloganEn;
    }

    return '';
  };

  const checkBackground = (): string => {
    if (isMobileView && !showRankings) return mobileBackground;
    if (isMobileView && showRankings) return mobileRankingBackground;
    if (!isMobileView && showRankings) return rankingBackground;
    return desktopBackground;
  };

  const checkTopMasterTitle = (): string => {
    if (isCNSimp) return newTopMasterTitleCNSimp;
    if (isCNTrad) return newTopMasterTitleCNTrad;

    return newTopMasterTitleEn;
  };

  const checkUnderlineRankings = (): string => {
    if (isfirstRow) return 'left-underline';
    if (!isfirstRow) return 'right-underline';

    return '';
  };

  const dateOfNextTournament = (): string => {
    if (isCNTrad) return '敬請期待';
    if (isCNSimp) return '敬请期待';
    return 'Stay Tuned';

    // if (isChinese) return dates.chinese;

    // return dates.english;
  };

  useEffect(() => {
    if (isMobileView) {
      if (isCNTrad) {
        const image = new Image();
        image.onload =
          () => { setLoading(prev => ({ ...prev, titleLogo: true })); };
        image.src = mobileTxtSloganHant;
      }

      if (isCNSimp) {
        const image = new Image();
        image.onload =
          () => { setLoading(prev => ({ ...prev, titleLogo: true })); };
        image.src = mobileTxtSloganHans;
      }

      if (!isChinese) {
        const image = new Image();
        image.onload =
          () => { setLoading(prev => ({ ...prev, titleLogo: true })); };
        image.src = mobileTxtSloganEn;
      }

      const mobileImgStage = new Image();
      mobileImgStage.onload =
        () => { setLoading(prev => ({ ...prev, background: true })); };
      mobileImgStage.src = mobileBackground;

      const mobileRankingBackgroundLoad = new Image();
      mobileRankingBackgroundLoad.onload =
        () => { setLoading(prev => ({ ...prev, rankingBackground: true })); };
      mobileRankingBackgroundLoad.src = mobileRankingBackground;
    };

    if (!isMobileView) {
      if (isCNTrad) {
        const image = new Image();
        image.onload =
          () => { setLoading(prev => ({ ...prev, titleLogo: true })); };
        image.src = mtTxtSloganHant;
      }

      if (isCNSimp) {
        const image = new Image();
        image.onload =
          () => { setLoading(prev => ({ ...prev, titleLogo: true })); };
        image.src = mtTxtSloganHans;
      }

      if (!isChinese) {
        const image = new Image();
        image.onload =
          () => { setLoading(prev => ({ ...prev, titleLogo: true })); };
        image.src = mtTxtSloganEn;
      }

      const desktopImgStage = new Image();
      desktopImgStage.onload =
        () => { setLoading(prev => ({ ...prev, background: true })); };
      desktopImgStage.src = desktopBackground;

      const desktopRankingBackgroundLoad = new Image();
      desktopRankingBackgroundLoad.onload =
        () => { setLoading(prev => ({ ...prev, rankingBackground: true })); };
      desktopRankingBackgroundLoad.src = rankingBackground;
    }
  }, []);

  const rankingsDataComponent =
    (rankingsData: string[], textArr: ISubText[]): JSX.Element =>
      (
        <>
          {rankingsData.map((data, index) => (
            <div key={data} className="gold-badge">
              <div className="top-text">
                <img src={goldCrown} alt="Gold Crown" width="27px" height="20px"/>
                <p className="gold-badge-title">{data}</p>
              </div>
              <aside>
                <>
                  <p>{textArr[index].subText1En}</p>
                  <p className="en-p">{textArr[index].subText2En}</p>
                </>
              </aside>

            </div>
          ))}
        </>);

  if (Object.values(loading).every(el => el)) {
    return (
      <div className="banner">
        <ParallaxBanner className="parallax-banner">
          <ParallaxBannerLayer
            image={checkBackground()}
            className={isMobileView ? 'cover-1-mobile' : 'cover-1'}
            key={checkBackground()}/>
          {!showRankings && (
            <>
              <ParallaxBannerLayer image={lantern} speed={5} className="lantern" />
              <ParallaxBannerLayer image={desktopCirle} speed={10} className="circle" />
            </>
          )}
          {showRankings && (
            <ParallaxBannerLayer image={desktopFans} className="fans" />
          )}
          {!isMobileView && <ParallaxBannerLayer image={blueGoldCloud} speed={12} className="cloud blue-cloud-left-1" />}
          {!isMobileView && <ParallaxBannerLayer image={redGoldCloud2} speed={12} className="cloud red-cloud-right-1" />}
          <ParallaxBannerLayer image={isMobileView ? mobileWave5 : desktopWave5} className="wave5" />
          <ParallaxBannerLayer image={isMobileView ? mobileWave4 : desktopWave4} className="wave4" />
          <ParallaxBannerLayer image={redGoldCloud} speed={12} className="cloud red-cloud-left-1" />
          <ParallaxBannerLayer image={blueGoldCloud} speed={12} className="cloud blue-cloud-right-1" />
          <ParallaxBannerLayer image={isMobileView ? mobileWave3 : desktopWave3} className="wave3" />
          <ParallaxBannerLayer image={isMobileView ? mobileWave2 : desktopWave2} className="wave2" />
          <ParallaxBannerLayer image={blueGoldCloud} speed={12} className="cloud blue-cloud-left-2" />
          <ParallaxBannerLayer image={redGoldCloud} speed={12} className="cloud red-cloud-right-2" />
          <ParallaxBannerLayer image={isMobileView ? mobileWave1 : desktopWave1} className="wave1" />
          <ParallaxBannerLayer image={isMobileView ? mobileStairs : desktopStairs} className="stairs" />
          {showRankings && (
            <ParallaxBannerLayer image={isMobileView ? mobileConfetti : desktopConfetti} className="confetti" />
          )}
          <AnimatePresence mode="wait">
            {!showRankings &&
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: TRANSITION_DURATION }}
                exit={{ opacity: 0 }}>
              </motion.div>
              <motion.div
                initial={{ transform: 'rotateY(180deg)', opacity: 0, backfaceVisibility: 'hidden' }}
                animate={{ transform: 'rotateY(0deg)', opacity: 1 }}
                transition={{ duration: TRANSITION_DURATION }}
                exit={{ transform: 'rotateY(-180deg)', opacity: 0 }}>
                <ParallaxBannerLayer expanded={false} speed={15} className="mt-logo">
                  <div className="logo-container">
                    <img src={mtLogo} alt="MT logo" width={isMobileView ? '250px' : '400px'} height="auto"/>
                    <img src={dragonEye} alt="Dragon Eye" width={isMobileView ? '25px' : '50px'} height="auto" className="dragon-eye" />
                  </div>
                </ParallaxBannerLayer>
                <ParallaxBannerLayer expanded={false} speed={15} className="mt-text-slogan">
                  <img src={checkTextSlogan()} alt="MT slogan" />
                  <div className="title-logo">
                    <p>{dateOfNextTournament()}</p>
                    {/* {isMobileView
                      ? <>
                        <p>
                          {mobileDateFormatPart1(isChinese
                            ? dates.chinese
                            : dates.english)}
                        </p>
                        <p>
                          {mobileDateFormatPart2(isChinese
                            ? dates.chinese
                            : dates.english)}</p>
                      </>
                      : <p>{dateOfNextTournament()}</p>} */}
                  </div>
                </ParallaxBannerLayer>
              </motion.div>
            </>
            }
          </AnimatePresence>
          <AnimatePresence mode="wait">
            {showRankings && (
              <motion.div
                initial={{ transform: 'rotateY(-180deg)', opacity: 0, backfaceVisibility: 'hidden' }}
                animate={{ transform: 'rotateY(0deg)', opacity: 1 }}
                transition={{ duration: TRANSITION_DURATION }}
                exit={{ transform: 'rotateY(-180deg)', opacity: 0 }}>
                <ParallaxBannerLayer speed={10}>
                  <div className="ranking-board-container">
                    <div className="top-master-title">
                      <img src={checkTopMasterTitle()} alt="New Top Master" width={isChinese ? '150px' : '300px'} height="auto"/>
                    </div>
                    <div>
                      <p className="congrats-text" style={{ fontWeight: i18n.resolvedLanguage === languageCodes.khmer ? '400' : '600' }}>{t('topMasterCongrats')}</p>
                    </div>
                    <div className="default-date">
                      <Select
                        sx={{
                          color: menuItemColor,
                          borderRadius: '16px',
                          border: '1px solid transparent',
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent'
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent'
                          },
                          '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: 'transparent'
                          },
                          '.MuiSvgIcon-root ': {
                            fill: `${menuItemColor} !important`
                          }
                        }}
                        inputProps={{
                          MenuProps: {
                            disableScrollLock: true,
                            MenuListProps: {
                              sx: {
                                backgroundColor: selectBackground
                              }
                            }
                          }
                        }}
                        value={resultsPointer.toString()}
                        onChange={handleChangeOfDate}>
                        {rankingDatesArray.map((dateObj, i) => {
                          const dateStr =
                            `${isChinese
                              ? rankingDatesArray[i]?.chinese
                              : rankingDatesArray[i]?.english}`;
                          return (
                            <MenuItem sx={{
                              bgcolor: 'black',
                              color: menuItemColor,
                              '&.Mui-selected, &.Mui-selected:hover, &.MuiMenuItem-root': {
                                background: selectBackground,
                                color: menuItemColor
                              },
                              '&.MuiMenu-paper': {
                                backgroundColor: selectBackground
                              },
                              '&.MuiMenuItem-root:hover': {
                                color: menuItemColor
                              }
                            }}
                            key={dateObj.chinese} value={i}>{dateStr}</MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                    {!isMobileView && (
                      <div className="rankings-container">
                        <div className="first-row">
                          <div className="date-line">
                            <p>
                              {isChinese
                                ? rankingDatesArray[resultsPointer].date1Chinese
                                : rankingDatesArray[resultsPointer]
                                  .date1English}
                            </p>
                          </div>
                          {rankingsDataComponent(rankingsData1[resultsPointer], subText)}
                        </div>
                        <div className="second-row">
                          <div className="date-line">
                            <p>
                              {isChinese
                                ? rankingDatesArray[resultsPointer].date2Chinese
                                : rankingDatesArray[resultsPointer]
                                  .date2English}
                            </p>
                          </div>
                          {rankingsDataComponent(rankingsData2[resultsPointer], subText2)}
                        </div>
                      </div>
                    )}
                    {isMobileView && (
                      <div className="mobile-rankings-container">
                        <div className=
                          {`mobile-day-container ${checkUnderlineRankings()}`}>
                          <button
                            className={`${!isfirstRow ? 'disabled-btn' : ''}`}
                            onClick={() => { setIsFirstRow(true); }}>
                            {isChinese
                              ? rankingDatesArray[resultsPointer].date1Chinese
                              : rankingDatesArray[resultsPointer].date1English}
                          </button>
                          <button
                            className={`${isfirstRow ? 'disabled-btn' : ''}`}
                            onClick={() => { setIsFirstRow(false); }}>
                            {isChinese
                              ? rankingDatesArray[resultsPointer].date2Chinese
                              : rankingDatesArray[resultsPointer].date2English}
                          </button>
                        </div>
                        <div className="mobile-first-row">
                          {isfirstRow &&
                            rankingsDataComponent(
                              rankingsData1[resultsPointer], subText)}
                          {!isfirstRow &&
                            rankingsDataComponent(
                              rankingsData2[resultsPointer], subText2)}
                        </div>
                      </div>
                    )}
                  </div>
                </ParallaxBannerLayer>
              </motion.div>
            )}
          </AnimatePresence>
        </ParallaxBanner>
      </div>
    );
  }

  return <LoadingBanner />;
};

const subText = [
  {
    subText1: '下午 2:30 场',
    subText2: '奖金 20,000',
    subText1En: '2:30pm',
    subText2En: 'CNY 20,000'
  },
  {
    subText1: '下午 4:30 场',
    subText2: '奖金 50,000',
    subText1En: '4:30pm',
    subText2En: 'CNY 50,000'
  },
  {
    subText1: '下午 8:00 场',
    subText2: '奖金 50,000',
    subText1En: '8:00pm',
    subText2En: 'CNY 50,000'
  },
  {
    subText1: '下午 9:30 场',
    subText2: '奖金 100,000',
    subText1En: '9:30pm',
    subText2En: 'CNY 100,000'
  }
];

const subText2 = [
  {
    subText1: '下午 2:30 场',
    subText2: '奖金 20,000',
    subText1En: '2:30pm',
    subText2En: 'CNY 20,000'
  },
  {
    subText1: '下午 4:30 场',
    subText2: '奖金 100,000',
    subText1En: '4:30pm',
    subText2En: 'CNY 100,000'
  },
  {
    subText1: '下午 8:00 场',
    subText2: '奖金 100,000',
    subText1En: '8:00pm',
    subText2En: 'CNY 100,000'
  },
  {
    subText1: '下午 9:30 场',
    subText2: '奖金 100,000',
    subText1En: '9:30pm',
    subText2En: 'CNY 100,000'
  }
];

// For day 1 of tournament for the month. Align names with indexes of subText
// Most recent tournament first
const rankingsData1 = [
  ['平凡', '8***536', '追龙', '让上一次岸'],
  ['万丈荣光', '牛牛牛', '天天好运', '稳住过两关'],
  ['每天赢二百', '飞飞飞', 'K***674', '癞子哥哥'],
  ['發發發', 'e***907', '吃吃吃', '鱼跃龙门'],
  ['h***698', '上不了的岸', '悔罪', '马户和又鸟'],
  ['G***596', 'q***644', '东北西门庆', 'n***g36']
];

// For day 2 of tournament for the month. Align names with indexes of subText
// Most recent tournament first
const rankingsData2 = [
  ['b***rk2', 't***106', 'd***904', '不要浪'],
  ['小小小發發', '最近有点崩', 'm***sdt', '两百就走'],
  ['d***o75', 'x***789', 'M***989', 's***nxc'],
  ['慢慢来', 'x***757', 's***edc', '赌神上身'],
  ['红红红', 'g***259', '幸运女神', 'd***901'],
  ['我在哪', 'r***qbv', '一路长虹', 'f***yxb']
];

export { rankingsData1, rankingsData2, subText, subText2 };

export const id = {
  gog: 'Dewa judi',
  introduction: 'Introduksi',
  award: 'Hadiah',
  details: 'Detail',
  faq: 'F.A.Q',
  new: 'New',
  topMaster: 'Top Master',
  backToTop: 'Kembali ke Atas',
  introductionDetails: 'Turnamen AG Master didasarkan pada permainan ' +
    'Baccarat \'Sit n Go\' yang paling populer di saat ini untuk diadakan, ' +
    'kami akan menggunakan semua peraturan standar yang adil dan jelas untuk ' +
    'membawa turnamen baccarat online yang paling menarik untuk semua pemain.',
  howToEnter: 'Cara masuk',
  gameProcess: 'Proses',
  step: 'Melangkah',
  step1: 'Turnamen ini hanya untuk akun uang riil AG;',
  step2: 'Pilih Master Tournamen Lobi dan pilih Turnamen yang diinginkan.',
  howToJoin1: 'Turnamen ini hanya untuk akun uang riil AG',
  howToJoin2: 'Pilih Master Tournamen Lobi dan pilih Turnamen yang diinginkan',
  satelliteTournament: 'Turnamen Satelit tidak memiliki jadwal tetap. ' +
    'Sit and go akan dimulai setelah meja terisi dengan pemain-pemain yang ' +
    'sudah mendaftar. Pemanang akan mendapatkan tiket untuk mendaftar ' +
    'di Turnamen Master.',
  masterTournament: 'Turnamen Master akan dimulai sesuai dengan waktu yang ' +
    'telah ditetapkan.',
  seasonTournament: 'Turnamen Triwulan menggunakan mode Waktu ' +
    'Tetap untuk memulai. Mengundang pemenang Turnamen Master pada musim ' +
    'terkait untuk bertanding dengan pemain yang telah mendaftar ' +
    'dengan poin. Pemenang akan mendapatkan hadiah berlimpah!',
  awardDetails: 'Semua hadiah pemenang akan langsung dikreditkan ke akun ' +
    'pemenang.',
  qualifyingRound: 'Turnamen Satelit',
  qualRoundText: 'Pemenang Turnamen Satelit akan mendapatkan tiket untuk mengikuti Turnamen Master dan memenangkan lebih banyak hadiah.',
  gogTickets: 'Tiket Turnamen Master\nAtau poin pengguna yang diperlukan',
  gogMasterTournament: 'Turnamen Master',
  gogStep3: 'Turnamen Master akan dimulai sesuai dengan waktu yang telah ditetapkan.',
  tournamentRules: 'Aturan Turnamen',
  fairness: 'Keadilan',
  regAndWithdrawal: 'Daftar / Pengeluaran',
  inGame: 'Peraturan',
  ranking: 'Kedudukan',
  withdraw3: 'Penarikan setelah pendaftaran (ketika orang yang dibutuhkan tidak terpenuhi) dan turnamen belum dimulai',
  withdraw3Child: 'Pemain dapat langsung mengundurkan diri, dan dalam waktu 3 menit pemain tidak dapat mendaftarkan jenis turnamen yang sama lagi, sistem akan mengembalikan poin/tiket yang dipotong.',
  withdraw4: 'Penarikan setelah pendaftaran (mencapai jumlah peserta) dan turnamen belum dimulai:',
  withdraw4Child: 'Peserta harus mengikuti turnamen untuk mengundurkan diri, peserta akan dihitung tersingkir oleh sistem. Semua poin atau tiket pengguna yang dipotong tidak akan dikembalikan.',
  withdraw5: 'Peserta memutuskan untuk pensiun selama turnamen',
  withdraw5Child: 'Selama turnamen jika salah satu peserta memilih permainan lain atau meninggalkan turnamen, peringkat dan peluang menang akan dinilai dari jumlah taruhan yang sah dan jumlah putaran yang telah dipasang peserta.',
  withdraw6: 'Pemain tidak akan dapat mendaftarkan ulang game yang sama setelah mereka keluar dari game tersebut.',
  withdraw7: 'Setiap pemain hanya dapat mengundurkan diri dari turnamen yang terdaftar dalam hari yang sama sebanyak 5 kali, jika lebih dari 5 kali pemain tidak diperbolehkan untuk mendaftar jenis turnamen yang sama.',
  tournamentRules1: 'Setelah pemain mendaftarkan turnamen, bergantungan dengan meja, chip taruhan akan ditentukan dengan meja turnamen. Chip turnamen tidak berhubungan dengan saldo pribadi dan Poin Pengguna. Semua chip turnamen tidak akan diakumulasikan ke sesi turnamen berikutnya. jika poin akun pemain tidak cukup, pemain bertaruh di meja normal untuk bertukar poin akun.',
  tournamentRules2: 'Untuk memastikan keadilan dan transparansi sistem,setelah pemain berhasil mendaftar, kursi yang ditetapkan semuanya diatur secara acak oleh sistem. Kontestan tidak boleh memilih atau bertukar tempat duduk dengan peserta lain.',
  tournamentRules3: 'Semua hadiah tunai instan pemenang akan didistribusikan sesuai dengan struktur penghargaan dan semua uang tunai akan di setorkan langsung ke akun pengguna;',
  tournamentRules4: 'Turnamen harus didasarkan pada aturan Baccarat non-komisi. Untuk detail selengkapnya mengenai aturan permainan Baccarat, klik menu> aturan game. Setiap kontestan memiliki 25 detik batas waktu taruhan per tangan di setiap putaran. Setiap peserta diharuskan untuk membuat setidaknya satu taruhan minimum baik pada BANKER ATAU PLAYER untuk setiap tangan. Kontestan tidak diiziinkan bertaruh pada TIE/ PAIR sebagai pengganti BANKER ATAU PLAYER.',
  tournamentRules5Part1: 'Sebelum dimulainya Babak Kualifikasi, 100,000 senilai chip turnamen (chip turnamen tidak memiliki nilai moneter yang benar dan hanya digunakan untuk tujuan turnamen) akan didistribusikan ke masing-masing kontestan di meja yang ditentukan, selama batas peserta kontes adalah 1000-10000.',
  tournamentRules5Part2: 'Kontestan tidak diperbolehkan bertaruh lebih dari chip turnamen yang tersedia dan batas taruhan per tangan sebagai berikut:',
  betItem: 'Item taruhan',
  minBet: 'Taruhan miminal',
  maxBet: 'Taruhan maksimum',
  banker: 'Banker',
  player: 'Player',
  tie: 'Tie',
  pair: 'Pair',
  tournamentRules6: 'Sebelum semua turnamen dimulai, 2 hand pertama akan dilwatkan. Saat turnamen, Shoe tidak akan di kocok lagi.',
  tournamentRules7: 'untuk memastikan keadilan dan transparansi di antara para peserta, turnamen akan menggunakan aturan promosi dan eliminasi berikut:',
  tournamentRules7_1: 'selama pertandingan, sistem mengadopsi aturan yang ditetapkan untuk setiap pertandingan untuk mempromosikan jumlah pemain yang ditunjuk sesuai dengantebel virtual untuk setiap putaran, dan setiap putaran pemain yang belum dipromosikan akan di eliminasi secara otomatis.',
  tournamentRules7_2: 'Contoh: 56 peserta yang berpartisipasi dalam turnamen, kontestan berada di 8 neja virtual (A, B, C, D, E, F, G, H) pemain dibagi menjadi 4 bagian (setiap bagian 5 putaran), kemudian setiap bagian tiga kontestan teratas dipromosikan (yaitu meja A sampai H, 9 pemain teratas dipromosikan ke babak berikutnya sampai selesai turnamen.',
  tournamentRules7_3: 'Semua peserta yang dipromosikan akan mempertahankan keuntungan pada chip turnamen: jika turnamen diatur ke dua peserta yang dipromosikan di setiap meja. Ketika para peserta dipromosikan di tempat pertama di babak berikutnya, maka masing-masing meja peserta tempat pertama akan menjadi persentas tetap peningkatan chip turnamen awal.(pemain teratas akan memiliki chip turnamen yang berbeda dan lebih banyak daripada peserta lain di babak berikutnya);',
  tournamentRules7_4: 'Sistem akan mengunakan sedekat munkin untuk memastikan bahwa rata-rata jumlah pemain per meja, setiap putaran yang dipromosikan peserta akan sekali lagi secara acak ditugaskan ke meja yang berbeda selama turnamen;',
  tournamentRules8: 'Sebelum dimulainya turnamen dimulai, fungsi taruhan khusus terbatas berikut akan didistribusikan ke masing-masing kontestan;',
  tournamentRules8_1: 'Taruhan Blind adalah salah satu teknik taruhan strategis, peserta tersembunyi yang memiliki jumlah taruhan ditempatkan. Taruhan Blind harus diungkapkan sebelum selesainya hand. Jumlah kesempatan taruhan blind sesuai dengan jumlah siklus yang disediakan di setiap turnamen. Contoh: Total siklus: 5, maka jumlah total taruhan blind: 5',
  tournamentRules8_2: '\'Pass bet\' adalah melewatkan putaran taruhan saat itu. Jika Anda tidak ingin bertaruh pada putaran saat itu, anda cukup mengeklik tombol \'Pass bet\'/ Jumlah peluang melewati taruhan sesuai dengan jumlah siklus yang disediakan dalam turnamen. Contoh: Total siklus:5, maka jumlah total taruhan yang bisa dilewatkan: 5',
  tournamentRules9: 'Pada akhir sesi turnamen, semua chip turnamen yang digunakan atau tidak digunakan dan fungsi taruhan khusus akan dihapus secara otomatis pada akhir setiap pertandingan. Peserta tidak diizinkan untuk mentransfer atau meminjam chip turnamen dari peserta lain.',
  tournamentRules10: 'Aturan peringkat akan menggunakan urutan prioritas sebgai berikut:',
  tournamentRules10_1: 'Peserta yang dipromosikan menduduki peringkat lebih tinggi daripada peserta yang deiliminsasi lainya, kemudian berdasarkan pada sisa akhir turnamen chip untuk mendapat peringkat;',
  tournamentRules10_2: 'Jika peserta dengan chip turnamen tersisa yang sama, maka tingkat kemenangan tertinggi adalah peringkat lebih tinggi;',
  tournamentRules10_3: 'Jika ada lebih dari satu peserta dengan peringkat yang sama dalam dua aturan peringkat di atas, peserta yang mendaftar lebih awal akan mendapatkan peringkat lebiih tinggi;',
  tournamentRules11: 'Untuk alasan apa pun jika peserta diminta meninggalkan meja selama sesi dan putaran, waktu absensi tidak boleh lebih dari lima (5) hand; jika tidak, peserta akan didiskualifikasi dari hadiah-hadiah dan hanya diperbolehkan masuk ke peringkat saja.',
  tc: 'Syarat dan kondisi',
  tc1: 'Semua turnamen hanya untuk semua akun uang riil AG yang valid hanya untuk peserta.',
  tc2: 'Selama Turnamen, pemain berpartisipasi \'Turnamen Satelit\' perlu menggunakan poin akun pemain untuk mendaftar untuk acara tersebut;',
  tc3: 'Semua turnamen dimulai tepat waktu sesuai waktu yang diumumkan di lobi turnamen yang berbeda dan kami berhak untuk mengubah, menunda atau membatalkan turnamen tanpa pemberitahuan sebelumnya kepada pemain.',
  tc4: 'Poin Pengguna dapat diperoleh dari taruhan yang berlaku hanya di Live Game, ini termasuk: jenis taruhan Baccarat, DragonTiger, SicBo, Roulette, Blackjack, Win Three, BID, MultiPlay, VIP, dan Good Road. Untuk detail lebih lanjut tentang sistem Poin Pengguna, silakan klik Poin Pengguna.',
  tc5: 'Pemenang “Turnamen Satelit” akan mendapatkan tiket untuk “Turnamen Master” (jika tiket telah habis, maka semua turnamen tidak akan menerima pendaftaran, siapa cepat dia dapat). Semua tiket sah selama acara di bulan ini.',
  tc6: 'Untuk memastikan acara turnamen adalah untuk anggota kami yang berharga, setiap anggota dibatasi ke akun yang sama dan alamat IP yang sama untuk berpartisipasi. Mereka yang melanggar aturan akan didiskualifikasi dari menerima penghargaan dan penipu serius, AG memiliki hak untuk berurusan dengan gelar atau mengurangi keuntungan terkait tanpa pemberitahuan sebelumnya.',
  tc7: 'Setiap peserta dalam turnamen dengan cara ilegal apa pun, termasuk namun tidak terbatas pada hedging, perselingkuhan konspirasi dan sebagainya, melanggar ketentuan poin kebijakan yang setara 6;',
  tc8: 'Jika peserta melanggar peraturan kompetisi, peserta akan kehilangan kelayakan mereka untuk berpartisipasi.',
  tc9: 'Bagi semua peserta yang mengikuti kegiatan AG, berarti menyetujui syarat dan ketentuan kegiatan tersebut.',
  tc10: 'AG berhak atas penjelasan terakhir dari kegiatan ini.',
  remark: 'Catatan',
  registration: 'Pendaftaran',
  registration1: 'Jika pemain memiliki tiket Turnamen Master dan poin pengguna yang cukup pada saat yang sama, pemain dapat memilih mengurangi poin pengguna atau tiket turnamen master.',
  registration2: 'Sebelum turnamen dimulai dalam 20 menit, setiap akun memungkinkan untuk mendaftar hanya satu jenis turnamen (turnamen Satelit atau Master)',
  registration2_1: 'Untuk menjamin pemain dapat berpartisipasi dalam Master Tournament, sebelum turnamen dimulai dalam waktu 20 menit, semua pemain yang terdaftar dengan akun yang sama tidak akan dapat mendaftar untuk Turnamen Satelit lainnya.',
  registration2_2: 'Jika Master Tournament dimulai pada 20 menit atau lebih, maka pemain dapat mendaftar untuk Turnamen Satelit. Ketika Master Tournament dimulai dalam 20 menit kemudian Turnamen Satelit yang terdaftar sebelumnya, semua poin akun dikurangi akan dikembalikan.',
  cancelReg: 'Batal pendaftaran',
  cancelReg1: 'Setiap pemain bisa membatalkan pendaftaran sebelum turnamen dimulai. Setelah pendaftaran dibatalkan, dalam waktu 3 menit tidak diperbolehkan mendaftar untuk sesi turnamen yang sama lagi.',
  cancelReg2: 'Selama turnamen jika salah satu peserta memilih permainan lain atau meninggalkan turnamen, peringkat dan peluang menang akan dinilai berdasarkan jumlah taruhan yang sah dan jumlah putaran yang telah ditempatkan peserta.',
  watch: 'Tonton',
  watch1: 'Setiap pemain dapat memilih opsi \'Tonton\' untuk menonton turnamen yang sedang berlangsung, selama dalam mode tontonan, pemain tidak diizinkan memasang taruhan tetapi dapat menggunakan fungsi pesan teks.',
  exception: 'Pengecualian',
  exception1: 'Jika terjadi crash server, kegagalan sistem dan mempengaruhi kelancaran turnamen, kami berhak untuk mengubah turnamen pembatalan dan mengembalikan semua poin yang dikurangi atau tiket kembali ke setiap peserta yang terdaftar.',
  exception2: 'Setelah turnamen dimulai, karena jaringan pemain sendiri disebabkan oleh pemutusan hubungan, pemain harus masuk kembali dalam lima (5) putaran dan kembali ke sesi turnamen sesegera mungkin. Selama 5 ronde ini, sistem taruhan otomatis akan menempatkan taruhan jumlah minimum di area Player. Jika peserta tidak dapat masuk kembali dalam lima (5) putaran akan otomatis dihapus karena peserta tidak bertaruh secara manual dengan peringkat saja tetapi dengan peluang hadiah.',
  exception3: 'Setiap peserta dalam turnamen berhak untuk membatalkan kualifikasi dan penghargaan uang tunai, karena penggunaan terus menerus dari taruhan hedge, AG memiliki hak untuk daftar hitam akun peserta dan pemain tidak akan pernah diizinkan untuk berpartisipasi dalam acara atau kegiatan yang diadakan AG.',
  q1: 'Bagaimana cara untuk berpartisipasi di Turnamen Triwulan?',
  faq1: 'Bagaimana cara berpartisipasi di Turnamen Triwulan: dapatkan satu tiket untuk Turnamen Triwulan, atau akumulasi poin.\n\nBagaimana cara mendapatkan tiket Turnamen Triwulan: Pemenang Turnamen Master pada musim terkait akan mendapatkan tiket Turnamen Triwulan sebelum turnamen tersebut dimulai. Tiket hanya bisa digunakan di Turnamen Triwulan musim tersebut.',
  q2: 'Di mana Lobi Turnamen Master?',
  faq2: 'Setelah berhasil masuk ke versi AG Desktop, Anda akan menemukan tab Master Tournament. Klik untuk mengakses lobi turnamen. Jika tab turnamen tidak ditampilkan, silakan hubungi operator Anda atau layanan pelanggan untuk informasi lebih lanjut. Untuk versi Mobile HTML5, sama dengan versi desktop, ada pintu yang berbeda untuk masuk ke lobi turnamen.',
  q3: 'Bagaimana cara mendaftar?',
  faq3: 'Cukup masuk ke lobi turnamen, dan klik tombol \'Daftar\'.',
  q4: 'Apa persyaratan untuk mengikuti turnamen?',
  faq4: 'Anda memerlukan akun uang riil AG pribadi, dan akun harus memiliki cukup poin atau tiket turnamen untuk dikurangi agar dapat bergabung dengan acara turnamen kami.',
  q5: 'Berapa nilai tukar untuk poin?',
  faq5: 'RMB100 taruhan yang valid = 1 poin. Anda dapat menggunakan Poin Pengguna untuk detailnya\nContoh:\nPemain A bertaruh 10.000, maka pemain A akan mendapatkan 100 poin;\nPemain B bertaruh 8,650, maka pemain B akan mendapatkan 86 poin;\nPoin akan dihitung berdasarkan bilangan bulat tanpa angka desimal.',
  q6: 'Di mana saya dapat memeriksa saldo Poin saya saat ini?',
  faq6: 'Klik tombol informasi (i) di sebelah avatar Anda, kemudian detail akun pribadi akan ditampilkan, ini termasuk saldo poin akun:\nAtau klik lobi Turnamen Master, di dalam lobi bagian sudut kanan atas akan menampilkan poin akun pribadi Anda:',
  q7: 'Bagaimana cara mendapatkan lebih banyak poin?',
  faq7: 'Penghasilan poin akun pemain didasarkan pada taruhan yang sah pada semua game langsung. Selama turnamen, semua poin dalam akun tidak akan dihapus. Live Games termasuk: jenis Baccarat, Dragon Tiger, Sic Bo, Roulette, Blackjack, Bull Bull, Win Three Cards, BID Baccarat, MultiPlay, VIP Baccarat, dan Good Road betting\n\nCatatan: Untuk semua tip atau hadiah untuk komentator, taruhan game tidak langsung semuanya tidak termasuk dalam perhitungan poin.',
  q8: 'Apa taruhan yang sah atau tidak sah?',
  faq8: 'Taruhan Sah: semua taruhan yang hasilnya menang atau kalah.\nTaruhan tidak sah: Taruhan yang hasilnya bertaruh pada 2 kemungkinan yaitu menang dan kalah.\nContoh: Di Baccarat, pemain ditempatkan bertaruh pada \'Banker\' dan \'Player\' pada waktu dan putaran yang sama.',
  q9: 'Apa perbedaan antara Turnamen Satelit dan Turnamen Master?',
  faq9: 'Turnamen Satelit: Tidak memiliki jadwal mulai tyang tetap. a sit and go dimulai setelah ada cukup banyak orang yang masuk di meja. Pemenang akan mendapatkan tiket untuk mendaftar di Turnamen Master:\nTurnamen Master menggunakan waktu tetap untuk mulai.',
  q10: 'Berapa biaya pendaftaran?',
  faq10: 'Dalam periode turnamen, semua saldo uang riil AG tidak perlu membayar biaya pendaftaran dan biaya apa pun. Hanya memerlukan akun pemain yang berisi poin atau tiket turnamen master yang cukup untuk bisa mendaftar.',
  q11: 'Bagaimana cara mendapatkan tiket Turnamen Master?',
  faq11: 'Pastikan anda memiliki poin pengguna yang cukup untuk mendaftar ke Turnamen Satelit. Dan anda harus memenangkan turnamen untuk mendapatkan tiket ke Turnamen Master untuk berpatisipasi di Turnamen Master.\nCatatan: Setiap peserta bisa menyimpan hingga 10 tiket Turnamen Master. Tiket tersebut sah digunakan hanya di Turnamen Master bulan ini. Saat acara berakhir, semua sisa tiket yang tidak terpakai akan hangus dan AG tidak akan mengembalikan atau mengganti poin pengguna.',
  q12: 'Dapatkah saya menggunakan poin untuk ditukar menjadi tiket Turnamen Master?',
  faq12: 'Ya, hanya ketika Anda tanpa tiket turnamen master tetapi dengan banyak poin pengguna. Cukup klik tombol daftar Turnamen Master dan sistem turnamen akan langsung mengurangi poin pengguna Anda sebagaimana tercantum dalam rincian turnamen.\nCatatan: Pendaftaran turnamen satelit didasarkan pada poin pengguna Anda untuk mendaftar bukan tiket.',
  q13: 'Dapatkah saya memutuskan untuk memilih poin pengguna atau tiket, mana yang saya kurangi terlebih dahulu?',
  faq13: 'Untuk Turnamen Satelit hanya akan mengurangi poin pengguna. Untuk Turnamen Master, Anda dapat memilih mengurangi poin pengguna atau tiket turnamen master.',
  q14: 'Apakah akun percobaan ini memungkinkan mendaftar untuk salah satu turnamen?',
  faq14: 'Semua turnamen kami hanya tersedia untuk akun uang AG saja. Meskipun akun uji coba AG hanya tersedia untuk mode tontonan, yaitu hanya menonton turnamen yang sedang berlangsung. Jika Anda ingin membuat akun uang riil AG, silakan kontak dengan perwakilan layanan pelanggan Anda.',
  q15: 'Mengapa ada peringatan saat gagal melakukan pendaftaran?',
  faq15: 'Pendaftaran gagal, mungkin memiliki alasan berikut:\nAkun Anda bukan akun uang riil AG atau tidak sesuai dengan persyaratan turnamen;\nAnda terdaftar sebelumnya, tetapi Anda dipilih untuk keluar dari turnamen sebelumnya, jadi ada hukuman 3 menit menunggu.',
  q16: 'Apakah setelah terdaftar dapat membatalkan pendaftaran?',
  faq16: 'Setelah pendaftaran (ketika orang yang dibutuhkan tidak penuh) dan turnamen belum dimulai:\nPemain diizinkan untuk kelaur sesegera mungkin, tetapi dalam waktu 3 menit pemain tidak dapat mendaftar turnamen sejenis lagi, sistem akan mengembalikan poin atau tiket yang dikurangi.\nSetelah pendaftaran (mencapai jumlah peserta) dan turnamen belum dimulai:\nPeserta harus memasuki turnamen kemudian berhenti, dan sistem akan memperlakukan peserta sebagai dieliminasi. Sistem tidak akan mengembalikan poin atau tiket pengguna yang dipotong.\nPeserta berinisiatif untuk berhenti selama turnamen:\nSelama turnamen jika salah satu peserta memilih permainan lain atau meninggalkan turnamen, peringkat dan peluang menang akan dinilai berdasarkan jumlah taruhan yang sah dan jumlah putaran yang telah.\nMohon Diingat:\n1. Pemain yang sama untuk mendaftar ke turnamen setelah berhenti, tidak akan dapat memasuki kembali game yang sama;\n2. Setiap pemain hanya dapat keluar dari turnamen yang terdaftar dalam hari yang sama hingga 5 kali, jika lebih dari 5 kali, maka tidak diizinkan untuk mendaftar jenis turnamen yang sama.',
  q17: 'Setelah terdaftar, apakah saya perlu menunggu di lobi untuk memulai turnamen?',
  faq17: 'TTidak perlu menunggu dimulainya turnamen, pemain dapat memasuki game lain sebelum atau mendekati waktu mulai, sistem akan mengirim pesan untuk menginformasikan pemain untuk bersiap memasuki turnamen.',
  q18: 'Berapa lama untuk setiap turnamen diadakan?',
  faq18: 'Setiap turnamen berjalan sekitar 5 hingga 20 menit.',
  q19: 'Apakah turnamen menggunakan uang riil untuk bermain?',
  faq19: 'Semua peserta diizinkan untuk menggunakan chip turnamen saja. Seluruh chip turnamen tidak terkait dengan saldo uang riil peserta.',
  q20: 'Apa itu chip turnamen?',
  faq20: 'Turnamen Master AG berpegang pada prinsip adil dan transparan, semua peserta di turnamen memiliki jumlah chip awal yang sama, yang dikenal sebagai chip turnamen untuk penentu peringkat. Ini tidak terkait dengan saldo akun uang riil peserta. Ini hanya diperbolehkan untuk digunakan dalam turnamen. Setelah setiap bagian, chip turnamen akan dihapus.',
  q21: 'Apakah penonton dapat bertaruh di turnamen?',
  faq21: 'Hanya para pemain yang mendaftar dapat bermain taruhan di bidang taruhan. Penonton tidak bisa bertaruh dan hanya bisa menonton di sela-sela. Jika Anda ingin berpartisipasi, Anda dapat menekan tombol \'daftar\' di lobi turnamen.',
  q22: 'Apa artinya online?',
  faq22: 'Jumlah total pemain yang telah mendaftar untuk turnamen.',
  q23: 'Berapa batas taruhan untuk setiap area taruhan?',
  betLimit: 'Batas Taruhan',
  bankerPair: 'Banker Pair',
  playerPair: 'Player Pair',
  q24: 'Mengapa tidak dapat bertaruh pada “Pairs” dan “Tie”?',
  faq24: 'Semua kontestan harus bertaruh pada Banker atau Player sebelum dapat bertaruh pada pair dan tie.',
  q25: 'Apa itu Blind?',
  faq25: 'Blind adalah salah satu teknik taruhan strategis, terutama kontestan tersembunyi yang menempatkan jumlah taruhan. Isi dari blind harus diungkapkan sebelum selesainya hand.',
  q26: 'Apa itu pass?',
  faq26: 'Pass adalah melewatkan putaran taruhan saat ini. Jika Anda tidak ingin bertaruh pada putaran saat ini, Anda cukup mengeklik tombol “Pass”.',
  q27: 'Jumlah orang yang pass dan blind, bukankah itu terakumulasi?',
  faq27: 'Semua kartu yang digunakan atau tidak digunakan adalah sah dan hanya digunakan dalam turnamen saja. Setelah turnamen selesai, semua pass dan blind kembali ke sistem untuk turnamen berikutnya.',
  q28: 'Di Turnamen Master, bagaimana memilih mengikuti promosi atau dieliminasi?',
  faq28: 'Untuk memastikan keadilan dan transparansi di antara pemain, Master Tournament akan menggunakan aturan promosi dan eliminasi berikut:\nUntuk memastikan keadilan dan transparansi di antara pemain, Turnamen Master akan menggunakan aturan promosi dan eliminasi berikut: Selama permainan, sistem menerapkan aturan yang ditetapkan untuk setiap pertandingan untuk mempromosikan jumlah pemain yang ditunjuk sesuai dengan tabel virtual untuk setiap putaran, dan masing-masing putaran pemain yang belum dipromosikan akan dihilangkan secara otomatis. Sebagai contoh: 56 yang berpartisipasi dalam turnamen, para kontestan berada di 8 meja virtual (A 、 B 、 C 、 D 、 E 、 F 、 G 、 H) permainan dibagi menjadi 4 bagian (setiap bagian 5 putaran), lalu masing-masing Bagian tiga kontestan teratas dipromosikan (yaitu A sampai H tabel, 3 pemain teratas dipromosikan ke babak berikutnya sampai turnamen selesai.',
  q29: 'Bagaimana cara menentukan peringkat pemain?',
  faq29: 'Aturan peringkat akan menggunakan urutan prioritas berikut:\nPeserta yang dipromosikan menduduki peringkat lebih tinggi daripada peserta yang dieliminasi lainnya, kemudian berdasarkan pada chip turnamen tetap akhir untuk mendapatkan peringkat;\nJika peserta dengan chip turnamen tersisa yang sama, maka tingkat kemenangan tertinggi adalah peringkat lebih tinggi;\nJika ada lebih dari satu peserta dengan peringkat yang sama dalam dua aturan peringkat di atas, peserta yang mendaftar lebih awal akan mendapat peringkat lebih tinggi;',
  q30: 'Bagaimana cara mendistribusikan hadiah atau uang tunai?',
  faq30: 'Silakan klik tombol \'Detail\' untuk informasi.',
  q31: 'Bagaimana cara menerima hadiah uang yang didapat di turnamen?',
  faq31: 'Semua bonus yang diberikan selama turnamen akan segera dikreditkan ke akun pemain.',
  q32: 'Mengapa pemain lain memiliki chip kompetisi yang lebih tinggi daripada saya ketika Turnamen Master putaran kedua dimulai?',
  faq32: 'Untuk meningkatkan persaingan, setiap chip pemain akan ditingkatkan sebelum dimulainya babak berikutnya',
  q33: 'Apakah setiap poin atau tiket Turnamen Master yang dipotong dari pertandingan akan dikembalikan jika pertandingan dibatalkan?',
  faq33: 'Poin atau tiket akan dikembalikan. Tidak ada pemotongan.',
  q34: 'Di mana saya dapat menemukan sejarah taruhan?',
  faq34: 'Pemain dapat menemukannya dengan mengklik \'layanan kustom\'> \'sejarah akun\'> kemudian \'sejarah taruhan pertandingan\'.',
  q35: 'Apa itu “Top Master”?',
  faq35: 'Top Master menunjukkan catatan dari setiap turnamen master yang memberi peringkat nama pemenang pertama dan penghargaan hadiah. Klik nama pemain akan menunjukkan bahwa pemain berpartisipasi di bagian turnamen dan hadiah yang dimenangkannya.',
  q36: 'Apa yang harus saya lakukan jika jaringan saya tiba-tiba terputus selama pertandingan turnamen?',
  faq36: 'Selama turnamen, jika jaringan Anda terputus dan gagal, sistem akan secara otomatis bertaruh pada \'Pemain\' dengan jumlah batas taruhan terendah. Jika pemain tidak kembali ke meja turnamen yang sama dalam 5 putaran, pemain akan dieliminasi. Sistem juga akan menentukan putaran dan sesi tempat pemain untuk menilai peringkat dan peluang hadiah penghargaan.',
  topMasterCongrats: 'Selamat untuk para Pemenang Master Turnamen AG',
  tournamentSetting: 'Tetapan',
  others: 'Lain-lain',
  regQ3Faq: 'Selama turnamen, semua akun uang riil AG tidak perlu membayar biaya pendaftaran dan penanganan. Ketika seorang pemain ingin mendaftar untuk Turnamen Master, ia harus memiliki poin atau tiket Turnamen Master yang cukup.'
};

import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { type ITournamentRules } from '../interfaces';

export const TournamentRules = ({ t }: ITournamentRules): JSX.Element => {
  const animationDuration = 300;
  const iconStyle = { color: 'white', height: '48px', width: '48px' };

  const initialQuestionsObj = { q1: false, q2: false, q3: false };

  const [questionsObj, setQuestionsObj] =
    useState<Record<string, boolean>>(initialQuestionsObj);

  const handleQuestionsObj = (question: string): void => {
    if (!questionsObj[question]) {
      setQuestionsObj({ ...initialQuestionsObj, [question]: true });

      const questionNumber: string = question.split('')[1];
      const element: HTMLElement | null =
        document.getElementById(`tr${questionNumber}`);

      /*
       a setTimeout() is necessary because when a question tab expands/collapses
       it takes time and we want that to complete before scrolling into view.
       */

      if (element != null) {
        setTimeout(() => {
          element.scrollIntoView(true);
        }, animationDuration);
      }
    }

    if (questionsObj[question]) {
      setQuestionsObj({ ...initialQuestionsObj });
    }
  };

  return (
    <div className="tournament-rules-container">
      <div className={`fairness-container ${questionsObj.q1 ? 'back-shadow' : ''}`}>
        <button id="tr1" onClick={() => { handleQuestionsObj('q1'); } }>
          <div className={`label-container ${questionsObj.q1 ? 'no-border' : ''}`}>
            <strong>{t('fairness')}</strong>
            {questionsObj.q1
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q1 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('watch1')}</li>
              <li>{t('tournamentRules1')}</li>
              <li>{t('tournamentRules2')}</li>
              <li>{t('tournamentRules3')}</li>
              <li>{t('tournamentRules9')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`registration-withdrawal-container ${questionsObj.q2 ? 'back-shadow' : ''}`}>
        <button id="tr2" onClick={() => {
          handleQuestionsObj('q2');
        } }>
          <div className={`label-container ${questionsObj.q2 ? 'no-border' : ''}`}>
            <strong>{t('regAndWithdrawal')}</strong>
            {questionsObj.q2
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q2 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('registration1')}</li>
              <li>{t('registration2')}</li>
              <ol type="a">
                <li>{t('registration2_1')}</li>
                <li>{t('registration2_2')}</li>
              </ol>
              <li>{t('withdraw3')}</li>
              <ul>
                <li>{t('withdraw3Child')}</li>
              </ul>
              <li>{t('withdraw4')}</li>
              <ul>
                <li>{t('withdraw4Child')}</li>
              </ul>
              <li>{t('withdraw5')}</li>
              <ul>
                <li>{t('withdraw5Child')}</li>
              </ul>
              <li>{t('withdraw6')}</li>
              <li>{t('withdraw7')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`in-game-container ${questionsObj.q3 ? 'back-shadow' : ''}`}>
        <button id="tr3" onClick={() => { handleQuestionsObj('q3'); } }>
          <div className={`label-container ${questionsObj.q3 ? 'no-border' : ''}`}>
            <strong>{t('inGame')}</strong>
            {questionsObj.q3
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q3 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('tournamentRules4')}</li>
              <li>{t('tournamentRules5Part1') + '\n\n' + t('tournamentRules5Part2')}</li>
            </ol>
            <div className="custom-table">
              <div className="table-first-row">
                <p>{t('betItem')}</p>
                <p>{t('minBet')}</p>
                <p>{t('maxBet')}</p>
              </div>
              <div className="table-other-rows">
                <div className="table-row">
                  <p>{t('banker')}</p>
                  <p>1,000</p>
                  <p>10,000</p>
                </div>
                <div className="table-row">
                  <p>{t('player')}</p>
                  <p>1,000</p>
                  <p>10,000</p>
                </div>
                <div className="table-row">
                  <p>{t('tie')}</p>
                  <p>100</p>
                  <p>1,000</p>
                </div>
                <div className="table-row">
                  <p>{t('pair')}</p>
                  <p>100</p>
                  <p>1,000</p>
                </div>
              </div>
            </div>
            <ol>
              <li value="3">{t('tournamentRules6')}</li>
              <li>{t('tournamentRules8')}</li>
              <ul>
                <li>{t('tournamentRules8_1')}</li>
                <li>{t('tournamentRules8_2')}</li>
              </ul>
            </ol>
          </section>
        </AnimateHeight>
      </div>
      <div className={`ranking-container ${questionsObj.q4 ? 'back-shadow' : ''}`}>
        <button id="tr4" onClick={() => { handleQuestionsObj('q4'); } }>
          <div className={`label-container ${questionsObj.q4 ? 'no-border' : ''}`}>
            <strong>{t('ranking')}</strong>
            {questionsObj.q4
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q4 ? 'auto' : 0}>
          <section className="tr-section-container">
            <ol>
              <li>{t('tournamentRules7')}</li>
              <ol type="a">
                <li>{t('tournamentRules7_1')}</li>
                <li>{t('tournamentRules7_2')}</li>
                <li>{t('tournamentRules7_3')}</li>
                <li>{t('tournamentRules7_4')}</li>
              </ol>
              <li>{t('tournamentRules10')}</li>
              <ol type="a">
                <li>{t('tournamentRules10_1')}</li>
                <li>{t('tournamentRules10_2')}</li>
                <li>{t('tournamentRules10_3')}</li>
              </ol>
              <li>{t('tournamentRules11')}</li>
            </ol>
          </section>
        </AnimateHeight>
      </div>
    </div>
  );
};

export const vn = {
  gog: 'Thần cờ bạc',
  introduction: 'Hướng dẫn',
  award: 'Phần thưởng',
  details: 'Chi tiết',
  faq: 'Câu hỏi thường gặp',
  new: 'Mới',
  topMaster: 'Top người chơi Master',
  backToTop: ' Lên đầu',
  introductionDetails: 'Giải đấu Casino Master dựa trên trò Baccarat trực ' +
    'tuyến kết hợp phương thức \'Sit n Go\' phổ biến nhất hiện nay, ' +
    'chúng tôi sẽ sử dụng tất cả các luật giải đấu công bằng và minh bạch ' +
    'để mang lại giải đấu baccarat trực tuyến thú vị nhất cho tất ' +
    'cả người chơi.',
  howToEnter: 'Làm thế nào để tham gia',
  gameProcess: 'Quá trình',
  step: 'Bước chân',
  step1: 'Tài khoản người dùng phải là tài khoản tiền thật của nền tảng AG',
  step2: 'Lựa chọn sảnh Giải đấu Casino Master và lựa chọn giải đấu mong muốn',
  howToJoin1: 'Giải đấu chỉ áp dụng đối với tài khoản bằng tiền thật',
  howToJoin2: 'Lựa chọn sảnh Giải đấu Casino Master và ' +
    'lựa chọn giải đấu mong muốn',
  satelliteTournament: 'Giải Vệ Tinh không có lịch đấu và thời gian bắt ' +
    'đầu cụ thể. Bàn chơi Sit and Go sẽ bắt đầu ngay sau khi có đủ người ' +
    'đăng ký tại bàn. Người chiến thắng sẽ nhận được một vé để than gia ' +
    'Giải đấu Master',
  masterTournament: 'Giải đấu Master có thời gian bắt đầu cụ thể. Giải thưởng' +
    'của Giải đấu Master sẽ rất lớn, tạo thêm tính hấp dẫn cho giải đấu.',
  seasonTournament: 'Giải Đấu Quý bắt đầu với thời gian cố định. ' +
    'Chúng tôi sẽ mời những người thắng cuộc của Giải Đấu Chuyên nghiệp ' +
    'của mùa giải để thi đấu với người chơi đăng ký tham gia để giành điểm. ' +
    'Nhà vô địch sẽ nhận giải thưởng hấp dẫn!',
  awardDetails: 'Tất cả tiền thưởng của người thắng cuộc sẽ được cập nhật ' +
    'trực tiếp vào tài khoản ngay mà không cần chờ kiểm duyệt.',
  qualifyingRound: 'Giải Vệ Tinh',
  qualRoundText: 'Những người chiến thắng trong Giải đấu vệ tinh sẽ nhận được vé tham gia Giải đấu Master và giành được nhiều giải thưởng hơn.',
  gogTickets: 'Vé giải đấu chính\nHoặc điểm người dùng bắt buộc',
  gogMasterTournament: 'Giải đấu cao thủ',
  gogStep3: 'Giải đấu Master đang sử dụng chế độ Thời gian cố định để bắt đầu. Khi người tham gia đã đạt được mục tiêu, giải đấu sẽ bắt đầu ngay lập tức.',
  tournamentRules: 'Thể lệ giải đấu',
  fairness: 'Công bằng',
  regAndWithdrawal: 'Đăng ký / Rút tiền',
  inGame: 'Quy tắc',
  ranking: 'xếp hạng',
  withdraw3: 'Rút lui sau khi đăng ký (khi không đủ số người yêu cầu) và giải đấu chưa bắt đầu',
  withdraw3Child: 'Người chơi có thể rút ngay lập tức và trong vòng 3 phút người chơi không thể đăng ký lại cùng một loại giải đấu, hệ thống sẽ trả lại số điểm/vé bị trừ.',
  withdraw4: 'Rút lui sau khi đăng ký (đủ số lượng người tham gia) và giải đấu chưa bắt đầu:',
  withdraw4Child: 'Người tham gia phải tham gia giải đấu để rút lui, người tham gia sẽ bị hệ thống tính là bị loại. Tất cả các điểm hoặc vé người dùng bị khấu trừ sẽ không được hoàn trả.',
  withdraw5: 'Người tham gia quyết định rút lui trong giải đấu',
  withdraw5Child: 'Trong suốt giải đấu nếu bất kỳ người tham gia nào chọn trò chơi khác hoặc rời khỏi giải đấu, thứ hạng và cơ hội chiến thắng sẽ được đánh giá dựa trên số lượng cược hợp lệ và số vòng mà người tham gia đã đặt.',
  withdraw6: 'Người chơi sẽ không thể đăng ký lại cùng một trò chơi sau khi họ thoát khỏi trò chơi đó.',
  withdraw7: 'Mỗi kỳ thủ chỉ được rút khỏi giải đã đăng ký trong cùng một ngày tối đa 5 lần, nếu quá 5 lần kỳ thủ đó không được đăng ký cùng loại giải.',
  tournamentRules1: 'Khi tham gia giải đấu, người chơi sẽ đặt cược bằng chip riêng của giải đấu, số chip tùy thuộc vào từng giải đấu cụ thể. Các chip trong giải đấu không ảnh hưởng đến số dư tài khoản cá nhân của người chơi và Điểm Người Chơi. Tất cả chip giải đấu sẽ không được cộng dồn vào giải đấu tiếp theo. Nếu Điểm Người Chơi không đủ, người chơi có thể tham gia đặt cược hợp lệ trong các bàn chơi thường để đổi điểm trong tài khoản.',
  tournamentRules2: 'Để đảm bảo tính công bằng và minh bạch của hệ thống, sau khi người chơi đăng ký thành công, ghế được chỉ định đều được hệ thống sắp xếp ngẫu nhiên. Người chơi không được chọn hoặc trao đổi chỗ ngồi với những người tham gia khác.',
  tournamentRules3: 'Tất cả tiền thưởng của người chiến thắng sẽ được căn cứ theo cấu trúc giải thưởng của giải đấu và sẽ được gửi trực tiếp vào tài khoản của người chơi.',
  tournamentRules4: 'Giải đấu sẽ dựa trên luật chơi của trò Baccarat không hoa hồng. Để biết thêm chi tiết về luật chơi Baccarat, hãy nhấp vào Menu > luật chơi. Người chơi phải đặt cược trong vòng25 giây giới hạn. Người chơi phải đặt ít nhất một lần cho BANKER HOẶC PLAYER cho mỗi vòng. Người chơi không được phép đặt cược vào Hòa / Đôi nếu như chưa đặt cược cho Banker hoặc Player.',
  tournamentRules5Part1: 'Trước khi bắt đầu giải đấu, mỗi người chơi sẽ được nhận 100,000 chip (chip giải đấu không có giá trị tiền thật và chỉ được sử dụng cho mục đích giải đấu). Trong giải đấu giới hạn đặt cược của người chơi là 1,000-10,000.',
  tournamentRules5Part2: 'Người chơi không được đặt cược nhiều hơn số lượng chip đang có và giới hạn đặt cược cho mỗi vòng như sau:',
  betItem: 'Loại cược',
  minBet: 'Cược tối thiểu',
  maxBet: 'Cược tối đa',
  banker: 'Banker',
  player: 'Player',
  tie: 'Hòa',
  pair: 'Đôi',
  tournamentRules6: '2 ván bài đầu tiên sẽ được bỏ qua trước khi giải đấu bắt đầu. Trong suốt giải đấu, hộp chia bài sẽ không được xáo lại.',
  tournamentRules7: 'Để đảm bảo sự công bằng và minh bạch giữa những người chơi, giải đấu sẽ sử dụng luật thăng hạng và loại trừ sau đây:',
  tournamentRules7_1: 'Trong Giải đấu, sau mỗi Cấp đấu hệ thống sẽ thăng hạng cho một số lượng người chơi cố định, được quy định dựa theo luật chơi của từng giải đấu. Các người chơi không được thăng hạng sẽ tự động bị loại.',
  tournamentRules7_2: 'Ví dụ: Có 56 người chơi tham gia giải đấu, các người chơi được sắp xếp chơi trong 8 bàn chơi ảo (A 、 B 、 C 、 D 、 E 、 F 、 G 、 H). Giải đấu sẽ được chia thành 4 cấp (mỗi cấp 5 vòng). Sau khi kết thúc mỗi cấp, ba người chơi đứng đầu của mỗi bàn sẽ được thăng hạng. Nghĩa là từ bàn A đến H, 3 người chơi đứng đầu mỗi bàn sẽ được chơi ở cấp kế tiếp, các người chơi khác sẽ bị loại. Và sẽ tiếp tục như vậy cho đến khi kết thúc giải đấu.',
  tournamentRules7_3: 'Lợi thế Chip: Nếu giải đấu được thiết lập cho từ 2 người chơi được thăng hạng trong mỗi bàn trở lên. Khi bắt đầu cấp đấu kế tiếp, các người chơi đứng đầu ở mỗi bàn sẽ có số chip cao hơn số chip khi bắt đầu giải đấu. Phần trăm chip được tăng sẽ được cố định. (ví dụ: người chơi hạng 1 sẽ có số chip khác và nhiều hơn so với các người chơi khác trong vòng tiếp theo);',
  tournamentRules7_4: 'Hệ thống đảm bảo số lượng người chơi trung bình trên mỗi bàn gần nhau nhất có thể, người chơi được thăng hạng mỗi vòng sẽ được chỉ định ngẫu nhiên vào các bàn khác nhau một lần nữa trong suốt giải đấu;',
  tournamentRules8: 'Trước khi bắt đầu giải đấu, chức năng cược đặc biệt sau sẽ được cung cấp cho từng người chơi:',
  tournamentRules8_1: 'Cược Ẩn là một trong những kỹ thuật đặt cược chiến lược, chức năng này ẩn đi lựa chọn cược và số tiền đặt cược của người chơi. Chi tiết của cược Ẩn sẽ được tiết lộ trước khi kết thúc ván bài. Số lần đặt cược Ẩn được dựa theo số cấp chơi được quy định trong giải đấu. Ví dụ: Tổng số cấp chơi: 5, tổng số cược Ẩn: 5',
  tournamentRules8_2: '\'Bỏ cược\' là bỏ qua vòng cược hiện tại. Nếu bạn không muốn đặt cược vòng hiện tại, bạn có thể chỉ cần nhấp vào nút \'Bỏ cược\'. Số lần bỏ cược được tính theo số cấp chơi được quy định trong giải đấu. Ví dụ: Tổng số cấp chơi: 5, tổng số lần bỏ cược: 5',
  tournamentRules9: 'Khi kết thúc mỗi giải đấu, tất cả chip giải đấu và cược đặc biệt sẽ bị xóa. Người chơi không được phép chuyển nhượng hoặc cho mượn chip giải đấu từ người chơi khác.',
  tournamentRules10: 'Luật xếp hạng sẽ sử dụng thứ tự ưu tiên như sau:',
  tournamentRules10_1: 'Những người chơi được thăng hạng có thứ hạng cao hơn những người chơi bị loại, sau đó xếp hạng dựa trên số chip giải đấu còn lại khi kết thúc;',
  tournamentRules10_2: 'Nếu nhiều người chơi có cùng số chip giải đấu còn lại khi kết thúc giải, người chơi có tổng số chip cược hợp lệ cao hơn sẽ được xếp hạng cao hơn;',
  tournamentRules10_3: 'Nếu có nhiều hơn một người chơi có cùng thứ hạng sau hai luật xếp hạng trên, những người chơi đăng ký trước sẽ được xếp hạng cao hơn;',
  tournamentRules11: 'Vì bất kỳ lý do gì nếu một người chơi cần rời khỏi bàn trong giải đấu hay vòng đấu, thời gian vắng mặt không được vượt quá năm (5) vòng; nếu vi phạm, người chơi vẫn được xem xét trong bảng xếp hạng nhưng sẽ không được nhận giải thưởng.',
  tc: 'Các điều khoản và điều kiện',
  tc1: 'Tất cả các giải đấu chỉ dành cho những người chơi có tài khoản AG bằng tiền thật hợp lệ.',
  tc2: 'Trong suốt giải đấu, người chơi tham gia “Giải Vệ Tinh” cần sử dụng điểm trong tài khoản người chơi để đăng ký tham gia;',
  tc3: 'Tất cả các giải đấu sẽ được bắt đầu đúng thời gian đã được công bố tại các sảnh giải đấu và chúng tôi có quyền thay đổi, trì hoãn hoặc hủy bỏ giải đấu mà không cần thông báo trước cho người chơi.',
  tc4: 'Số Điểm Người Chơi có thể kiếm được từ việc đặt cược hợp lệ chỉ trong trò chơi trực tuyến, bao gồm: bất kỳ loại cược Baccarat, Rồng Hổ, SicBo, Roulette, Blackjack, Win Three Cards, BID, MultiPlay, VIP và Good Road. Để biết thêm chi tiết về hệ thống Số Điểm Người Dùng, vui lòng nhấp vào mục Điểm Người Dùng',
  tc5: 'Người chiến thắng “Giải Vệ Tinh” sẽ nhận được vé “Giải đấu Casino Master” (nếu tất cả vé đã hết, tất cả các giải đấu sẽ không chấp nhận đăng ký thêm, theo nguyên tắc \'đến trước, được phục vụ trước\'). Tất cả vé chỉ có hiệu lực trong thời gian giải đấu diễn ra của tháng hiện tại.',
  tc6: 'Để đảm bảo giải đấu được công bằng cho tất cả khách hàng, mỗi thành viên được giới hạn tham gia một tài khoản và chỉ được dùng một địa chỉ IP để tham gia. Những người chơi vi phạm sẽ bị truất quyền nhận thưởng. Trường hợp vi phạm nghiêm trọng, chúng tôi có quyền đưa người chơi vào danh sách đen và khấu trừ các lợi nhuận liên quan mà không cần thông báo trước.',
  tc7: 'Bất kỳ người chơi nào có hành vi không hợp lệ trong giải đấu, bao gồm nhưng không giới hạn việc cược đối đầu, hợp tác gian lận và tương tự, được xem là vi phạm các điều khoản tương đương với điều 6;',
  tc8: 'Nếu người chơi vi phạm luật thi đấu, người chơi sẽ mất quyền tham gia.',
  tc9: 'Tham gia các sự kiện AG đồng nghĩa với việc người chơi đồng ý với các điều khoản và điều kiện của các sự kiện này.',
  tc10: 'AG có quyền đưa ra giải thích và quyết định cuối cùng cho sự kiện này.',
  remark: 'Nhận xét',
  registration: 'Đăng ký',
  registration1: 'Nếu người chơi sở hữu vé Giải đấu chính và đủ điểm để tham gia chơi cùng lúc, người chơi có thể lựa chọn trừ điểm hoặc sử dụng vé tham gia giải đấu.',
  registration2: 'Nếu người chơi đã đăng ký giải đấu (Giải Vệ Tinh hoặc Master), trong vòng 20 phút trước khi giải đấu đó bắt đầu, người chơi không được đăng ký bất kỳ giải đấu nào khác.',
  registration2_1: 'Để đảm bảo người chơi có thể tham gia Giải Đấu Casino Master, trong vòng 20 phút trước khi giải đấu bắt đầu, người chơi đã đăng ký sẽ không thể đăng ký một Giải Vệ Tinh khác.',
  registration2_2: 'Nếu Giải Đấu Casino Master còn từ 20 phút trở lên mới bắt đầu, người chơi có thể đăng ký tham gia giải đấu Vệ Tinh. Nếu Giải Đấu Casino Master sẽ bắt đầu trong vòng 20 phút và Giải Đấu Vệ Tinh mà người chơi đăng ký trước đó chưa bắt đầu, hệ thống sẽ tự động hủy tham gia giải Vệ Tinh và điểm người chơi sẽ được hoàn lại.',
  cancelReg: 'Hủy bỏ đăng ký',
  cancelReg1: 'Mỗi người chơi được phép hủy đăng ký trước khi giải đấu bắt đầu. Sau khi hủy đăng ký trong vòng 3 phút không được phép đăng ký lại cùng một giải đấu.',
  cancelReg2: 'Khi giải đấu đang diễn ra, nếu người chơi chọn một trò chơi khác hoặc rời khỏi giải đấu, thứ hạng và cơ hội chiến thắng sẽ được đánh giá dựa trên số lượng cược hợp lệ và số vòng mà người chơi đã đặt.',
  watch: 'Xem',
  watch1: 'Tất cả người chơi có thể chọn “Xem” để xem giải đấu đang diễn ra, khi ở trong chế độ xem, người chơi không được phép đặt cược nhưng có thể sử dụng chức năng nhắn tin.',
  exception: 'Ngoại lệ',
  exception1: 'Trong trường hợp xảy ra sự cố máy chủ, lỗi hệ thống và ảnh hưởng đến việc vận hành giải đấu, chúng tôi có quyền hủy giải đấu và hoàn trả tất cả các điểm đã trừ hoặc vé lại cho các người chơi đã đăng ký thành công.',
  exception2: 'Khi giải đấu đã bắt đầu, nếu việc ngắt kết nối xảy ra do đường truyền của người chơi, người chơi phải đăng nhập lại trong vòng năm (5) vòng và trở lại giải đấu ngay khi có thể. Trong 5 vòng đấu này, hệ thống đặt cược tự động sẽ đặt cược số tiền tối thiểu tại mục Player. Nếu người chơi không thể đăng nhập lại trong vòng năm (5) vòng, người chơi sẽ tự động bị loại do không đặt cược. Người chơi vẫn được xem xét trong bảng xếp hạng nhưng sẽ không được nhận giải thưởng.',
  exception3: 'Bất kỳ người chơi nào gian lận hoặc đặt cược đối đấu trong giải đấu sẽ bị hủy quyền thi đấu và giải thưởng. Chúng tôi có quyền đưa người chơi vào danh sách đen và người chơi sẽ không được tham gia vào bất kỳ sự kiện hoặc hoạt động nào khác của chúng tôi.',
  q1: 'Cách tham gia Giải Đấu Quý?',
  faq1: 'Cách tham gia Giải Đấu Quý: giành một vé của giải đấu Quý hoặc tích luỹ điểm.\n\nCách giành vé Giải Đấu Quý: người thắng cuộc của Giải Đấu Chuyên Nghiệp của mùa giải sẽ nhận một vé tham gia Giải Đấu Quý trước khi Giải Đấu Quý bắt đầu. Vé mời sẽ hữu dụng để tham gia giải đấu của Quý đó.',
  q2: 'Sảnh Giải Đấu Casino Master ở đâu?',
  faq2: 'Khi thành công đăng nhập trên phiên bản AG Desktop, bạn sẽ thấy mục Giải Đấu Casino Master tại thanh điều hướng bên trái. Nhấp để truy cập vào sảnh giải đấu. Nếu không thấy mục giải đấu, vui lòng liên hệ với nhà điều hành hoặc hỗ trợ khách hàng của bạn để biết thêm thông tin. Phiên bản HTML5 dành cho thiết bị di động cũng có mục riêng dẫn tới sảnh giải đấu, tương tự như phiên bản dành cho máy tính để bàn.',
  q3: 'Đăng ký như thế nào?',
  faq3: 'Chỉ cần chọn sảnh giải đấu và nhấp vào nút “Đăng ký”.',
  q4: 'Yêu cầu để tham gia giải đấu là gì?',
  faq4: 'Bạn cần một tài khoản cá nhân AG bằng tiền thật, và tài khoản phải có đủ điểm hoặc vé giải đấu cho việc khấu trừ để tham gia các sự kiện giải đấu của chúng tôi.',
  q5: 'Tỉ lệ quy đổi điểm như thế nào?',
  faq5: '100 RMB tiền cược hợp lệ = 1 điểm. Bạn có thể kiểm tra chi tiết tại mục Điểm Điểm Người Chơi.\nVí dụ:\nNgười chơi A đạt 10,000 RMB tiền cược hợp lệ, người chơi A được 100 điểm;\nNgười chơi B đạ 8,650 RMB tiền cược hợp lệ, người chơi B được 86 điểm;\nĐiểm sẽ được tính dựa trên số nguyên, không tính phần thập phân và sẽ không được làm tròn.',
  q6: 'Tôi có thể kiểm tra số Điểm hiện tại ở đâu?',
  faq6: 'Nhấp vào nút thông tin (i) bên cạnh hình đại diện của bạn, sau đó chi tiết tài khoản cá nhân sẽ được hiển thị, bao gồm số dư điểm của tài khoản:\nHoặc nhấp vào sảnh Giải Đấu Casino Master, góc trên bên phải sảnh sẽ hiển thị các điểm của tài khoản cá nhân của bạn:',
  q7: 'Làm thế nào để kiếm được nhiều điểm hơn?',
  faq7: 'Số điểm người chơi được dựa trên tiền cược hợp lệ trên tất cả các trò chơi trực tuyến của AG. Số điểm nhận được không có giới hạn và sẽ không bị hết hạn. Các trò chơi trực tuyến bao gồm: bất kỳ loại cược Baccarat, Dragon Tiger, Sic Bo, Roulette, Blackjack, Bull Bull, Win Three Cards, BID Baccarat, MultiPlay, VIP Baccarat và Good Road.\n\nLưu ý: Tiền tặng cho bình luận viên, đặt cược các trò không phải Casino trực tuyến đều không được tính vào điểm.',
  q8: 'Cược hợp lệ hoặc cược không hợp lệ là gì?',
  faq8: 'Cược hợp lệ: Là tất cả các cược thắng hoặc thua.\nCược không hợp lệ: Nếu người chơi trong cùng một trò chơi cùng lúc có 2 kết quả cược thắng và thua.\nVí dụ: Trong Baccarat, người chơi đặt cược vào “Banker” và “Player” cùng một lúc và cùng một ván.',
  q9: 'Điểm khác nhau giữa Giải Vệ Tinh và Giải Đấu Casino Master là gì?',
  faq9: 'Giải Vệ Tinh: Không có lịch đấu cụ thể. Giải đấu bắt đầu ngay sau khi có đủ người đăng ký tại bàn. Người chiến thắng sẽ nhận được một vé đăng ký Giải Đấu Casino Master.\nGiải Đấu Casino Master: Giải đấu Master có thời gian bắt đầu cụ thể. Giải thưởng của Giải đấu Master sẽ rất lớn, tạo thêm tính hấp dẫn cho giải đấu.',
  q10: 'Phí đăng ký là bao nhiêu?',
  faq10: 'Người chơi không tốn phí đăng ký giải đấu của sự kiện. Người chơi chỉ cần sử dụng Điểm Người Chơi hoặc vé giải đấu khi đăng ký giải đấu.',
  q11: 'Làm thế nào để có được vé Giải Đấu Casino Master?',
  faq11: 'Vé Giải Đấu Casino Master được trao cho người chơi thắng giải Vệ Tinh. Bạn cần có đủ Điểm Người Chơi để tham gia vào Giải Vệ Tinh và phải thắng giải Vệ Tinh để được nhận vé Giải Đấu Casino Master để tham gia Giải Đấu Casino Master.\nGhi chú: Mỗi người chơi có thể tích lũy tối đa 10 vé Giải Đấu Casino Master. Vé chỉ có hiệu lực trong thời gian giải đấu diễn ra của tháng hiện tại.Vào cuối ngày, tất cả các vé chưa sử dụng sẽ hết hạn và AG sẽ không trả lại hoặc hoàn trả Điểm Người Chơi.',
  q12: 'Tôi có thể sử dụng Điểm Người Chơi để đổi vé Giải Đấu Casino Master không?',
  faq12: 'Có, khi bạn không có vé Giải Đấu Casino Master nhưng có rất nhiều Điểm Người Chơi. Chỉ cần nhấp vào nút Đăng ký Giải Đấu Casino Master và hệ thống giải đấu sẽ trực tiếp trừ số Điểm Người Chơi đã nêu trong chi tiết giải đấu.\nLưu ý: Với Giải Vệ Tinh, chỉ có thể dùng Điểm Người Chơi để dăng ký, không thể dùng vé.',
  q13: 'Tôi có thể lựa chọn trừ điểm trước hoặc sử dụng vé trước không?',
  faq13: 'Giải đấu vệ tinh sẽ chỉ trừ điểm. Giải đấu chính, Quý Khách có thể lựa chọn trừ điểm hoặc sử dụng vé để tham gia.',
  q14: 'Tài khoản chơi thử là có được phép đăng ký giải đấu không?',
  faq14: 'Tất cả các giải đấu của chúng tôi chỉ dành cho tài khoản bằng tiền thật. Tài khoản chơi thử AG chỉ có thể dùng chế độ xem, tức là chỉ xem giải đấu đang diễn ra. Nếu bạn muốn tạo một tài khoản bằng tiền thật, vui lòng liên hệ nhân viên chăm sóc khách hàng.',
  q15: 'Tại sao có thông báo đăng ký không thành công?',
  faq15: 'Đăng ký không thành công có thể có các lý do sau:\nTài khoản của bạn không phải là tài khoản bằng tiền thật hoặc không đạt các yêu cầu của giải đấu;\nBạn đã đăng ký và tự hủy đăng ký giải đấu trước đó. Trường hợp này bạn phải chờ ít nhất 3 phút để được đăng ký lại vào giải đấu cùng loại khác.',
  q16: 'Sau khi đăng ký có thể hủy đăng ký không?',
  faq16: 'Nếu đăng ký (khi giải đấu chưa đạt đủ lượng người chơi yêu cầu) và giải đấu chưa bắt đầu:\nNgười chơi được hủy đăng ký ngay lập tức, nhưng sẽ không thể đăng ký giải đấu cùng loại khác trong vòng 3 phút. Hệ thống sẽ tự hoàn trả Điểm Người Chơi hoặc vé đã trừ.\nNếu đăng ký (khi giải đấu đã đạt đủ lượng người chơi yêu cầu) và giải đấu chưa bắt đầu:\nNgười chơi phải tham gia giải đấu sau đó thoát khỏi giải đấu, hệ thống sẽ coi như người chơi đã bị loại. Hệ thống sẽ không trả lại bất kỳ Điểm Người Chơi hoặc vé đã trừ.\nNgười chơi chủ động bỏ cuộc khi giải đấu đang diễn ra:\nKhi giải đấu đang diễn ra, nếu người chơi chọn trò chơi khác hoặc rời khỏi giải đấu, hạng của người chơi và cơ hội chiến thắng sẽ được đánh giá bởi số lượng cược hợp lệ và số vòng mà người chơi đã đặt.\nLưu ý:\n1. Nếu đăng ký một giải đấu và sau đó hủy đăng ký, người chiơ sẽ không thể đăng ký lại giải đấu đó nữa;\n2. Mỗi người chơi chỉ có thể hủy đăng ký giải đấu tối đa 5 lần trong 1 ngày. Nếu người chơi hủy hơn 5 lần trong 1 ngày, người chơi sẽ không thể đăng ký lại loại giải đấu đó nữa.',
  q17: 'Sau khi đăng ký, tôi có cần phải đợi ở sảnh đến khi bắt đầu giải đấu không?',
  faq17: 'Người chơi không cần phải đợi giải đấu bắt đầu, người chơi có thể vào các game khác. Trước hoặc gần thời gian bắt đầu, hệ thống sẽ gửi tin nhắn thông báo cho người chơi chuẩn bị tham gia giải đấu.',
  q18: 'Mỗi giải đấu được tổ chức trong bao lâu?',
  faq18: 'Mỗi giải đấu được tổ chức khoảng 5 đến 20 phút.',
  q19: 'Giải đấu có sử dụng tiền thật để chơi không?',
  faq19: 'Tất cả những người tham gia chỉ được phép sử dụng chip giải đấu. Toàn bộ chip giải đấu không liên quan đến số dư tiền thật của người tham gia.',
  q20: 'Chip giải đấu là gì?',
  faq20: 'Giải đấu Casino Master tôn trọng sự công bằng và minh bạch, tất cả những người tham gia giải đấu đều có cùng số lượng chip khởi đầu, được gọi là chip giải đấu và được dùng để xác định xếp hạng. Chip không liên quan đến số dư tài khoản tiền thật của người tham gia và chỉ được phép sử dụng trong giải đấu. Sau mỗi Cấp, chip giải đấu sẽ bị xóa.',
  q21: 'Khán giả có thể đặt cược trong giải đấu?',
  faq21: 'Chỉ những người chơi đăng ký mới có thể đặt cược lên bàn cược. Các khán giả không thể đặt cược và chỉ có thể xem bên lề. Nếu bạn muốn tham gia, bạn có thể nhấn nút “đăng ký” tại sảnh giải đấu.',
  q22: 'Mục \'Trực tuyến\' nghĩa là gì?',
  faq22: 'Tổng số người chơi đã đăng ký tham gia giải đấu.',
  q23: 'Giới hạn đặt cược cho từng loại cược là bao nhiêu?',
  betLimit: 'Giới Hạn Cược',
  bankerPair: 'Banker Đôi',
  playerPair: 'Player Đôi',
  q24: 'Tại sao không thể đặt cược vào “Đôi” và “Hòa”?',
  faq24: 'Tất cả người chơi phải đặt cược vào Banker hoặc Player trước khi có thể đặt cược vào Đôi và Hòa.',
  q25: 'Cược Ẩn là gì?',
  faq25: 'Cược Ẩn là một trong những kỹ thuật đặt cược chiến lược, chức năng này ẩn đi lựa chọn cược và số tiền đặt cược của người chơi. Chi tiết của Cược Ẩn sẽ được tiết lộ trước khi kết thúc ván bài.',
  q26: 'Bỏ Cược là gì?',
  faq26: '\'Bỏ Cược\' là bỏ qua vòng cược hiện tại. Nếu bạn không muốn đặt cược vòng hiện tại, bạn có thể chỉ cần nhấp vào nút \'Bỏ Cược\'.',
  q27: 'Số lượt Cược Ẩn và Bỏ Cược có được tích lũy không?',
  faq27: 'Tất cả lượt Cược Ẩn và Bỏ Cược chỉ được sử dụng trong giải đấu. Khi giải đấu kết thúc, tất cả số lượt Cược Ẩn và Bỏ Cược sẽ bị hủy.',
  q28: 'Trong Giải đấu Casino Master, làm thế nào để quyết định thăng hạng hay bị loại?',
  faq28: 'Để đảm bảo sự công bằng và minh bạch giữa những người chơi, Giải đấu Casino Master sẽ sử dụng luật thăng hạng và loại trừ sau đây:\nTrong Giải đấu, sau mỗi Cấp đấu hệ thống sẽ thăng hạng cho một số lượng người chơi cố định, được quy định dựa theo luật chơi của từng giải đấu. Các người chơi không được thăng hạng sẽ tự động bị loại. Ví dụ: Có 56 người chơi tham gia giải đấu, các người chơi được sắp xếp chơi trong 8 bàn chơi ảo (A 、 B 、 C 、 D 、 E 、 F 、 G 、 H). Giải đấu sẽ được chia thành 4 cấp (mỗi cấp 5 vòng). Sau khi kết thúc mỗi cấp, ba người chơi đứng đầu của mỗi bàn sẽ được thăng hạng. Nghĩa là từ bàn A đến H, 3 người chơi đứng đầu mỗi bàn sẽ được chơi ở cấp kế tiếp, các người chơi khác sẽ bị loại. Và sẽ tiếp tục như vậy cho đến khi kết thúc giải đấu.',
  q29: 'Làm thế nào để xác định thứ hạng của người chơi?',
  faq29: 'Luật xếp hạng sẽ sử dụng thứ tự ưu tiên như sau:\nNhững người chơi được thăng hạng có thứ hạng cao hơn những người chơi bị loại, sau đó xếp hạng dựa trên số chip giải đấu còn lại khi kết thúc;\nNếu nhiều người chơi có cùng số chip giải đấu còn lại khi kết thúc giải, người chơi có tổng số chip cược hợp lệ cao hơn sẽ được xếp hạng cao hơn;\nNếu có nhiều hơn một người chơi có cùng thứ hạng sau hai luật xếp hạng trên, những người chơi đăng ký trước sẽ được xếp hạng cao hơn.',
  q30: 'Làm thế nào để phân chia phần thưởng hoặc tiền mặt?',
  faq30: 'Vui lòng nhấp vào nút “Chi tiết” để xem thông tin.',
  q31: 'Làm cách nào để nhận tiền thưởng từ giải đấu?',
  faq31: 'Tất cả tiền thưởng từ giải đấu sẽ được cập nhật vào tài khoản của người chơi ngay mà không cần chờ kiểm duyệt.',
  q32: 'Tại sao những người chơi khác lại có chip cao hơn tôi khi Cấp đấu thứ hai bắt đầu?',
  faq32: 'Để đảm bảo cạnh tranh công bằng, khi bắt đầu cấp đấu kế tiếp, các người chơi đứng đầu ở mỗi bàn sẽ có số chip cao hơn số chip khi bắt đầu giải đấu. Phần trăm chip được tăng sẽ được cố định.',
  q33: 'Nếu giải đấu bị hủy, điểm và vé đã cùng có được hoàn trả?',
  faq33: 'Điểm hoặc vé sẽ được trả lại. Không có bất kỳ khoản khấu trừ nào.',
  q34: 'Tôi có thể tìm lịch sử đặt cược ở đâu?',
  faq34: 'Người chơi có thể kiểm tra tại mục: \'Dịch vụ khách hàng\'> \'Lịch sử tài khoản\' > \'Lịch sử đặt cược Giải Đấu\'.',
  q35: 'Danh sách “Top Master” là gì?',
  faq35: 'Danh sách Top Master thể hiện các người chơi đỉnh cao đã thắng nhất tại các Giải đấu Casino Master. Nhấp vào tên của người chơi sẽ cho biết giải thưởng mà người chơi đó nhận được cùng với thông tin về giải đấu.',
  q36: 'Tôi nên làm gì nếu mạng của tôi đột nhiên bị ngắt kết nối trong giải đấu?',
  faq36: 'Khi giải đấu đang diễn ra, đường truyền của bạn bị ngắt kết nối, hệ thống đặt cược tự động sẽ đặt cược số tiền tối thiểu tại mục Player. Nếu người chơi không thể đăng nhập lại và tham gia lại giải đấu trong vòng 5 vòng, người chơi sẽ tự động bị loại. Người chơi vẫn được xem xét trong bảng xếp hạng nhưng sẽ không được nhận giải thưởng.',
  topMasterCongrats: 'Chúc mừng những người chơi chiến thắng tại Giải Đấu Casino Master',
  tournamentSetting: 'Cài đặt',
  others: 'Khác',
  regQ3Faq: 'Trong thời gian diễn ra giải đấu, tất cả các tài khoản tiền thật của AG không phải trả bất kỳ khoản phí đăng ký và xử lý nào. Khi người chơi muốn đăng ký Giải đấu Cao thủ, cần phải có đủ điểm hoặc (các) vé Giải đấu Cao thủ.'
};

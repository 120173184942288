export const th = {
  gog: 'เทพเจ้าแห่งการพนัน',
  introduction: 'ข้อมูล',
  award: 'รางวัล',
  details: 'รายละเอียด',
  faq: 'คำถามทั่วไป',
  new: 'ใหม่',
  topMaster: 'อันดับสูงสุด',
  backToTop: 'กลับไปด้านบน',
  introductionDetails: 'AG Master Tournament ' +
    'คือทัวร์นาเม้นต์แข่งขันซึ่งใช้เกมไพ่บาคาร่าแบบ Sit n Go ' +
    'เป็นหลัก โดยมีการออกกฎและกติกาในการแข่งขันอย่างชัดเจน ' +
    'เพื่อให้ผู้เข้าร่วมทุกท่านได้รับความบันเทิงถึงขีดสุด',
  howToEnter: 'วิธีการมีส่วนร่วม',
  gameProcess: 'กระบวนการ',
  step: 'ขั้นตอนที่',
  step1: 'การแข่งขันนี้สำหรับบัญชีเงินจริงของAGเท่านั้น',
  step2: 'เลือกล็อบบี่มาสเตอร์ทัวร์นาเม้นต์และเลือกการแข่งขันที่ต้องการ',
  howToJoin1: 'การแข่งขันนี้สำหรับบัญชีเงินจริงของAGเท่านั้น',
  howToJoin2: 'เลือกล็อบบี่มาสเตอร์ทัวร์นาเม้นต์และเลือกการแข่งขันที่ต้องการ',
  satelliteTournament: 'จะไม่กำหนดเวลาในการแข่งขัน ' +
    'เมื่อมีผู้เข้าร่วมเพียงพอการแข่งขันจะเริ่มทันที ' +
    'ผู้ชนะจะได้รับตั๋วเพื่อเข้าไปร่วมใน มาสเตอร์ทัวร์นาเม้นต์',
  masterTournament: 'จะมีกำหนดเวลาเข้าร่วมการแข่งขันอย่างชัดเจน ' +
  'ผู้ลงทะเบียนจะถูกเชิญเมื่อการแข่งขันเริ่มต้นขึ้น',
  seasonTournament: 'การแข่งขันรายไตรมาสจะใช้โหมดเวลาคงที่ในการเริ่มแข่งขัน ' +
    'เชิญผู้ชนะมาสเตอร์ทัวร์นาเมนท์ประจำฤดูกาลมาเข้าแข่งขันกับผู้เ' +
    'ล่นที่ลงทะเบียนเข้าม' +
    'าเพื่อสะสมคะแนน ผู้ชนะจะได้รับรางวัลมากมาย!',
  awardDetails: 'รางวัลผู้ชนะทั้งหมดจะถูกปรับเข้าสู่บัญชีผู้เล่นทั้งหมด ' +
    'เมื่อการแข่งขันสิ้นสุดและผ่านการตรวจสอบแล้ว',
  qualifyingRound: 'แซทเทิลไลท์ทัวร์นาเม้นต์',
  qualRoundText: 'ผู้ชนะของการแข่งขันดาวเทียมจะได้รับตั๋วเพื่อเข้าสู่การแข่งขันมาสเตอร์และรับรางวัลเพิ่มเติม',
  gogTickets: 'ตั๋วการแข่งขันมาสเตอร์\nหรือคะแนนผู้ใช้ที่จำเป็น',
  gogMasterTournament: 'การแข่งขันมาสเตอร์',
  gogStep3: 'จะมีกำหนดเวลาเข้าร่วมการแข่งขันอย่างชัดเจน ผู้ลงทะเบียนจะถูกเชิญเมื่อการแข่งขันเริ่มต้นขึ้น',
  tournamentRules: 'กฎการแข่งขัน',
  fairness: 'ความเป็นธรรม',
  regAndWithdrawal: 'สมัครสมาชิก / ถอนเงิน',
  inGame: 'กฎ',
  ranking: 'การจัดอันดับ',
  withdraw3: 'ถอนตัวหลังจากลงทะเบียน (เมื่อไม่พบคนที่ต้องการ) และทัวร์นาเมนต์ยังไม่เริ่ม',
  withdraw3Child: 'ผู้เล่นสามารถถอนได้ทันที และภายใน 3 นาที ผู้เล่นไม่สามารถลงทะเบียนการแข่งขันประเภทเดิมได้อีก ระบบจะคืนคะแนน/ตั๋วที่หักไป',
  withdraw4: 'การถอนตัวหลังจากการลงทะเบียน (ถึงจำนวนผู้เข้าร่วม) และการแข่งขันยังไม่เริ่ม:',
  withdraw4Child: 'ผู้เข้าร่วมต้องเข้าร่วมทัวร์นาเมนต์เพื่อถอนตัว ผู้เข้าร่วมจะถูกคัดออกโดยระบบ คะแนนผู้ใช้หรือตั๋วที่ถูกหักทั้งหมดจะไม่ได้รับการคืนเงิน',
  withdraw5: 'ผู้เข้าร่วมตัดสินใจออกจากการแข่งขันระหว่างการแข่งขัน',
  withdraw5Child: 'ในระหว่างทัวร์นาเมนต์ หากผู้เข้าร่วมคนใดคนหนึ่งเลือกเกมอื่นหรือออกจากทัวร์นาเมนต์ อันดับและโอกาสในการชนะจะตัดสินจากจำนวนการเดิมพันที่ถูกต้องและจำนวนรอบที่ผู้เข้าร่วมวาง',
  withdraw6: 'ผู้เล่นจะไม่สามารถลงทะเบียนเกมเดิมซ้ำได้หลังจากออกจากเกมนั้นแล้ว',
  withdraw7: 'ผู้เล่นแต่ละคนสามารถถอนตัวจากทัวร์นาเมนต์ที่ลงทะเบียนภายในวันเดียวกันได้สูงสุด 5 ครั้ง หากเกิน 5 ครั้ง ผู้เล่นจะไม่ได้รับอนุญาตให้ลงทะเบียนทัวร์นาเมนต์ประเภทเดียวกัน',
  tournamentRules1: 'หลังจากผู้เล่นลงทะเบียนสำเร็จจะได้รับชิป หรือ ทัวร์นาเม้นต์เครดิตเพื่อร่วมแข่งในทัวนาเม้นท์ (ชิปการแข่งขันไม่มีค่าเป็นเงิน และใช้เพื่อแข่งในทัวนาเม้นต์เท่านั้น) ชิปที่เหลือจากการแข่งขันก่อนหน้าจะไม่สามารถยกยอดมาใช้กับการแข่งขันในนี้หรือรอบต่อไปได้ และหาก ยูสเซอร์พอยท์ ของผู้เล่นมีไม่พอ ผู้เล่นสามารถสะสมพอยท์ได้จากการเดิมพันเงินจริงในคาสิโนโอเรียนทอลสวีท',
  tournamentRules2: 'เพื่อความโปร่งใสในการแข่ง หลังลงทะเบียนสำเร็จระบบจะสุ่มผู้เล่นให้เข้านั่งโต๊ะ โดยที่นั่งนี้ไม่สามารถนำไปแลกกับผู้เล่นท่านอื่นได้',
  tournamentRules3: 'เงินรางวัลจะถูกปรับไปยังบัญชีผู้เล่น ตามสัดส่วนที่ท่านได้รับ',
  tournamentRules4: 'ในการแข่งขัน เราเลือกใช้เกมไพ่บาคาร่าแบบไม่มีคอมมิชชัน หรือไม่มีค่าต๋ง รายละเอียดเพิ่มเติมคลิกเมนู > กฎบาคาร่า ก่อนการเปิดไพ่ในแต่ละรอบผู้แข่งขันมีเวลา 25 วินาทีเพื่อเลือกเดิมพันของตนเองซึ่งเดิมพันหลักได้แก่ Player หรือ Banker จากนั้นจึงจะสามารถวางเดิมพัน Tie / Player Pair / Banker Pair พ่วงได้',
  tournamentRules5Part1: 'ก่อนทัวร์นาเม้นต์เริ่มผู้แข่งขันจะได้รับชิปตั้งต้นมูลค่า 100,000 เครดิตเท่ากันทุกคน และลิมิตเดิมพันที่ 1,000-10,000',
  tournamentRules5Part2: 'ผู้แข่งขันสามารถวางเดิมพันแต่ละชนิดได้ดังตารางที่แสดงด้านล่าง',
  betItem: 'ชนิด',
  minBet: 'ขั้นต่ำ',
  maxBet: 'สูงสุด',
  banker: 'Banker',
  player: 'Player',
  tie: 'Tie',
  pair: 'Pair',
  tournamentRules6: 'ก่อนเริ่มทุกทัวร์นาเม้นท์จะไม่เปิดให้เดิมพันใน 2 เกมแรกและไม่มีการเปลี่ยนสำรับไพ่ในระหว่างแข่งขัน',
  tournamentRules7: 'กฎการคัดเลือกผู้แข่งขันที่ได้ไปต่อ หรือ ตกรอบ',
  tournamentRules7_1: 'การคัดสรรค์ผู้เข้าร่วมหรือตกรอบเป็นการกระทำผ่านระบบทั้งสิ้น',
  tournamentRules7_2: 'ผู้แข่งจำนวน 56 คน แยกนั่ง 8 โต๊ะ ๆ ละ 7 (A、B、C、D、E、F、G 、H ) จากนั้นคัด 3 อันดับแรกของแต่ละโต๊ะแข่งรอบสอง ผู้แข่งจำนวน 24 คน แยกนั่ง 4 โต๊ะ ๆ 6 จากนั้นคัด 3 อันดับแรกเล่นรอบสาม ผู้แข่งจำนวน 12 คน แยกนั่ง 2 โต๊ะ ๆ 6 จากนั้นคัด 3 อันดับแรกเล่นรอบสี่ ผู้แข่งจำนวน 6 คน นั่งโต๊ะรอบสุดท้ายเพื่อประกาศผลผู้ชนะตามอันดับในรอบนี้',
  tournamentRules7_3: 'ผู้แข่งที่ผ่านเข้ารอบคนแรกจะได้รับชิปพิเศษตามเปอร์เซนต์ที่กำหนดเมื่อเริ่มแข่งในรอบต่อไป',
  tournamentRules7_4: 'ระบบสุ่มที่นั่งให้กับผู้เล่นทุกครั้งที่ขึ้นรอบใหม่',
  tournamentRules8: 'ก่อนทัวร์นาเม้นต์เริ่ม ผู้แข่งจะได้รับโควต้าการใช้งานฟังก์ชันเดิมพันพิเศษดังนี้',
  tournamentRules8_1: 'Blind Bet : ปิด/ซ่อน เงินเดิมพันเพื่อไม่ให้เพื่อนร่วมโต๊ะทราบ แต่ตัวเลขเงินเดิมพันของท่านแสดงผลสั้น ๆ ไม่กี่วินาทีก่อนการเปิดไพ่ ทั้งนี้จะได้รับกี่ครั้งขึ้นอยู่กับจำนวนรอบที่จะเกิดขึ้นในการแข่งขัน เช่นหากรอบนั้นมีผู้เข้าร่วมจำนวนเพียงพอที่จะแข่งกันได้ห้ารอบ ผู้แข่งจะได้รับ 5 สิทธิ์ในการ ปิด/ซ่อนเงินเดิมพัน',
  tournamentRules8_2: 'ข้ามการเดิมพันในการเปิดไพ่รอบนี้ จำนวนครั้งของการได้สิทธิ์นี้ใช้วิธีคิดเดียวกับฟังก์ชันด้านบน',
  tournamentRules9: 'เมื่อจบการแข่งแต่ละรอบ ชิปและฟังก์ชันพิเศษ ในบัญชีผู้เล่นจะถูกปรับให้เป็น 0 เสมอ',
  tournamentRules10: 'กฎการจัดอันดับ',
  tournamentRules10_1: 'ชิปมากสุดอยู่สูงสุด',
  tournamentRules10_2: 'หากชิปเท่ากัน จะเลือกนับเฉพาะจำนวนชิปจริงเท่านั้น ชิปที่ได้พิเศษในการเข้ารอบเป็นคนแรกจะถูกหักออกไป',
  tournamentRules10_3: 'หากยังเท่ากัน ผู้เล่นที่ลงทะเบียนเข้าแข่งก่อนจะได้รับสิทธิ์เป็นผู้มีอันดับสูงกว่า',
  tournamentRules11: 'หากในระหว่างแข่ง ผู้แข่งขาดการวางเดิมพันเกิน 5 มือ เมื่อจบรอบการแข่งขันท่านจะมีอันดับแต่จะไม่สามารถรับเงินรางวัลได้',
  tc: 'ข้อกำหนดการแข่งขัน',
  tc1: 'เป็นผู้เล่นเงินจริงเท่านั้น',
  tc2: 'ในช่วงโปรโมชั่นผู้เล่นที่ต้องการเข้าร่วม แซทเทิลไลท์ทัวร์นาเม้นต์ ต้องใช้ ยูสเซอร์พ้อยต์แลกเพื่อเข้าเล่น',
  tc3: 'ทัวร์นาเม้นต์เริ่มเวลาตามที่ประกาศในหน้าล็อบบี้ ซึ่งขอสงวนสิทธิ์ในการเปลี่ยนแปลง/ดีเลย์/หรือยกเลิกเวลาเริ่มแข่งโดยไม่ต้องแจ้งให้ท่านทราบล่วงหน้า',
  tc4: 'ยูสเซอร์พอยท์สามารถสะสมได้จากการเดิมพันในเกมดังต่อไปนี้ Live Game only, this is included: any type of Baccarat, DragonTiger, SicBo, Roulette, Blackjack, Win Three Cards, BID, MultiPlay, VIP and Good Road betting ข้อมูลเพิ่มเติมเกี่ยวกับยูสเซอร์พอยท์ กรุณาคลิกที่นี่',
  tc5: 'ผู้ชนะจาก \'แซทเทิลไลท์ทัวร์นาเม้นต์\' จะได้รับตั๋ว ผ่านเข้าไปเล่นใน \'มาสเตอร์ทัวร์นาเม้นต์\' (หากตั๋วเข้าร่วมครบจำนวนแล้ว ทัวร์นาเม้นต์ทั้งหมดจะไม่เปิดรับการลงทะเบียนเข้าร่วม โดยเป็นไปตามลำดับก่อนหลัง) ตั๋วที่ได้ต้องใช้กับทัวร์นาเม้นท์ที่จัดขึ้นภายในเดือนเดียวกันเท่านั้น',
  tc6: 'จำกัดหนึ่งผู้เล่นต่อหนึ่งบ้านเลขที่, ที่อยู่, อุปกรณ์เดิมพัน เท่านั้น หากพบความไม่โปร่งใสของผู้เล่น ขอยึดเงินรางวัลคืนหรือตัดสินเป็นอย่างอื่น ตามผู้จัดเห็นสมควร',
  tc7: 'หากพบการโกงไม่ว่าจะด้วยกรณีหรือวิธีการใดก็แล้วแต่ จะขอใช้การพิจารณาเช่นเดียวกับข้อ 6',
  tc8: 'หากละเมิดกฎการแข่งขัน จะถูกตัดสิทธิ์ทันที',
  tc9: 'ผู้แข่งขัน เมื่อตัดสินใจเข้าร่วมทัวร์นาเม้นต์แล้วถือเป็นการยอมรับได้โดยปริยายภายได้ข้อกำหนดและเงื่อนไขที่ทางผู้จัดได้สร้างขึ้น',
  tc10: 'AG (“ผู้จัด”) ขอเป็นผู้ตัดสินเด็ดขาดแต่เพียงผู้เดียว',
  remark: 'หมายเหตุ',
  registration: 'ลงทะเบียน',
  registration1: 'หากผู้เล่นเป็นเจ้าของตั๋ว Master Tournament และมีคะแนนผู้ใช้เพียงพอในเวลาเดียวกันผู้เล่นสามารถเลือกหักคะแนนผู้ใช้หรือตั๋วมาสเตอร์ทัวร์นาเมนต์ได้',
  registration2: 'ทัวร์นาเม้นต์เปิดให้ลงทะเบียน 20 นาทีก่อนแข่ง และผู้แข่งสามารถเลือกได้เพียงทัวนาเม้นต์เดียวเท่านั้น (แซทเทิลไลท์ หรือ มาสเตอร์ทัวร์นาเม้นต์)',
  registration2_1: 'หากผู้แข่งลงทะเบียนใน มาสเตอร์ทัวร์นาเม้นต์ สำเร็จและการแข่งขันจะมีขึ้นภายใน 20 นาที ผู้แข่งจะไม่สามารถลงทะเบียนที่ แซทเทิลไลท์ทัวร์นาเม้นต์ได้',
  registration2_2: 'หากผู้แข่งลงทะเบียนใน แซทเทิลไลท์ทัวร์นาเม้นต์ และ มาสเตอร์ทัวร์นาเม้นต์ สำเร็จทั้งคู่และมาสเตอร์ทัวร์นาเม้นต์เริ่มแข่งก่อน ผู้แข่งจะได้เล่นในมาสเตอร์ทัวร์นาเม้นต์ และได้รับรีฟันคืนเป็นจำนวนพอยท์หรือตั๋วที่โดนหักในส่วนของแซทเทิลไลท์ทัวร์นาเม้นต์คืน',
  cancelReg: 'ยกเลิกการเข้าแข่ง',
  cancelReg1: 'ผู้เล่นแต่ละคนอนุญาตให้ยกเลิกการลงทะเบียนก่อนการแข่งขันจะเริ่มขึ้น หลังจากยกเลิกการลงทะเบียนภายใน 3 นาทีจะไม่อนุญาตให้ลงทะเบียนสำหรับทัวร์นาเมนต์เดิมอีกครั้ง',
  cancelReg2: 'ในระหว่างการแข่ง หากผู้แข่งออกจากการแข่งขัน เราจะตัดสินอันดับของท่านจากจำนวนเกม/รอบและจำนวนเครดิตที่ท่านเล่นไปแล้ว',
  watch: 'เข้าชม',
  watch1: 'ผู้ที่ไม่ได้แข่งขัน สามารถเข้าชมการแข่งขันได้ ด้วยฟีเจอร์ “Watch” สามารถดูและส่งข้อความระหว่างการแข่งขันได้ แต่ไม่สามารถวางเดิมพันได้',
  exception: 'ข้อยกเว้น',
  exception1: 'หากเซิฟเวอร์ของผู้จัดล่ม เราขอสิทธิ์ในการยกเลิกทัวร์นาเม้นต์ คืนพอยท์และคืนตั๋ว ให้กับทุกท่านที่เข้าร่วมสำเร็จ',
  exception2: 'หากระบบเชื่อมต่อของผู้แข่งล่ม ขอให้ท่านรีบกลับเข้ามาให้ได้ไวที่สุดเพราะในระหว่างที่ท่านหายไป ระบบจะทำ pass bet และวางเดิมพัน Player ขั้นต่ำรอท่านแต่สูงสุดไม่เกิน 5 มือ หากนานกว่าที่กำหนดท่านจะถูกตัดสิทธิ์',
  exception3: 'หากตรวจพบว่าผู้แข่งโกงการแข่งขันไม่ว่าด้วยรูปแบบใดก็แล้วแต่ ทางผู้จัดขอสิทธิ์ในการติด แบล็คลิสท่าน ซึ่งมีผลต่อการเข้าร่วมทุกโปรโมชั่นที่เราจะจัดในอนาคต',
  q1: 'เราจะสามารถเข้าร่วมการแข่งขันรายไตรมาสได้อย่างไร?',
  faq1: 'วิธีเข้าร่วมในทัวร์นาเมนต์รายไตรมาส: รับตั๋วหนึ่งใบสำหรับทัวร์นาเมนต์รายไตรมาสหรือสะสมคะแนน\n\nวิธีรับตั๋วทัวร์นาเมนต์รายไตรมาส: ผู้ชนะการแข่งขันมาสเตอร์ประจำฤดูกาลจะได้รับตั๋วทัวร์นาเมนต์รายไตรมาสก่อนการแข่งขันทัวร์นาเมนต์เริ่มต้น ตั๋วจะถูกจำกัดเฉพาะการแข่งขันประจำไตรมาสปัจจุบันเท่านั้น',
  q2: 'ล็อบบี้ของมาสเตอร์ทัวร์นาเม้นต์อยู่ที่ไหน?',
  faq2: 'หลังจากเข้าสู่ระบบ คุณจะพบมาสเตอร์ทัวร์นาเม้นท์ที่หน้าล็อบบี้หลัก ทั้งเวอร์ชั่น คอม,มือถือ, และHTML5 คลิกลงทะเบียน เพื่อเข้าสู่การแข่งขัน หากท่านไม่พบมาสเตอร์ทัวร์นาเม้นท์หรือต้องการความช่วยเหลืออื่นๆสามารถติดต่อฝ่ายบริการลูกค้า',
  q3: 'ลงทะเบียนอย่างไร?',
  faq3: 'คลิกปุ่ม ลงทะเบียน ที่หน้าล็อบบี้ทัวร์นาเม้นต์',
  q4: 'ข้อกำหนดในการเข้าร่วมทัวร์นาเม้นต์คืออะไร?',
  faq4: 'ผู้เล่นต้องมีบัญชีเงินจริงกับAG และบัญชีต้องมีคะแนนสะสมหรือตั๋วเพียงพอสำหรับการเข้าร่วมการแข่งขันในทัวร์นาเม้นต์',
  q5: 'เรทในการแลกเปลี่ยนสำหรับคะแนนคืออะไร?',
  faq5: 'เดิมพัน 200 บาท = 1 คะแนน สามารถตรวตสอบคะแนนได้ที่ยูสเซอร์พอยท์\nตัวอย่าง:\nผู้เล่น A เดิมพัน 20,000 บาท จะได้รับ 100 คะแนน\nผู้เล่น B เดิมพัน 17,300 บาท จะได้รับ 86 คะแนน\nคะแนนจะคิดตามจำนวนเต็มไม่นับจุดทศนิยม',
  q6: 'สามารถตรวจสอบยอดคงเหลือได้จากที่ไหน?',
  faq6: 'คลิกปุ่มข้อมูล (i) ข้างรูปโปรไฟล์ของคุณ\nหรือคลิกที่มุมบนขวามือของมาสเตอร์ทัวร์นาเม้นต์ เพื่อตรวจสอบข้อมูลส่วนตัวและยอดเงินคงเหลือ',
  q7: 'สามารถสะสมคะแนนเพิ่มได้อย่างไร?',
  faq7: 'ยูสเซอร์พอยท์สามารถสะสมได้จากการเดิมพันในเกมดังต่อไปนี้ Live Game only, this is included: any type of Baccarat, DragonTiger, SicBo, Roulette, Blackjack, Win Three Cards, BID, MultiPlay, VIP and Good Road betting',
  q8: 'เดิมพันที่ถูกต้องหรือไม่ถูกต้องคืออะไ?',
  faq8: 'บิลที่เข้าเงื่อนไข : แพ้หรือชนะเท่านั้นที่เข้าเงื่อนไข\nบิลที่ไม่เข้าเงื่อนไข : ผู้เล่นวางเดิมพัน 2 ฝั่งในโต๊ะและรอบเดียวกัน\nตัวอย่าง : ผู้เล่นวางเดิมพันบาคาร่าที่ ที่ Player และ Banker ในรอบเดียวกัน',
  q9: 'ความแตกต่างระหว่าง แซทเทิลไลท์ทัวร์นาเม้นต์ และ มาสเตอร์ทัวร์นาเม้นต์?',
  faq9: 'แซทเทิลไลท์ทัวร์นาเม้นต์ : จะไม่กำหนดเวลาในการแข่งขัน เมื่อมีผู้เข้าร่วมเพียงพอการแข่งขันจะเริ่มทันที ผู้ชนะจะได้รับตั๋วเพื่อเข้าไปร่วมใน มาสเตอร์ทัวร์นาเม้นต์\nมาสเตอร์ทัวร์นาเม้นต์ : จะมีกำหนดเวลาเข้าร่วมการแข่งขันอย่างชัดเจน ผู้ลงทะเบียนจะถูกเชิญเมื่อการแข่งขันเริ่มต้นขึ้น',
  q10: 'ต้องจ่ายค่าลงทะเบียนหรือไม่?',
  faq10: 'ไม่ต้องเสียค่าลงทะเบียน เพียงผู้เล่นมีพอยต์หรือตั๋วเพียงพอสำหรับการเข้าร่วมในแต่ละทัวร์นาเม้นต์',
  q11: 'วิธีรับตั๋วสำหรับเข้าร่วมมาสเตอร์ทัวร์นาเม้นต์?',
  faq11: 'ตรวจสอบว่าคุณมีพอยต์เพียงพอสำหรับเข้าร่วมในแซทเทิลไลท์ทัวร์นาเม้นต์หรือไม่ ตั๋วจะถูกปรับให้สำหรับผู้เป็นอันดับหนึ่งในการแข่งขันเพื่อเข้าร่วม มาสเตอร์ทัวร์นาเม้นต์\nหมายเหตุ : ผู้เล่นสามารถสะสมตั๋วสูงสุด 10 ใบ เพื่อเข้าไปแข่งขันใน มาสเตอร์ทัวร์นาเม้นต์ ตั๋วที่ได้ต้องใช้กับทัวร์นาเม้นท์ที่จัดขึ้นภายในเดือนเดียวกันเท่านั้น หากผู้เล่นใช้ไม่หมดจะถือเป็นโมฆะและไม่สามารถแลกเปลี่ยนเป็นเงินหรือพอยต์คืนได้',
  q12: 'สามารถใช้พอยต์แทนตั๋วเพื่อเข้าไปเล่นในมาสเตอร์ทัวร์นาเม้นต์ได้หรือไม่?',
  faq12: 'ใช้ได้ : หากคุณไม่มีตั๋วในการเข้าร่วม แต่มีพอยต์ในบัญชีเพียงพอ ผู้เล่นสามารถคลิกปุ่มเพื่อลงทะเบียนในการแข่งขันหลักระบบจะทำการหักพอยต์โดยอัตโนมัติ\nหมายเหตุ: การลงทะเบียนแซทเทิลไลท์ทัวร์นาเม้นต์จะใช้เพียงพอยต์เท่านั้นไม่สามาถใช้ตั๋วได้',
  q13: 'ฉันสามารถตัดสินใจที่จะเลือกใช้คะแนนหรือตั๋วก่อน?',
  faq13: 'สำหรับการแข่งขันผ่านดาวเทียมจะหักคะแนนผู้ใช้เท่านั้น สำหรับมาสเตอร์ทัวร์นาเมนต์ คุณสามารถเลือกหักคะแนนผู้ใช้หรือตั๋วมาสเตอร์ทัวร์นาเมนต์',
  q14: 'สามารถใช้บัญชีทดลองเล่นเข้าร่วมการแข่งขันหรือไม่?',
  faq14: 'การแข่งขันของ AG ระบุว่าต้องใช้เงินจริงเท่านั้น บัญชีทดลองเล่นใช้สำหรับโหมด \'ข้าชม\' หากต้องการสมัครบัญชีจริงเพื่อการเข้าร่วม โปรดติดต่อเว็บไซด์ฝ่ายบริการลูกค้าของ ตัวแทน AG',
  q15: 'เหตุใดจึงมีการแจ้งเตือนเกี่ยวกับการลงทะเบียนล้มเหลว?',
  faq15: 'การลงทะเบียนล้มเหลวอาจมีสาเหตุดังนี้:\nบัญชีผู้เล่นไม่ใช่บัญชีเงินจริง\nคุณสมบัติไม่ตรงกับเงื่อนไข ผู้เล่นลงทะเบียนมาก่อนหน้านี้แต่ถูกให้ออกจากการแข่งขัน ดังนั้นจึงต้องพักการเข้าร่วม 3 นาที',
  q16: 'หลังจากลงทะเบียนสามารถยกเลิกได้หรือไม่?',
  faq16: 'หลังจากลงทะเบียน(กรณีที่ผู้เล่นยังไม่ครบ) และการแข่งขันยังไม่เริ่ม:\nอนุญาตให้ผู้เข้าร่วมยกเลิกได้ หากภายใน 3 นาทีหลังยกเลิกผู้เล่นอยากลงทะเบียนเข้ามาใหม่จะยังไม่สามารถทำได้ ต้องให้ผ่านเวลาดังกล่าวไปเสียก่อน\nหลังจากลงทะเบียน (กรณีที่ผู้เข้าแข่งขันครบ) แต่การแข่งขันยังไม่เริ่ม :\nหากผู้เข้าร่วมต้องการยกเลิก ระบบะถือว่าท่านสละสิทธิ์และไม่คืนพอยต์และตั๋ว\nผู้เข้าร่วมเริ่มเล่นไปแล้วและไม่อยากเล่นให้จบ :\nตัดสินใจออกจากการแข่งขัน ระบบจะตัดสินอันดับของท่านจากจำนวนเกม/รอบและจำนวนเครดิตที่ท่านเล่นไปแล้ว\nโปรดทราบ:\n1. การลงทะเบียนการแข่งขันผู้เล่นคนเดียวกันหากเลิกเล่นจะไม่สามารถกลับเข้ามาในเกมเดียวกันได้อีก\n2. ผู้เล่นมีสิทธิ์ยกเลิกได้เพียง 5 ครั้งต่อวันเท่านั้น',
  q17: 'หลังจากลงทะเบียนเสร็จต้องอยู่ที่หน้าล็อบบี้เพื่อรอการแข่งขันเริ่มหรือไม่?',
  faq17: 'ไม่จำเป็นต้องรอที่หน้าล็อบบี้ผู้เล่นสามารถเข้าเดิมพันที่เกมอื่นได้ตามปกติ เมื่อใกล้เวลาเริ่มการแข่งขันระบบจะส่งข้อความแจ้งเตือนให้ผู้เล่นทราบ',
  q18: 'การแข่งขันแต่ละรอบมีระยะเวลาเท่าใด?',
  faq18: 'ประมาณ 5 ถึง 20 นาที',
  q19: 'การแข่งขันใช้เงินจริงเล่นหรือไม่?',
  faq19: 'ผู้เข้าร่วมจะใช้ชิปในการเดิมพันเท่านั้น ชิปการแข่งขันไม่มีค่าเป็นเงิน และใช้เพื่อแข่งในทัวร์นาเม้นต์เท่านั้น',
  q20: 'ชิปการแข่งขันคืออะไร?',
  faq20: 'AG Master Tournament เพื่อความโปร่งใสผู้เข้าร่วมแข่งขันทั้งหมดมีจำนวนชิปเริ่มต้นจำนวนเท่ากัน ซึ่งจะใช้ชิปเข้ามาเป็นตัวจัดอันดับ ไม่เกี่ยวข้องกับยอดเงินบัญชีจริงของผู้เข้าร่วม ใช้ภายในทัวร์นาเมนต์เท่านั้น เมื่อจบการแข่งแต่ละรอบ ชิป ในบัญชีผู้เล่นจะถูกปรับให้เป็น 0 เสมอ',
  q21: 'ผู้เข้าชมสามารถเดิมพันในการแข่งขันได้หรือไม่?',
  faq21: 'เฉพาะผู้เล่นที่ลงทะเบียนสำเร็จเท่านั้น หากผู้เข้าชมต้องการเข้าร่วม สามารถ คลิกที่ปุ่ม \'ลงทะเบียน\' ที่หน้าล็อบบี้เพื่อเข้าร่วมการแข่งขัน',
  q22: 'สัญลักษณ์ \'online\' คืออะไร?',
  faq22: 'จำนวนผู้ลงทะเบียนในการแข่งขัน',
  q23: 'วงเงินในการวางเดิมพันเท่าไหร่?',
  betLimit: 'วงเงินเดิมพัน',
  bankerPair: 'Banker Pair',
  playerPair: 'Player Pair',
  q24: 'ทำไมไม่สามารถวางเดิมพัน “Pairs” และ “Tie” ได้?',
  faq24: 'ผู้เข้าแข่งขันทุกคนจะต้องเดิมพันหลักได้แก่ Player หรือ Banker จากนั้นจึงจะสามารถวางเดิมพัน Tie / Player Pair / Banker Pair พ่วงได้',
  q25: 'Blind Bet  คืออะไร?',
  faq25: 'ปิด/ซ่อน เงินเดิมพันเพื่อไม่ให้เพื่อนร่วมโต๊ะทราบ แต่ตัวเลขเงินเดิมพันของท่านแสดงผลสั้น ๆ ไม่กี่วินาทีก่อนการเปิดไพ่ ทั้งนี้จะได้รับกี่ครั้งขึ้นอยู่กับจำนวนรอบที่จะเกิดขึ้นในการแข่งขัน',
  q26: 'Pass Bet คืออะไร?',
  faq26: 'ข้ามการเดิมพันในการเปิดไพ่รอบนี้ สามารถคลิกปุ่ม \'Pass Bet\' จำนวนครั้งของการได้สิทธิ์นี้ใช้วิธีคิดเดียวกับฟังก์ชัน Blind Bet.',
  q27: 'pass และ blind นับสะสมไปในรอบต่อไปหรือไม่?',
  faq27: 'เมื่อจบการแข่งแต่ละรอบ ฟังก์ชั่นพิเศษนี้ ถูกปรับให้เป็น 0 เสมอ',
  q28: 'การคัดเลือกผู้แข่งขันที่ได้ไปต่อ หรือ ตกรอบในมาสเตอร์ทัวร์นาเม้นต์ ทำอย่างไร?',
  faq28: 'เพื่อให้เกิดความยุติธรรมและโปร่งใสระหว่างผู้เล่น, ตัวอย่าง\nผู้แข่งจำนวน 56 คน แยกนั่ง 8 โต๊ะ ๆ ละ 7 (A、B、C、D、E、F、G 、H ) จากนั้นคัด 3 อันดับแรกของแต่ละโต๊ะแข่งรอบสอง ผู้แข่งจำนวน 24 คน แยกนั่ง 4 โต๊ะ ๆ 6 จากนั้นคัด 3 อันดับแรกเล่นรอบสาม ผู้แข่งจำนวน 12 คน แยกนั่ง 2 โต๊ะ ๆ 6 จากนั้นคัด 3 อันดับแรกเล่นรอบสี่ ผู้แข่งจำนวน 6 คน นั่งโต๊ะรอบสุดท้ายเพื่อประกาศผลผู้ชนะตามอันดับในรอบนี้',
  q29: 'วิธีการจัดอันดับของผู้เล่นทำอย่างไร?',
  faq29: 'กฎการจัดลำดับจะใช้ลำดับความสำคัญต่อไปนี้:\nชิปมากสุดอยู่สูงสุด;\nหากชิปเท่ากัน จะเลือกนับเฉพาะจำนวนชิปจริงเท่านั้น ชิปที่ได้พิเศษในการเข้ารอบเป็นคนแรกจะถูกหักออกไป\nหากยังเท่ากัน ผู้เล่นที่ลงทะเบียนเข้าแข่งก่อนจะได้รับสิทธิ์เป็นผู้มีอันดับสูงกว่า',
  q30: 'รับรางวัลและเงินรางวัลได้อย่างไร?',
  faq30: 'โปรดคลิกที่ปุ่ม \'รายละเอียด\' เพื่อดูข้อมูลเพิ่มเติม',
  q31: 'จะรับรางวัลในการแข่งขันได้อย่างไร?',
  faq31: 'เงินรางวัลที่ได้จากการแข่งขันจะถูกปรับเข้าสู่บัญชีผู้เล่นทั้งหมด เมื่อการแข่งขันสิ้นสุด',
  q32: 'ในรอบที่สองของมาสเตอร์ทัวร์นาเม้นต์ ทำไมผู้เล่นคนอื่นมีชิปการแข่งขันสูงกว่าของฉัน?',
  faq32: 'ผู้แข่งที่ผ่านเข้ารอบคนแรกจะได้รับชิปพิเศษตามเปอร์เซนต์ที่กำหนดเมื่อเริ่มแข่งในรอบต่อไป',
  q33: 'หากการแข่งขันถูกยกเลิกจะคืนยูสเซอร์พ้อยต์ หรือ ตั๋ว หรือไม่?',
  faq33: 'คืนยูสเซอร์พ้อยต์และตั๋วที่ได้หักไป',
  q34: 'สามารถเรียกดูประวัติการเดิมพันได้ที่ไหน?',
  faq34: 'ผู้เล่นสามารถเลือกที่ “custom service” > “account record” >และเลือก“match betting record”.',
  q35: 'Top Master คือออะไร?',
  faq35: 'Top Master แสดงชื่อผู้ได้รับรางวัลสูงสุดในรอบนั้น ๆ สามารถ คลิกชื่อของผู้เล่นเพื่อดูรายละเอียดเพิ่มเติม',
  q36: 'ฉันควรทำอย่างไรหากสัญญาณอินเตอร์เน็ตไม่สามารถเข้าใช้งานได้ในขณะแข่งขัน?',
  faq36: 'หากระบบเชื่อมต่อของผู้แข่งล่ม ขอให้ท่านรีบกลับเข้ามาให้ได้ไวที่สุดเพราะในระหว่างที่ท่านหายไป ระบบจะทำ pass bet และวางเดิมพัน Player ขั้นต่ำรอท่านแต่สูงสุดไม่เกิน 5 มือ หากนานกว่าที่กำหนดท่านจะถูกตัดสิทธิ์',
  topMasterCongrats: 'ขอแสดงความยินดีกับผู้ชนะของ AG Master Tournament',
  tournamentSetting: 'การตั้งค่า',
  others: 'อื่น',
  regQ3Faq: 'ในระหว่างการแข่งขัน บัญชีเงินจริงของ AG ทั้งหมดไม่ต้องจ่ายค่าธรรมเนียมการลงทะเบียนและการจัดการใดๆ เมื่อผู้เล่นต้องการลงทะเบียนสำหรับ Master Tournament จำเป็นต้องมีคะแนนหรือตั๋ว Master Tournament เพียงพอ'
};

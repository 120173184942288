export const cnSimplified = {
  gog: '赌神赛',
  stayTuned: '敬请期待',
  introduction: '比赛简介',
  award: '奖金',
  details: '比赛详情',
  faq: '常见问题',
  new: '新',
  topMaster: '赌神榜',
  backToTop: '返回页顶',
  introductionDetails: 'AG赌神赛基于最受欢迎的百家乐游戏，\n迎合当前网络最火爆的坐满即玩比赛方式，\n以公平透明的比赛规则为玩家呈现最具娱乐性的线上百家乐比赛。',
  introductionDetailsMobile: 'AG赌神赛基于最受欢迎的\n百家乐游戏，迎合当前网络\n最火爆的坐满即玩比赛方式，\n以公平透明的比赛规则为玩家呈现\n最具娱乐性的线上百家乐比赛。',
  howToEnter: '如何参加',
  gameProcess: '比赛流程',
  step: '步骤',
  step1: '用户账号必须为\nAG平台的真钱账号',
  step2: '进入AG平台\n在 “赌神赛” 厅中选择\n对应的比赛场报名即可',
  howToJoin1: '用户账号必须为AG平台的真钱账号',
  howToJoin2: '进入AG平台在“赌神赛”厅中选择对应的比赛场报名即可',
  satelliteTournament: '采用人满开赛的方式，\n资格赛获胜玩家获得赌神赛门票，\n于赌神赛赢取更丰富奖金。',
  masterTournament: '采用定时开赛的方式，让更多玩家都能参与到比赛中来，' +
    '同时赌神赛的巨额奖金使比赛竞争更激烈，比赛气氛更紧张刺激。',
  seasonTournament: '采用定时开赛方式，邀请当季赌神参赛，与通过足额积分报名的玩家进行高手同场竞技，' +
    '冠军将获得超高额奖金！',
  awardDetails: '所有用户在所参与的比赛场中所获得的奖金会即时到账用户所在的AG游戏户口，无需等待，无需审核。',
  qualifyingRound: '资格赛',
  qualRoundText: '采用人满开赛的方式，资格赛\n获胜玩家获得赌神赛门票，\n于赌神赛赢取更丰富奖金。',
  gogTickets: '赌神赛门票 /\n足额积分',
  gogMasterTournament: '赌神赛',
  gogStep3: '采用定时开赛的方式，让更多\n玩家都能参与到比赛中来。\n同时赌神赛的巨额奖金使比赛\n竞争更激烈，气氛更紧张刺激。',
  tournamentRules: '比赛规则',
  fairness: '公平性',
  regAndWithdrawal: '报名及退赛',
  inGame: '玩法规则',
  ranking: '排名规则',
  withdraw3: '报名后退赛（未满足所需人员时）且比赛尚未开始',
  withdraw3Child: '玩家可以立即退出，3分钟内玩家不能再次报名同类型赛事，系统将退还扣除的积分/门票。',
  withdraw4: '报名后退赛（达到报名人数）且比赛尚未开始：',
  withdraw4Child: '参赛者必须进入比赛方可退出，该参赛者将被系统视为淘汰。所有扣除的用户积分或门票将不予退还。',
  withdraw5: '参赛者决定在比赛期间退赛',
  withdraw5Child: '比赛期间，若任何参赛者选择另一场比赛或退出比赛，则根据有效投注数和参赛轮数来决定排名和获胜机会。',
  withdraw6: '玩家退出该场比赛后将无法重新参与同一场比赛。',
  withdraw7: '每位玩家在同一天内只能退出已报名的比赛最多5次，如果超过5次则不允许玩家报名同一类型的比赛。',
  tournamentRules1: '玩家报名参加比赛后，在比赛中将以固定的比赛筹码在特定的比赛桌枱中进行投注，该比赛筹码与玩家个人真钱额度及用户积分并无任何关联，所有比赛筹码将不会累积带到下一个比赛场中。若玩家的用户积分不足，可通过非比赛的游戏投注，来提高有效投注额换积分；',
  tournamentRules2: '为保证系统公平透明性，玩家报名成功后，进入比赛场时的座位均采用系统随机分配，玩家不得选择或交换桌台上的座位；',
  tournamentRules3: '所有比赛奖金将依照各比赛场奖金分配表发放，实时到账；',
  tournamentRules4: '比赛将根据“免佣百家乐”的规则下进行，有关“百家乐”游戏规则请于游戏内按菜单>游戏规则查看玩法。参赛者必须于每局下注时间内下注，每局的下注时限为25秒，每位参赛者必须于每局至少以最低投注额下注于庄家或闲家。于每一手牌中，参赛者只可选择投注于庄家或闲家任何一方，当选择投注于和局或对子时，亦必须同时选择投注庄家或闲家其中一方；',
  tournamentRules5Part1: '于资格赛及赌神赛开始前，每位参赛者将获得比赛筹码10万（比赛筹码只适用于比赛，不能兑换现金或现金筹码），玩家在比赛中的投注限红为1000 - 10,000，参赛者不可投注多于其拥有之比赛筹码。',
  tournamentRules5Part2: '在比赛进行时，参赛者于每局的投注金额限制如下：',
  betItem: '投注項目',
  minBet: '最低投注筹码',
  maxBet: '最高投注筹码',
  banker: '庄家',
  player: '闲家',
  tie: '和局',
  pair: '对子',
  tournamentRules6: '所有比赛场，荷官会先派出2局不设投注的牌局后开始正式发牌，后面的比赛在同一靴牌中将不再重复洗牌；',
  tournamentRules7: '为保证玩家间的公平透明，比赛系统将使用以下晋级/淘汰机制：',
  tournamentRules7_1: '比赛过程中,系统根据每个比赛场次设定的规则每轮按虚拟桌晋级指定数目的玩家，每轮未获晋级的參賽者将自动淘汰。',
  tournamentRules7_2: '例如：某场比赛共56人参赛，玩家分布于“A、B、C、D、E、F、G 、H”8个虚拟桌上，比赛共分4轮（每轮5局）进行，每轮比赛每桌晋级前3名, 即A-H每桌的前3名晋级下一轮（未晋级的自动淘汰），直到比赛完成。',
  tournamentRules7_3: '玩家比赛中晋级的额度优势: 如比赛场设定每桌每轮晋级2 人以上时，当玩家在上一轮以第一名晋级下一轮时，每桌第一名的玩家的在下一轮的初始比赛筹码额度会有固定百分比的增加（即每桌的第一名会比其他玩家多固定百分比的比赛筹码额度，该百分比在游戏中设定）',
  tournamentRules7_4: '系统采用尽量保证每桌玩家数目平均的方式，每轮晋级的玩家将会再次随机平均分配到不同的桌上进行游戏；',
  tournamentRules8: '于比赛过程中，可使用的特别投注功能条款如下：',
  tournamentRules8_1: '“暗注”供参赛者于比赛中使用，是属于策略投注技巧。主要是隐藏自己投注的项目，直到投注时间完毕时，其他玩家才能看到自己所投的筹码及位置。暗注次数则按该场比赛的“轮数”提供相对的暗注次数。例如：总轮数：5 , 总暗注次数：5',
  tournamentRules8_2: '“过牌”供参赛者做决定时使用，意思就是不下注。过牌次数则按该场比赛的”轮数”提供相对的过牌次数。例如：总轮数：5 , 总过牌次数：5',
  tournamentRules9: '不论赢或输，所有已使用或未使用的特别投注功能于比赛结束时将自动清零，参赛者之间不能转让或借用比赛筹码，在每场比赛结束后，系统会将玩家的比赛筹码自动清零。',
  tournamentRules10: '排名规则将使用以下优先级：',
  tournamentRules10_1: '晋级参赛者排名高于淘汰玩家,然后按该场比赛筹码从高至低来排名；',
  tournamentRules10_2: '若玩家比赛筹码相同,则该场胜率较高者排名较高；',
  tournamentRules10_3: '若以上2个排名规则情况下多个参赛者存在相同排名,则以该场报名时间较早的玩家排名较高。',
  tournamentRules11: '如参赛者在比赛中因任何理由需离开比赛，离桌的时间不可超过5局游戏，若违反此规定，参赛者获奖的资格将被取消而只可获排名。',
  tc: '活动条款',
  tc1: '所有有效AG真钱会员均可参加。',
  tc2: '活动期间，玩家参与“资格赛”，需使用玩家现有用户积分报名参与赛事；',
  tc3: '所有比赛均依照比赛大厅所公告之时间准时开始报名，我们有权在不事先通知玩家的情况下，更改、延迟或取消比赛。',
  tc4: '用户积分赚取只限于AG平台中进行有效投注，详细说明以及积分兑换规则请参考“用户积分系统” 说明。',
  tc5: '“资格赛”胜出者即可获取参与“赌神赛”门票一张 (若门票已派完则当日不再发放门票，所有比赛也不再接受报名，先到先得，派完即止)，每位玩家获得的门票于本月比赛活动期间有效;',
  tc6: '为保证活动回馈广大会员，本活动每位会员限同一帐户及同一IP地址参加。违规者取消领奖资格，严重舞弊者AG有权在不事先通知情况下作封号处理或扣除相关获利；',
  tc7: '任何会员以任何非法手段，包括但不限于对冲、合谋作弊等参加本活动，违规处理等同活动条款6；',
  tc8: '若违反比赛之规定，参赛者将会失去其参赛的资格;',
  tc9: '玩家参加本次AG活动，即视为同意本活动条款；',
  tc10: 'AG保留对本次活动的最终解释权。',
  remark: '注意事项',
  registration: '报名',
  registration1: '如果玩家同时拥有赌神赛门票和足够积分的情况报名赌神赛，玩家可选择使用积分或赌神赛门票参赛。',
  registration2: '任何比赛开始前的20分钟内同一用户同时只能报名一场比赛（资格赛或赌神赛）',
  registration2_1: '为确保玩家能优先进行赌神赛，当玩家已经报名赌神赛且距离下场赌神赛开始只有20分钟的情况下，同一个玩家不能报名另一场资格赛；',
  registration2_2: '若玩家已报名赌神赛且离该赌神赛开始多于20分钟，则玩家仍可以报名资格赛，但当该场赌神赛开始只有20分钟且玩家报名的资格赛仍未开始则系统会自动取消玩家所报名的资格赛并退回积分。',
  cancelReg: '退赛',
  cancelReg1: '每一位玩家只可以在比赛开始前退赛，退赛后3分钟内将不可以重新报名该相同场次的比赛，系统会退回所扣积分或门票。',
  cancelReg2: '比赛开始后,玩家强行退赛将按该玩家已下注局数及轮数来判断排名及获奖机会。',
  watch: '旁观',
  watch1: '所有用户可以选择旁观正在进行中的比赛，不能投注比赛，但可以进行聊天；',
  exception: '异常处理',
  exception1: '如遇系统故障原因而影响比赛顺利进行,我们有权随时取消比赛，并且将该场比赛取消后实时退还玩家在该场比赛已扣除的用户积分或赌神赛门票；',
  exception2: '比赛开始后，因玩家自身网络原因造成的断线，玩家应在5局时间内重新登录并即时返回该比赛场游戏，自动下注系统将在每一局以最小限红于“闲”下注，当5局时间内玩家仍未进入比赛场将自动因玩家没有任何手动下注而被淘汰，玩家只会获取排名而不会获取任何奖金机会；',
  exception3: '任何参与比赛的玩家如因连续使用对冲下注等方法进行比赛投注，我们有权取消玩家比赛资格及奖金，并将对该玩家作封号处理，永久不得参加比赛。',
  q1: '如何参加季度赛？',
  faq1: '如何参加季度赛：季度赛门票一张，或积分报名。\n\n如何获得季度赛门票：当季度的任何一场赌神赛冠军可获得季度赛门票一张，门票在季度赛开始前派发。季度赛门票仅限当期季度赛使用，过期无效。',
  q2: '比赛大厅在哪？',
  faq2: '成功登录AG的PC版后，在左侧导航栏按下赌神赛，便可进入比赛大厅。如未显示，请与您的运营商或客服联系。手机网页版的比赛大厅在 AG游戏大厅中有一个独立的入口按钮。',
  q3: '如何报名比赛？',
  faq3: '进入比赛大厅后，选择您想要参加的比赛场次，按报名键即可；手机版本请点击AG赌神赛大厅的“报名”键进入。',
  q4: '参赛资格是什么？',
  faq4: '您必须拥有一个个人的AG真钱帐号，个人帐户必须有足够积分或门票来参与资格赛或赌神赛。',
  q5: '如何兑换积分？',
  faq5: '100人民币有效投注额 = 1积分或到用户积分专页查看更多详情\n如：\nA玩家有效投注额为10000，则A用戶积分为100积分；\nB玩家有效投注额为8650,则B玩家的积分为 86积分；\n积分将不会产生小数位，只取整数。',
  q6: '在哪查询我有多少积分？',
  faq6: '您可以按下大厅个人头像旁的 (i)，即可显示个人信息，其中包括用户积分：\n或于比赛大厅左上角的用户积分位置查看：',
  q7: '怎样赚取更多积分？',
  faq7: '用戶积分是通过玩家在AG平台的有效投注额兑换获得，积分不限时间、不清零，只有在AG视讯游戏投注的有效投注额才可兑换，视讯游戏包括：各类型百家乐、龙虎、骰宝、轮盘、21点、牛牛、炸金花、竞咪、多台、包桌及好路下注。\n\n请注意： 小费、送礼物给主播、非视讯类游戏中的有效投注等，一律不包括在赚取积分内',
  q8: '怎样才算有效投注？',
  faq8: '有效投注: 指所有产生输赢的投注。\n无效投注：指玩家在同一局游戏同时投注输赢两种结果。\n例：在百家乐游戏同一局内，同时下注庄、闲的对冲投注。',
  q9: '资格赛和赌神赛有什么区别？',
  faq9: '资格赛: 采用人满开赛的方式，资格赛获胜玩家将获得赌神赛门票，於赌神赛赢取更丰富奖金。\n赌神赛：采用定时开赛的方式，让更多玩家都能参与到比赛中来，同时赌神赛的巨额奖金使比赛竞争更激烈，比赛气氛更紧张刺激。',
  q10: '报名费是多少？',
  faq10: '活动期间玩家无需任何报名及手续费用，只需使用用戶积分或赌神赛门票进行报名。',
  q11: '赌神赛门票如何获得？',
  faq11: '在同一天内，只要您有足够的用户积分, 便可无限次报名资格赛。资格赛获胜玩家将获得赌神赛门票，凭此门票可参加于活动期间举办的任何赌神赛场次。\n注意：每一玩家拥有的赌神赛门票数量上限为10张，获得的门票可于活动期间用于报名的赌神赛，报名一场赌神赛即立刻扣除玩家一张门票。赌神赛门票只于赌神赛本月比赛活动期间有效，活动结束后即失效，AG不作任何退款及退积分。',
  q12: '能否直接使用用户积分兑换赌神赛门票参赛？',
  faq12: '可以，如果您没有赌神赛门票但拥有很多的用户积分情况下，您可直接在赌神赛按报名键，系统会直接扣除所需积分。\n请注意：资格赛只可使用用户积分进行报名',
  q13: '可以选择扣除门票或积分吗？',
  faq13: '资格赛报名时，只扣除玩家的用户积分；赌神赛报名时，则可选择使用积分或赌神赛门票参赛。',
  q14: '试玩帐号能否报名？',
  faq14: '活动期间所有比赛场只开放给AG真钱账号报名，试玩账号的玩家只限进入任何比赛进行旁观。',
  q15: '为什么提示报名失败？',
  faq15: '报名失败有以下几种原因：\n您的账户不是真钱帐户或没有符合参赛要求；\n您已报名并选择退赛，于3分钟内您将不允许再次报名同一比赛场次。',
  q16: '报名后能否退赛？',
  faq16: '报名后(人数未满时)及比赛未开始：\n允许玩家即时退赛，但3分钟内不能再次报名同类型的比赛，系统会退回所扣积分或门票。\n报名后(人数已达到要求)及比赛未开始：\n允许玩家进入比赛后才能退赛，系统将判断玩家为自动弃权，因玩家主动退赛，系统不会退回因报名已扣除的积分或门票。\n比赛中玩家主动退赛：\n玩家在比赛中主动点”返回”来退赛，系统将判断玩家已下注的局数及轮数来判断玩家的排名及获奖机会，另外因玩家主动退赛，系统不会退回因报名已扣除的积分或门票。\n请注意：\n1. 同一玩家报名一场比赛后选择退赛，将不可重新报名同一场次的比赛；\n2. 每名玩家同一天内最多只可退赛5次，若超过5次则不允许报名同类型比赛。',
  q17: '报名后，是否须在比赛大厅等待开赛？',
  faq17: '无须在比赛大厅等待开赛，玩家可以先进入其他游戏下注，直到人满比赛开始前或是临近开赛时间时，系统将会发出提示信息通知玩家准备进入比赛。',
  q18: '每场比赛需时多久？',
  faq18: '每一场比赛大概5至20分钟。',
  q19: '比赛是否使用个人真钱额度投注？',
  faq19: '在比赛场下注时，所有参赛者都只能使用系统提供的比赛筹码投注，与玩家本身的真钱额度没有直接关系。',
  q20: '比赛筹码是什么？',
  faq20: 'AG赌神赛秉承公平、公正、透明的原则，比赛场中所有玩家进入比赛时都拥有相同的初始筹码，即称为比赛筹码。比赛筹码为AG赌神赛系统中专用的额度，与玩家本身的真钱额度没有直接关系，比赛筹码的多少将作为比赛排名的决定因素。比赛筹码不能转入及转出，只可用于当场比赛，每场比赛结束后，参赛者的比赛筹码即被清零。',
  q21: '旁观可以投注吗？',
  faq21: '只有报名参赛的玩家才可于比赛场内进行投注，旁观的玩家不可进行投注，只可进行旁观。如须参赛，可在比赛大厅按“报名参赛”键，选择比赛场进行报名。',
  q22: '比赛中的在线人数是什么？',
  faq22: '代表已报名该场比赛的总人数。',
  q23: '各投注区的限红是多少？',
  betLimit: '限红',
  bankerPair: '庄对子',
  playerPair: '闲对子',
  q24: '为何无法投注“对子”及“和”？',
  faq24: '参赛者必须投注庄或闲家其中一方，才可投注“对子”及“和”。',
  q25: '什么是“暗注”？',
  faq25: '玩家先按下“暗注”键，再进行投注，将会隐藏玩家投注的项目和金额，直到开牌时，其他玩家才能看到玩家所投的筹码，属于一种策略投注技巧。',
  q26: '什么是“过牌”？',
  faq26: '玩家按下“过牌”后，该局可不进行任何投注，系统也不会自动扣除最小限红的投注额。',
  q27: '过牌及暗注次数是否能累计？',
  faq27: '所有已使用或未使用的过牌及暗注次数只于该场比赛内有效，该场比赛结束后即失效，无法累计到下一场使用。',
  q28: '于赌神赛中，如何决定晋级或淘汰？',
  faq28: '为保证比赛的公平透明，赌神赛系统将使用以下晋级及淘汰机制：\n比赛过程中，每轮比赛每张虚拟桌晋级固定数目的玩家，每轮未获晋级的玩家将自动淘汰。例如：某场比赛共56人参赛，玩家分布于“A、B、C、D、E、F、G 、H”八个虚拟桌上，比赛共分4轮（每轮5局）进行，每轮比赛每桌晋级前3名, 即A-H每桌的前3名晋级下一轮(未晋级的自动淘汰),直到比赛完成。',
  q29: '如何决定玩家排名？',
  faq29: '排名规则将使用以下优先顺序：\n晋级玩家排名高于淘汰玩家，然后按该场比赛筹码从高至低来排名；\n若玩家比赛筹码相同,则该场胜率较高者排名较高；\n若以上2个排名规则情况下多个玩家存在相同排名,则以该场报名时间较早的玩家排名较高。',
  q30: '奖品或奖金如何分配？',
  faq30: '请于该比赛场按“查看详情”键查询有关资讯。',
  q31: '怎样领取在比赛中获得的奖金？',
  faq31: '在比赛中所有获得的奖金经审核后将即时存入玩家帐户内。',
  q32: '为什么赌神赛中下一轮开始时其他人的比赛筹码比我高？',
  faq32: '所有参赛者于赌神赛晋级后，直到该场比赛完结。为了保持玩家与玩家的良性竞争，每桌第一名的玩家在下一轮的初始比赛筹码额度会有固定百分比的增加。',
  q33: '如比赛取消已扣除的积分或赌神赛门票是否会退回？',
  faq33: '如比赛取消，所有已扣除的积分或门票都会即时退回到您的真钱帐号内，无需扣除任何手续费。',
  q34: '比赛投注纪录在哪查询？',
  faq34: '参赛者可在比赛大厅按“客户服务” > “帐户纪录” > 于左侧选择“比赛下注纪录”内查询。',
  q35: '赌神榜是什么?',
  faq35: '赌神榜是纪录每一场赌神赛获得第1名的赌神名称及获得的奖金金额，按下排名中玩家的名字可查看该玩家于赌神赛中获得奖金的金额及场次详情。',
  q36: '赌神赛进行过程中，我的网络掉线了怎么办？',
  faq36: '若玩家因网路掉线，系统将自动在5局内以最少注于“闲”下注，5局内未返回比赛场则当作淘汰处理。系统会根据玩家曾经下注的局数及轮数来判断玩家排名位置及获奖机会。',
  topMasterCongrats: '恭喜以下玩家于赌神赛夺奖',
  tournamentSetting: '赛事设置',
  others: '其他',
  regQ3Faq: '比赛期间，所有AG真钱账户无需支付任何注册费和手续费。当玩家想要报名参加赌神赛时，其账户中需要有足够的积分或赌神赛门票。'
};

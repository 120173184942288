import React, { useState } from 'react';

import { type IDetails } from '../interfaces';

import { TournamentRules } from './TournamentRules';

import '../styles/DetailsSection.scss';

export const Details = ({ t }: IDetails): JSX.Element => {
  const [isTournamentRules, setIsTournamentRules] = useState<boolean>(true);
  return (
    <section className="details-container" id="details">
      <h2>{t('details')}</h2>
      <div className="details-content">
        <div className="details-section-nav-container">
          <button className={!isTournamentRules ? 'disabled-btn' : 'btn-highlight'} onClick={() => { setIsTournamentRules(true); }}>
            <strong>{t('tournamentRules')}</strong>
          </button>
          <button className={isTournamentRules ? 'disabled-btn' : 'btn-highlight' } onClick={() => { setIsTournamentRules(false); }}>
            <strong>{t('tc')}</strong>
          </button>
        </div>
        {isTournamentRules && <TournamentRules t={t}/>}
        {!isTournamentRules && (
          <div className={'terms-conditions-container back-shadow'}>
            <ol>
              <li>{t('tc1')}</li>
              <li>{t('tc2')}</li>
              <li>{t('tc3')}</li>
              <li>{t('tc4')}</li>
              <li>{t('tc5')}</li>
              <li>{t('tc6')}</li>
              <li>{t('tc7')}</li>
              <li>{t('tc8')}</li>
              <li>{t('tc9')}</li>
              <li>{t('tc10')}</li>
            </ol>
          </div>
        )}
      </div>
    </section>
  );
};

import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import tournamentLobby1 from '../assets/faq/web_mt_txt_faq_reg_01_en_updated.webp';
import cnSimpTournamentLobby1 from '../assets/faq/web_mt_txt_faq_reg_01_hans_updated.webp';
import cnTradTournamentLobby1 from '../assets/faq/web_mt_txt_faq_reg_01_hant_updated.webp';
import tournamentLobby2 from '../assets/faq/web_mt_txt_faq_reg_01b_en_updated.webp';
import cnSimpTournamentLobby2 from '../assets/faq/web_mt_txt_faq_reg_01b_hans_new.webp';
import cnTradTournamentLobby2 from '../assets/faq/web_mt_txt_faq_reg_01b_hant_updated.webp';
import enhowToRegister1 from '../assets/faq/web_mt_txt_faq_reg_02a_en_revised.webp';
import cnSimpHowToRegister1 from '../assets/faq/web_mt_txt_faq_reg_02a_hans_revised.webp';
import cnTradHowToRegister1 from '../assets/faq/web_mt_txt_faq_reg_02a_hant_revised.webp';
import enhowToRegister2 from '../assets/faq/web_mt_txt_faq_reg_02b_en_revised.webp';
import cnSimpHowToRegister2 from '../assets/faq/web_mt_txt_faq_reg_02b_hans_revised.webp';
import cnTradHowToRegister2 from '../assets/faq/web_mt_txt_faq_reg_02b_hant_revised.webp';
import enRegister1 from '../assets/faq/web_mt_txt_faq_reg_03a_en_revised.webp';
import cnSimpRegister1 from '../assets/faq/web_mt_txt_faq_reg_03a_hans_revised.webp';
import cnTradRegister1 from '../assets/faq/web_mt_txt_faq_reg_03a_hant_revised.webp';
import enRegister2 from '../assets/faq/web_mt_txt_faq_reg_03b_en_revised.webp';
import cnSimpRegister2 from '../assets/faq/web_mt_txt_faq_reg_03b_hans_revised.webp';
import cnTradRegister2 from '../assets/faq/web_mt_txt_faq_reg_03b_hant_revised.webp';
// scss file is from parent component Faq.scss
import { type IRegistration } from '../interfaces';
import { languageCodes } from '../languages/languages';

import 'react-lazy-load-image-component/src/effects/blur.css';

export const Registration =
  ({ t, isChinese, i18n }: IRegistration): JSX.Element => {
    const animationDuration = 300;
    const initialQuestionsObj = {
      q1: false,
      q2: false,
      q3: false,
      q4: false,
      q5: false,
      q6: false,
      q7: false,
      q8: false,
      q9: false
    };
    const [questionsObj, setQuestionsObj] =
    useState<Record<string, boolean>>(initialQuestionsObj);

    const handleQuestionsObj = (question: string): void => {
      if (!questionsObj[question]) {
        setQuestionsObj({ ...initialQuestionsObj, [question]: true });
        const element: HTMLElement | null = document.getElementById(question);

        /*
       a setTimeout() is necessary because when a question tab expands/collapses
       it takes time and we want that to complete before scrolling into view.
       */

        if (element != null) {
          setTimeout(() => {
            element.scrollIntoView(true);
          }, animationDuration);
        }
      }

      if (questionsObj[question]) {
        setQuestionsObj({ ...initialQuestionsObj });
      }
    };

    const iconStyle = { color: 'white', height: '48px', width: '48px' };
    const defaultImgQ1Props = { width: 'auto', height: '413px' };
    const defaultImgProps = { width: '350px', height: 'auto' };

    const isCNTrad: boolean =
      i18n.resolvedLanguage === languageCodes.chineseTraditional;
    const isCNSimp: boolean =
      i18n.resolvedLanguage === languageCodes.chineseSimplified;

    return (
      <div className="registration-container">

        <div className={`registrationQ1-container ${questionsObj.q1 ? 'back-shadow' : ''}`}>
          <button id="q1" onClick={() => { handleQuestionsObj('q1'); }}>
            <div className={`label-container ${questionsObj.q1 ? 'no-border' : ''}`}>
              <strong>{`1. ${t('q2')}`}</strong>
              {questionsObj.q1
                ? <KeyboardArrowDownIcon sx={iconStyle} />
                : <KeyboardArrowUpIcon sx={iconStyle} />}
            </div>
          </button>
          <AnimateHeight duration={animationDuration} height={ questionsObj.q1 ? 'auto' : 0}>
            <section>
              <p>{t('faq2')}</p>
              <div className="images-container-row">
                {isCNSimp &&
                  <>
                    <LazyLoadImage src={cnSimpTournamentLobby1} alt="tournament link 1" {...defaultImgQ1Props} effect="blur"/>
                    <LazyLoadImage src={cnSimpTournamentLobby2} alt="tournament link 2" {...defaultImgQ1Props} effect="blur"/>
                  </>
                }
                {isCNTrad &&
                  <>
                    <LazyLoadImage src={cnTradTournamentLobby1} alt="tournament link 1" {...defaultImgQ1Props} effect="blur"/>
                    <LazyLoadImage src={cnTradTournamentLobby2} alt="tournament link 2" {...defaultImgQ1Props} effect="blur"/>
                  </>
                }
                {!isChinese &&
                  <>
                    <LazyLoadImage src={tournamentLobby1} alt="tournament link 1" {...defaultImgQ1Props} effect="blur"/>
                    <LazyLoadImage src={tournamentLobby2} alt="tournament link 2" {...defaultImgQ1Props} effect="blur"/>
                  </>
                }
              </div>
            </section>
          </AnimateHeight>
        </div>

        <div className={`registrationQ2-container ${questionsObj.q2 ? 'back-shadow' : ''}`}>
          <button id="q2" onClick={() => { handleQuestionsObj('q2'); }}>
            <div className={`label-container ${questionsObj.q2 ? 'no-border' : ''}`}>
              <strong>{`2. ${t('q3')}`}</strong>
              {questionsObj.q2
                ? <KeyboardArrowDownIcon sx={iconStyle} />
                : <KeyboardArrowUpIcon sx={iconStyle} />}
            </div>
          </button>
          <AnimateHeight duration={animationDuration} height={ questionsObj.q2 ? 'auto' : 0 }>
            <section>
              <p>{t('faq3')}</p>
              <div className="q2-image-container images-container-row">
                {!isChinese &&
                  <>
                    <LazyLoadImage src={enhowToRegister1} alt="how to register 1" {...defaultImgProps} effect="blur"/>
                    <LazyLoadImage src={enhowToRegister2} alt="how to register 2" {...defaultImgProps} effect="blur"/>
                  </>}
                {isCNSimp &&
                  <>
                    <LazyLoadImage src={cnSimpHowToRegister1} alt="how to register 1" {...defaultImgProps} effect="blur"/>
                    <LazyLoadImage src={cnSimpHowToRegister2} alt="how to register 2" {...defaultImgProps} effect="blur"/>
                  </>}
                {isCNTrad &&
                  <>
                    <LazyLoadImage src={cnTradHowToRegister1} alt="how to register 1" {...defaultImgProps} effect="blur"/>
                    <LazyLoadImage src={cnTradHowToRegister2} alt="how to register 2" {...defaultImgProps} effect="blur"/>
                  </>}
              </div>
            </section>
          </AnimateHeight>
        </div>

        <div className={`registrationQ3-container ${questionsObj.q3 ? 'back-shadow' : ''}`}>
          <button id="q3" onClick={() => { handleQuestionsObj('q3'); }}>
            <div className={`label-container ${questionsObj.q3 ? 'no-border' : ''}`}>
              <strong>{`3. ${t('q10')}`}</strong>
              {questionsObj.q3
                ? <KeyboardArrowDownIcon sx={iconStyle} />
                : <KeyboardArrowUpIcon sx={iconStyle} />}
            </div>
          </button>
          <AnimateHeight duration={animationDuration} height={ questionsObj.q3 ? 'auto' : 0 }>
            <section>
              <p>{t('regQ3Faq')}</p>
              <div className="q3-image-container images-container-row">
                {isCNSimp &&
                  <>
                    <LazyLoadImage src={cnSimpRegister1} alt="Register 1" {...defaultImgProps} effect="blur"/>
                    <LazyLoadImage src={cnSimpRegister2} alt="Register 2" {...defaultImgProps} effect="blur"/>
                  </>}
                {isCNTrad &&
                  <>
                    <LazyLoadImage src={cnTradRegister1} alt="Register 1" {...defaultImgProps} effect="blur"/>
                    <LazyLoadImage src={cnTradRegister2} alt="Register 2" {...defaultImgProps} effect="blur"/>
                  </>}
                {!isChinese &&
                  <>
                    <LazyLoadImage src={enRegister1} alt="Register 1" {...defaultImgProps} effect="blur"/>
                    <LazyLoadImage src={enRegister2} alt="Register 2" {...defaultImgProps} effect="blur"/>
                  </>
                }
              </div>
            </section>
          </AnimateHeight>
        </div>

        <div className={`registrationQ4-container ${questionsObj.q4 ? 'back-shadow' : ''}`}>
          <button id="q4" onClick={() => { handleQuestionsObj('q4'); }}>
            <div className={`label-container ${questionsObj.q4 ? 'no-border' : ''}`}>
              <strong>{`4. ${t('q12')}`}</strong>
              {questionsObj.q4
                ? <KeyboardArrowDownIcon sx={iconStyle} />
                : <KeyboardArrowUpIcon sx={iconStyle} />}
            </div>
          </button>
          <AnimateHeight duration={animationDuration} height={ questionsObj.q4 ? 'auto' : 0 }>
            <section>
              <p>{t('faq12')}</p>
            </section>
          </AnimateHeight>
        </div>

        <div className={`registrationQ5-container ${questionsObj.q5 ? 'back-shadow' : ''}`}>
          <button id="q5" onClick={() => { handleQuestionsObj('q5'); }}>
            <div className={`label-container ${questionsObj.q5 ? 'no-border' : ''}`}>
              <strong>{`5. ${t('q13')}`}</strong>
              {questionsObj.q5
                ? <KeyboardArrowDownIcon sx={iconStyle} />
                : <KeyboardArrowUpIcon sx={iconStyle} />}
            </div>
          </button>
          <AnimateHeight duration={animationDuration} height={ questionsObj.q5 ? 'auto' : 0 }>
            <section>
              <p>{t('faq13')}</p>
            </section>
          </AnimateHeight>
        </div>

        <div className={`registrationQ6-container ${questionsObj.q6 ? 'back-shadow' : ''}`}>
          <button id="q6" onClick={() => { handleQuestionsObj('q6'); }}>
            <div className={`label-container ${questionsObj.q6 ? 'no-border' : ''}`}>
              <strong>{`6. ${t('q15')}`}</strong>
              {questionsObj.q6
                ? <KeyboardArrowDownIcon sx={iconStyle} />
                : <KeyboardArrowUpIcon sx={iconStyle} />}
            </div>
          </button>
          <AnimateHeight duration={animationDuration} height={ questionsObj.q6 ? 'auto' : 0 }>
            <section>
              <p>{t('faq15')}</p>
            </section>
          </AnimateHeight>
        </div>

        <div className={`registrationQ7-container ${questionsObj.q7 ? 'back-shadow' : ''}`}>
          <button id="q7" onClick={() => { handleQuestionsObj('q7'); }}>
            <div className={`label-container ${questionsObj.q7 ? 'no-border' : ''}`}>
              <strong>{`7. ${t('q17')}`}</strong>
              {questionsObj.q7
                ? <KeyboardArrowDownIcon sx={iconStyle} />
                : <KeyboardArrowUpIcon sx={iconStyle} />}
            </div>
          </button>
          <AnimateHeight duration={animationDuration} height={ questionsObj.q7 ? 'auto' : 0 }>
            <section>
              <p>{t('faq17')}</p>
            </section>
          </AnimateHeight>
        </div>

        <div className={`registrationQ8-container ${questionsObj.q8 ? 'back-shadow' : ''}`}>
          <button id="q8" onClick={() => { handleQuestionsObj('q8'); }}>
            <div className={`label-container ${questionsObj.q8 ? 'no-border' : ''}`}>
              <strong>{`8. ${t('q18')}`}</strong>
              {questionsObj.q8
                ? <KeyboardArrowDownIcon sx={iconStyle} />
                : <KeyboardArrowUpIcon sx={iconStyle} />}
            </div>
          </button>
          <AnimateHeight duration={animationDuration} height={ questionsObj.q8 ? 'auto' : 0 }>
            <section>
              <p>{t('faq18')}</p>
            </section>
          </AnimateHeight>
        </div>

        <div className={`registrationQ9-container ${questionsObj.q9 ? 'back-shadow' : ''}`}>
          <button id="q9" onClick={() => { handleQuestionsObj('q9'); }}>
            <div className={`label-container ${questionsObj.q9 ? 'no-border' : ''}`}>
              <strong>{`9. ${t('q33')}`}</strong>
              {questionsObj.q9
                ? <KeyboardArrowDownIcon sx={iconStyle} />
                : <KeyboardArrowUpIcon sx={iconStyle} />}
            </div>
          </button>
          <AnimateHeight duration={animationDuration} height={ questionsObj.q9 ? 'auto' : 0 }>
            <section>
              <p>{t('faq33')}</p>
            </section>
          </AnimateHeight>
        </div>
      </div>
    );
  };

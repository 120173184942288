import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import topMaster1en from '../assets/faq/web_mt_txt_faq_oth_02a_en_revised.webp';
import topMasterCnSimp1 from '../assets/faq/web_mt_txt_faq_oth_02a_hans_revised.webp';
import topMasterCnTrad1 from '../assets/faq/web_mt_txt_faq_oth_02a_hant_revised.webp';
import topMaster2en from '../assets/faq/web_mt_txt_faq_oth_02b_en_revised.webp';
import topMasterCnSimp2 from '../assets/faq/web_mt_txt_faq_oth_02b_hans_revised.webp';
import topMasterCnTrad2 from '../assets/faq/web_mt_txt_faq_oth_02b_hant_revised.webp';
// scss file is from parent component Faq.scss
import { type IOthers } from '../interfaces';
import { languageCodes } from '../languages/languages';

import 'react-lazy-load-image-component/src/effects/blur.css';

export const Others = ({ t, isChinese, i18n }: IOthers): JSX.Element => {
  const animationDuration = 300;
  const initialQuestionsObj = {
    q1: false,
    q2: false,
    q3: false
  };
  const [questionsObj, setQuestionsObj] =
  useState<Record<string, boolean>>(initialQuestionsObj);

  const handleQuestionsObj = (question: string): void => {
    if (!questionsObj[question]) {
      setQuestionsObj({ ...initialQuestionsObj, [question]: true });

      const questionNumber = question.split('')[1];
      const element = document.getElementById(`o${questionNumber}`);

      if (element != null) {
        setTimeout(() => {
          element.scrollIntoView(true);
        }, animationDuration);
      }
    }

    if (questionsObj[question]) {
      setQuestionsObj({ ...initialQuestionsObj });
    }
  };
  const iconStyle = { color: 'white', height: '48px', width: '48px' };

  const isCNSimp: boolean =
    i18n.resolvedLanguage === languageCodes.chineseSimplified;

  return (
    <div className="others-container">

      <div className={`others-q1-container ${questionsObj.q1 ? 'back-shadow' : ''}`}>
        <button id="o1" onClick={() => { handleQuestionsObj('q1'); }}>
          <div className={`label-container ${questionsObj.q1 ? 'no-border' : ''}`}>
            <strong>{`1. ${t('q31')}`}</strong>
            {questionsObj.q1
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q1 ? 'auto' : 0 }>
          <section>
            <p>{t('faq31')}</p>
          </section>
        </AnimateHeight>
      </div>

      <div className={`others-q2-container ${questionsObj.q2 ? 'back-shadow' : ''}`}>
        <button id="o2" onClick={() => { handleQuestionsObj('q2'); }}>
          <div className={`label-container ${questionsObj.q2 ? 'no-border' : ''}`}>
            <strong>{`2. ${t('q35')}`}</strong>
            {questionsObj.q2
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q2 ? 'auto' : 0 }>
          <section>
            <p>{t('faq35')}</p>
            <div className="q2-image-container images-container-row">
              {!isChinese &&
                <>
                  <LazyLoadImage src={topMaster1en} alt="Top Master 1" width="350px" height="auto" effect="blur"/>
                  <LazyLoadImage src={topMaster2en} alt="Top Master 2" width="350px" height="auto" effect="blur"/>
                </>}
              {isChinese &&
                <>
                  <LazyLoadImage src={isCNSimp ? topMasterCnSimp1 : topMasterCnTrad1} alt="Top Master 1" width="350px" height="auto" effect="blur"/>
                  <LazyLoadImage src={isCNSimp ? topMasterCnSimp2 : topMasterCnTrad2} alt="Top Master 2" width="350px" height="auto" effect="blur"/>
                </>}
            </div>
          </section>
        </AnimateHeight>
      </div>

      <div className={`others-q3-container ${questionsObj.q3 ? 'back-shadow' : ''}`}>
        <button id="o3" onClick={() => { handleQuestionsObj('q3'); }}>
          <div className={`label-container ${questionsObj.q3 ? 'no-border' : ''}`}>
            <strong>{`3. ${t('q36')}`}</strong>
            {questionsObj.q3
              ? <KeyboardArrowDownIcon sx={iconStyle} />
              : <KeyboardArrowUpIcon sx={iconStyle} />}
          </div>
        </button>
        <AnimateHeight duration={animationDuration} height={ questionsObj.q3 ? 'auto' : 0 }>
          <section>
            <p>{t('faq36')}</p>
          </section>
        </AnimateHeight>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { type IFaqSection } from '../interfaces';

import { Others, Registration, TournamentSetting } from '.';

import '../styles/Faq.scss';

export const FaqSection =
  ({ t, isChinese, i18n }: IFaqSection): JSX.Element => {
    const REGISTRATION = 'Registration';
    const TOURNAMENT_SETTING = 'Tournament Setting';
    const OTHERS = 'Others';
    const disabledClass = 'disabled-btn';
    const [sectionFaq, setSectionFaq] = useState<string>(REGISTRATION);

    const sections = [REGISTRATION, TOURNAMENT_SETTING, OTHERS];
    const sectionsObj: Record<string, string> =
    {
      Registration: 'registration',
      'Tournament Setting': 'tournamentSetting',
      Others: 'others'
    };

    return (
      <section className="faq-container" id="faq">
        <h2>{t('faq')}</h2>
        <div className="faq-content">
          <div className="faq-section-nav-container">
            {sections.map(section => (
              <button key={section} className={sectionFaq !== section ? disabledClass : 'btn-highlight'} onClick={() => { setSectionFaq(section); }}>
                <strong>{t(sectionsObj[section])}</strong>
              </button>
            ))}
          </div>
          {sectionFaq === REGISTRATION &&
            <LazyLoadComponent>
              <Registration t={t} isChinese={isChinese} i18n={i18n} />
            </LazyLoadComponent>}
          {sectionFaq === TOURNAMENT_SETTING &&
            <LazyLoadComponent>
              <TournamentSetting t={t} i18n={i18n}/>
            </LazyLoadComponent>}
          {sectionFaq === OTHERS &&
            <LazyLoadComponent>
              <Others t={t} isChinese={isChinese} i18n={i18n}/>
            </LazyLoadComponent>}
        </div>
      </section>
    );
  };

export const kr = {
  gog: '도박꾼의 신',
  introduction: '소개',
  award: '상금',
  details: '상세정보',
  faq: '자주 묻는 질문',
  new: '새로운',
  topMaster: '최고 주인',
  backToTop: '맨 위로 가기',
  introductionDetails: 'AG 마스터 토너먼트는 가장 인기 있는 바카라 게임을 기반으로 현재 ' +
    '온라인에서 가장 인기있는 \'싯 앤 고\' 방식을 사용하여 만들어졌으며, 모든 플레이어를 대상으로 ' +
    '가장 흥미 진진한 온라인 바카라 토너먼트를 개최하기 위해 공정하고 투명한 토너먼트 규칙을 사용합니다',
  howToEnter: '참여 방법',
  gameProcess: '프로세스',
  step: '단계',
  step1: '토너먼트는 AG 실제 금액 계정 전용입니다;',
  step2: '마스터 토너먼트 로비를 선택하고 원하시는 토너먼트를 선택하세요;',
  howToJoin1: '토너먼트는 AG 실제 금액 계정 전용입니다',
  howToJoin2: '마스터 토너먼트 로비를 선택하고 원하시는 토너먼트를 선택하세요',
  satelliteTournament: '위성 토너먼트는 정해진 시작 시간이 없습니다. 테이블에 충분한 인원이 ' +
    '등록되는 대로 싯앤고가 시작됩니다. 우승자는 마스터 토너먼트 등록을 위한 티켓을 획득하게 됩니다.',
  masterTournament: '마스터 토너먼트는 고정된 시간에 시작됩니다. 정해진 참가자 수가 채워지면 토너먼트가 즉시 시작됩니다.',
  seasonTournament: '분기별 토너먼트는 고정 시간 모드를 사용하여 시작합니다. 시즌 마스터 토너먼트 우승자를 초대합니다. ' +
    '포인트를 위해 참여한 타 플레이어들과 경쟁하고, 챔피언이 되어 풍부한 상품을 받아가세요!',
  awardDetails: '모든 우승자의 상금은 대기 및 검토 없이 즉시 사용자 계정으로 바로 입금됩니다.',
  qualifyingRound: '위성 토너먼트',
  qualRoundText: '새틀라이트 토너먼트의 우승자는 마스터 토너먼트에 참가할 수 있는 티켓을 받고 더 많은 상품을 받을 수 있습니다.',
  gogTickets: '마스터 토너먼트 티켓\n또는 필요한 사용자 포인트',
  gogMasterTournament: '마스터 토너먼트',
  gogStep3: '마스터 토너먼트는 고정 시간 모드를 사용하여 시작합니다. 참가자가 목표에 도달하면 토너먼트가 즉시 시작됩니다.',
  tournamentRules: '토너먼트 규칙',
  fairness: '공평',
  regAndWithdrawal: '등록 / 탈퇴',
  inGame: '규칙',
  ranking: '순위',
  withdraw3: '등록 후 철회(요구인원 미충족 시) 및 토너먼트 시작 전',
  withdraw3Child: '플레이어는 즉시 철회할 수 있으며, 3분 이내에 플레이어는 동일한 유형의 토너먼트를 다시 등록할 수 없으며 시스템은 차감된 포인트/티켓을 반환합니다.',
  withdraw4: '등록(참가자 수에 도달) 후 토너먼트가 시작되지 않은 후 철회:',
  withdraw4Child: '참가자는 토너먼트에 참가해야만 철회할 수 있으며 참가자는 시스템에서 탈락한 것으로 간주됩니다. 차감된 모든 사용자 포인트 또는 티켓은 환불되지 않습니다.',
  withdraw5: '토너먼트 도중 은퇴를 결정한 참가자',
  withdraw5Child: '토너먼트 중에 참가자 중 한 명이 다른 게임을 선택하거나 토너먼트를 떠나면 순위와 우승 기회는 유효한 베팅 수와 참가자가 배치한 라운드 수로 판단됩니다.',
  withdraw6: '플레이어는 해당 게임을 종료한 후 동일한 게임을 다시 등록할 수 없습니다.',
  withdraw7: '각 플레이어는 동일한 유형의 토너먼트에 5회 이상 등록할 수 없는 경우 같은 날 최대 5회까지만 등록된 토너먼트에서 철회할 수 있습니다.',
  tournamentRules1: '플레이어가 토너먼트에 등록한 후, 토너먼트에서는 토너먼트 전용 테이블에서 정해진 전용 칩으로만 베팅 가능합니다. 토너먼트 칩은 플레이어의 개인 계정과 사용자 포인트 와 연결되어 있지 않습니다. 모든 토너먼트 칩은 다음 토너먼트 세션으로 누적되지 않습니다. 만약 플레이어의 계정 포인트가 충분하지 않다면, 일반 테이블에서 유효한 베팅을 통해 계정 포인트로 교환할 수 있습니다',
  tournamentRules2: '시스템의 공정성과 투명성을 보장하기 위해, 플레이어가 성공적으로 등록을 마친 후, 시스템에 의해 무작위로 자리가 배정됩니다. 참가자는 자리를 선택하거나 다른 참가자와 자리를 바꿀 수 없습니다.',
  tournamentRules3: '모든 우승자의 현금 상금은 상금 구조에 따라 나누어 지며, 모든 상금은 사용자 계정으로 바로 입금됩니다.',
  tournamentRules4: '토너먼트는 논-커미션 바카라 규칙을 기반으로 합니다. 바카라 게임 규칙에 대한 자세한 내용은 메뉴 > 게임 규칙에서 확인할 수 있습니다. 각 참가자에게는 매 라운드마다 25 초의 베팅 제한 시간이 있습니다. 각 참가자는 매 핸드마다 뱅커 또는 플레이어에 최소 금액을 적어도 한번은 베팅해야 합니다. 참가자는 뱅커 또는 플레이어 대신에 타이/페어에 베팅할 수 없습니다.',
  tournamentRules5Part1: '예선 라운드가 시작되기 전에 100,000의 토너먼트 칩(토너먼트 칩은 금전적 가치가 없는 칩으로 오직 토너먼트 목적으로만 사용됩니다)이 지정된 테이블의 각 참가자에게 지급됩니다. 토너먼트가 진행되는 동안 참가자에게는 1000-10000의 베팅금액 제한이 있습니다.',
  tournamentRules5Part2: '참가자는 사용 가능한 토너먼트 칩 이상을 베팅해서는 안되며, 핸드 당 베팅 한도는 다음과 같습니다',
  betItem: '베팅 항목',
  minBet: '최소 베팅',
  maxBet: '최대 베팅',
  banker: '뱅커',
  player: '플레이어',
  tie: '타이',
  pair: '페어',
  tournamentRules6: '모든 토너먼트는 시작하기 전 2번의 핸드를 건너 뛰게 됩니다. 토너먼트가 진행되는 동안 셔플은 진행되지 않습니다.',
  tournamentRules7: '참가자 간의 공정성과 투명성을 보장하기 위해, 토너먼트에서는 다음과 같은 승격 및 탈락 규정을 사용합니다:',
  tournamentRules7_1: '게임이 진행되는 동안, 시스템은 각 라운드의 가상 테이블마다 각 경기에 설정된 규칙을 적용하여 지정된 숫자의 플레이어를 승격시키고, 승격되지 않은 플레이어는 자동으로 탈락 처리됩니다.',
  tournamentRules7_2: '예시: 토너먼트에 참가한 56명의 참가자는, 8개의 가상 테이블(A、B、C、D、E、F、G 、H )로 나누어 지며, 경기는 4개의 섹션(각 섹션마다 5번의 라운드)으로 구성됩니다. 그 다음, 각 섹션에서 상위 3명의 참가자를 승격시킵니다. (즉, A부터 H 테이블까지, 토너먼트가 끝날때 까지 상위 3명의 플레이어가 다음 라운드로 승격하게 됩니다.)',
  tournamentRules7_3: '모든 승격된 참가자는 토너먼트 칩에서 우위를 갖게 됩니다: 만약 토너먼트가 각 테이블에 2명의 승격된 참가자를 배정한 경우. 참가자가 다음 라운드에서 첫번째로 승격한다면, 각 테이블의 첫번째로 승격한 참가자에게는 즉시 지급되는 토너먼트 칩의 고정된 비율의 향상이 있습니다. (즉. 상위 플레이어는 다음 라운드에서 다른 참가자들과는 달리 더 많은 토너먼트 칩을 갖게 됩니다);',
  tournamentRules7_4: '시스템은 테이블 당 평균 플레이어 수를 가능한 한 비슷하게 유지할 것입니다. 토너먼트가 진행되는 동안, 각 라운드의 승격된 참가자는 다시 한번 무작위로 테이블이 배정됩니다.',
  tournamentRules8: '토너먼트가 시작되기 전에 다음과 같은 제한된 특별 베팅 유형이 참가자에게 배포됩니다.',
  tournamentRules8_1: '블라인드 베팅은 참가자가 자신의 베팅 금액을 숨기는 전략적 베팅 기술 중 하나입니다. 블라인드 베팅은 핸드가 완료되기 전에 공개되어야 합니다. 블라인드 베팅 횟수는 토너먼트에서 제공되는 사이클 수에 따라 결정됩니다. 예: 총 사이클: 5, 총 블라인드 베팅 횟수: 5',
  tournamentRules8_2: '“베팅 패스”는 현재 라운드의 베팅을 건너 뜁니다. 현재 라운드에 베팅하고 싶지 않다면, “베팅 패스” 버튼을 클릭하면 됩니다. 베팅 패스 횟수는 토너먼트에서 제공되는 사이클 수에 따라 결정됩니다. 예: 총 사이클: 5, 총 베팅 패스 횟수: 5',
  tournamentRules9: '토너먼트 세션이 끝나면, 사용된 또는 사용되지 않은 모든 토너먼트 칩과 특별 베팅 유형은 각 경기가 끝날때마다 자동으로 제거 됩니다. 참가자는 다른 참가자에게 토너먼트 칩을 양도하거나 대여할 수 없습니다.',
  tournamentRules10: '랭킹 규칙은 다음 우선 순위가 적용됩니다:',
  tournamentRules10_1: '승격된 참가자가 다른 탈락한 참가자보다 높은 랭크를 차지하며, 최종 남은 토너먼트 칩을 토대로 랭크가 결정됩니다;',
  tournamentRules10_2: '참가자들의 남아있는 토너먼트 칩이 같을 경우, 총 유효한 토너먼트 칩이 많은 참가자가 더 높은 랭크를 획득합니다;',
  tournamentRules10_3: '위의 두 가지 규칙 외에도 같은 랭킹을 획득한 참가자가 두 명 이상일 경우, 먼저 등록한 참가자가 더 높은 랭크를 획득합니다;',
  tournamentRules11: '참가자가 어떤 이유로든 세션 또는 라운드 중에 테이블을 떠나야 하는 경우, 부재 시간은 5핸드 이상 초과해서는 안됩니다; 그렇지 않다면 참가자는 상금을 획득할 자격을 잃게 되며, 랭킹에만 참가할 수 있습니다.',
  tc: '이용 약관',
  tc1: '토너먼트는 AG에 유효한 실제 계정을 보유한 참가자에게만 제공됩니다.',
  tc2: '토너먼트 기간동안, 플레이어는 “위성 토너먼트” 이벤트에 등록하기 위해 플레이어 계정의 포인트를 사용해야 합니다;',
  tc3: '모든 토너먼트는 다른 토너먼트 로비에서 발표한 시간에 따라 시작하게 되며, 당사는 플레이어에게 사전 통지 없이 토너먼트를 변경, 지연 또는 취소할 권리가 있습니다.',
  tc4: '사용자 포인트는 오직 라이브 게임에서 유효한 베팅을 통해 획득할 수 있으며, 다음이 포함됩니다: 모든 유형의 바카라, 드래곤 타이거, 식보, 룰렛, 블랙잭, 3카드 바카라, BID, MultiPlay, Vip 그리고 Good Road betting. 사용자 포인트에 대해 더 알고 싶으시면, 사용자 포인트를 클릭하세요.',
  tc5: '”위성 토너먼트” 우승자는 “마스터 토너먼트” 티켓을 획득하게 됩니다(만약 모든 티켓이 소진 된 경우, 모든 토너먼트는 등록을 받지 않습니다, 선착순으로 진행됩니다). 모든 티켓은 현재 진행중인 월간 이벤트 기간에만 유효합니다;',
  tc6: '토너먼트를 당사의 소중한 고객들에게만 제공하기 위해, 각 고객은 하나의 계정과 하나의 IP주소를 사용하여 참가하도록 제한되어 있습니다. 규칙을 위반한 고객은 사기 행위로 간주되어 상금 자격을 잃게 됩니다. AG는 사전 통보없이, 타이틀을 다루거나 관련 이익을 소거할 권리가 있습니다.',
  tc7: '헤징, 공모 또는 속임수 등 불법적인 수단으로 토너먼트에 참가한 모든 참가자는 약관 6을 위반한 것과 동등하게 간주됩니다;',
  tc8: '참가자가 규칙을 위반한 경우, 참가자격을 상실하게 됩니다.',
  tc9: '모든 참가자는 AG 이벤트에 참여함으로써, 이러한 모든 이용약관에 동의한다는 것을 의미합니다.',
  tc10: 'AG는 이벤트에 대한 최종결정권이 있습니다.',
  remark: '참조',
  registration: '등록',
  registration1: '마스터 토너먼트 티켓과 충분한 유저포인트를 보유하고 계신 경우, 둘 중 하나를 선택하시어 이용 가능하십니다.',
  registration2: '토너먼트가 20분 이내에 시작된다면, 각 계정은 하나의 토너먼트에만 등록할 수 있습니다.(위성 또는 마스터 토너먼트)',
  registration2_1: '마스터 토너먼트에 참가하는 플레이어를 보장하기 위해, 토너먼트가 20분 이내에 시작된다면, 모든 등록된 플레이어는 같은 계정을 사용하여 다른 위성 토너먼트에 등록할 수 없습니다.',
  registration2_2: '마스터 토너먼트 시작이 20분 이상 남아있다면, 플레이어는 위성 토너먼트에 등록할 수 있습니다. 마스터 토너먼트가 20분 이내에 시작된다면, 기존에 등록한 위성 토너먼트에 차감된 모든 사용자 포인트는 환불됩니다.',
  cancelReg: '등록 취소',
  cancelReg1: '토너먼트 시작 전 참여를 취소하실 수 있으십니다. 취소 후 3분 동안은 동일한 토너먼트 참여가 불가하십니다.',
  cancelReg2: '토너먼트가 진행되는 동안, 참가자 중 한명이 다른 게임을 선택하거나 토너먼트를 떠난다면, 랭킹 및 우승 기회는 유효한 베팅 수와 참가자가 베팅했던 라운드 수에 따라 결정됩니다.',
  watch: '관전',
  watch1: '모든 플레이어는 “관전” 옵션을 선택하여 진행중인 토너먼트를 관전하실 수 잇습니다. 관전 모드에서 플레이어는 베팅을 할 수 없지만 문자 메시지 기능은 사용하실 수 있습니다.',
  exception: '예외사항',
  exception1: '서버 오류, 시스템 장애로 인해 토너먼트가 원활하게 운영되지 않은 경우, 당사는 토너먼트를 변경 또는 취소하고, 성공적으로 등록을 완료한 모든 참가자에게 차감된 모든 포인트와 티켓을 환불할 수 있는 권리가 있습니다,',
  exception2: '토너먼트가 시작된 후, 플레이어의 개인 네트워크 문제로 인해 접속이 끊어졌다면, 5라운드 이내에 재접속 해야하며 최대한 빨리 토너먼트 세션에 돌아와야 합니다. 5라운드가 진행되는 동안, 자동 베팅 시스템이 플레이어 구역에 최소 금액을 베팅합니다. 참가자가 5라운드 내에 재접속이 불가능한 경우, 자동으로 탈락합니다. 참가자가 수동으로 베팅하지 않았기 때문에 랭킹에서 탈락하게 되지만 상금 기회는 주어집니다.',
  exception3: '지속적으로 헤지 베팅을 하는 모든 토너먼트 참가자는 참가자격과 상금이 취소됩니다. AG는 이러한 참가자를 블랙리스트에 등재하고, AG가 주최하는 모든 이벤트와 활동에 참가할 수 없도록 할 권리가 있습니다.',
  q1: '분기별 토너먼트에 어떻게 참여하나요?',
  faq1: '분기별 토너먼트는 어떻게 참여하나요: 분기별 토너먼트 티켓을 획득하거나, 적립한 포인트를 사용 할 수 있습니다.\n\n분기별 토너먼트 티켓은 어떻게 얻을 수 있나요? 시즌 마스터 토너먼트의 우승자는 분기별 토너먼트가 시작하기 전 참여 티켓을 획득 할 수 있습니다. 해당 티켓은 현재 진행 되는 분기별 토너먼트 한정 티켓 입니다.',
  q2: '마스터 토너먼트 로비는 어디에 있나요?',
  faq2: 'AG 데스크탑 버전에서 성공적으로 로그인한 경우, 마스터 토너먼트 탭을 찾을 수 있습니다. 클릭하여 토너먼트 로비에 입장하십시오. 만약 토너먼트 탭이 표시되지 않는 경우, 운영자 또는 고객 서비스에 자세한 내용을 문의하십시오. 모바일 HTML5의 경우, 데스크탑 버전과 유사한 토너먼트 로비의 개별 입구가 있습니다.',
  q3: '등록 방법은?',
  faq3: '토너먼트 로비로 이동한 다음, \'등록\' 버튼을 클릭하십시오.',
  q4: '토너먼트 참가 요건은 무엇입니까?',
  faq4: 'AG 개인 실제 계정이 필요하며, 당사의 토너먼트에 참가하기 위해서는 계정에 충분한 포인트 또는 토너먼트 티켓을 보유하고 있어야 합니다.',
  q5: '포인트 환율은 어떻게 되나요?',
  faq5: 'RMB100 유효 베팅 = 1 포인트. 내용은 사용자 포인트 확인 가능합니다\n예시:\n플레이어 A의 유효베팅이 10,000인 경우, 플레이어 A는 100 포인트 획득;\n플레이어 B의 유효베팅이 8,650인 경우, 플레이어 B는 86 포인트 획득;\n점수는 소수 자리를 포함하지 않는 정수로만 계산됩니다.',
  q6: '현재 포인트 잔액을 어디에서 확인할 수 있습니까?',
  faq6: '고객님의 아바타 옆에 있는 정보 버튼(i)을 클릭하면 개인 계정 세부 정보가 표시됩니다. 여기에 계정의 포인트 잔액이 포함되어 있습니다:\n또는 마스터 토너먼트 로비를 클릭하십시오. 로비 오른쪽 상단 모서리에 개인 계정의 포인트가 표시됩니다.',
  q7: '포인트를 더 얻는 방법은?',
  faq7: '플레이어 계정 포인트 획득은 모든 라이브 게임의 유효한 베팅을 기반으로 합니다. 포함된 라이브 게임: 모든 유형의 바카라, 드래곤 타이거, 식보, 룰렛, 블랙잭, 3카드 바카라, BID, MultiPlay, Vip 그리고 Good Road betting\n\n참고: 모튼 팁이나 진행자에게 준 선물, 비-라이브 게임의 베팅은 계정 포인트에 포함되지 않습니다.',
  q8: '유효하거나 유효하지 않은 베팅은 무엇입니까?',
  faq8: '유효 베팅: 승리 또는 패배한 모든 베팅\n유효하지 않은 베팅: 플레이어들이 같은 게임에서 같은 시간에 베팅하여 승리 또는 패배의 결과가 모두 나온 경우.\n예시: 바카라에서 같은 시간 같은 라운드에서 플레이어가 \'뱅커\'와 \'플레이어\' 모두에 베팅한 경우',
  q9: '위성 토너먼트와 마스터 토너먼트의 차이점은 무엇입니까?',
  faq9: '위성 토너먼트: 위성 토너먼트는 정해진 시작 시간이 없습니다. 테이블에 충분한 인원이 등록되는 대로 싯앤고가 시작됩니다. 우승자는 마스터 토너먼트 등록을 위한 티켓을 획득하게 됩니다.\n마스터 토너먼트: 마스터 토너먼트는 고정된 시간에 시작됩니다.',
  q10: '등록비는 얼마입니까?',
  faq10: '액티비티 기간에는, 모든 AG 실제 금액 계정은 어떤 등록 비용 및 처리 비용을 지불할 필요가 없습니다. 플레이어는 오직 토너먼트 등록을 위해서 플레이어 계정에 충분한 포인트 또는 마스터 토너먼트 티켓을 보유하고 있어야 합니다.',
  q11: '마스터 토너먼트 티켓을 얻는 방법은 무엇입니까?',
  faq11: '참고: 각 참가자는 마스터 토너먼트 티켓을 최대 10장까지 보유할 수 있습니다. 티켓은 현재 진행중인 월간 이벤트에만 유효합니다. 토너먼트가 끝나면 사용하지 않은 티켓은 모두 무효가 되고 AG는 사용자 포인트를 반환하거나 환불해드리지 않습니다.',
  q12: '사용자 포인트를 사용하여 마스터 토너먼트 티켓을 교환할 수 있습니까?',
  faq12: '네, 마스터 토너먼트 티켓을 보유하고 있지 않지만 사용자 포인트가 충분할 때 가능합니다. 마스터 토너먼트 등록 버튼을 클릭하면 토너먼트 시스템이 토너먼트 세부 사항에 명시된 대로 자동으로 사용자 포인트를 차감합니다.\n참고: 위성 토너먼트 등록은 티켓이 아닌 사용자 포인트로만 가능합니다.',
  q13: '유저포인트와 티켓 중 어느것을 먼저 사용할지 선택 가능한가요?',
  faq13: '위성 토너먼트의 경우 유저포인트만 차감되며, 마스터 토너먼트의 경우 유저포인트와 마스터 토너먼트 티켓 중 선택 가능하십니다.',
  q14: '트라이얼 계정을 통해 토너먼트에 등록할 수 있습니까?',
  faq14: '모튼 토너먼트는 AG 실제 계정에서만 가능합니다. AG 트라이얼 계정은 관전 모드에서만 사용가능합니다. 즉. 진행중인 토너먼트 관전만 가능합니다. AG 실제 계정을 만드고자 하시는 경우, 고객 서비스 담당자에게 문의하십시오.',
  q15: '등록 실패에 대한 경고를 하는 이유는 무엇입니까?',
  faq15: '등록이 실패한 이유는 다음과 같습니다:\n귀하의 계정이 AG 실제 계정이 아니거나 토너먼트 요구 조건과 일치하지 않는 경우;\n기존에 등록했지만, 토너먼트 조기 종료를 선택한 경우 3분의 대기 페널티가 부과됩니다.',
  q16: '등록 후에 등록을 취소 할 수 있습니까?',
  faq16: '등록 후(요구 플레이어가 차지 않은 경우) 또는 토너먼트가 시작되지 않은 경우:\n플레이어는 즉시 종료 할 수 있지만, 3분간 같은 유형의 토너먼트에 재등록 할 수 없으며, 시스템은 차감된 포인트 또는 티켓을 환불해 드립니다.\n등록 후(참가자 수 도달) 그리고 토너먼트가 시작하지 않은 경우:\n참가자는 토너먼트에 참가한 후 종료해야 하며, 시스템은 참가자를 탈락으로 간주합니다. 시스템은 차감된 포이트 또는 티켓을 환불해 드리지 않습니다.\n참가자가 토너먼트 진행중 스스로 포기한 경우:\n토너먼트 진행중에 참가자가 다른 게임을 선택하거나 토너먼트를 떠난다면, 랭킹과 우승 기회는 유효한 베팅 수와 참가자의 베팅 라운드에 따라 결정됩니다.\n참고:\n1. 종료 후에, 토너먼트에 등록하는 동일한 플레이어는, 같은 게임에 재등록 할 수 없습니다.\n2. 각 플레이어는 동일한 날짜에 등록한 토너먼트를 5회만 종료 가능합니다, 5회가 넘은 경우 동일한 유형의 토너먼트의 등록할 수 없습니다.',
  q17: '등록 후에, 토너먼트 시작을 위해 로비에서 기다려야 하나요?',
  faq17: '토너먼트 시작을 기다릴 필요는 없습니다. 플레이어는 시작 시작 전까지 다른 게임에 참가할 수 있습니다. 시스템이 토너먼트 참가 준비를 알리는 메시지를 보내 드립니다.',
  q18: '각 토너먼트 소요시간은?',
  faq18: '각 토너먼트는 약 5분에서 20분 정도 소요됩니다.',
  q19: '토너먼트는 실제 돈을 사용하여 진행되나요?',
  faq19: '모든 참가자는 토너먼트 칩만 사용할 수 있습니다. 모든 토너먼트 칩은 참가자의 실제 잔액과는 관련이 없습니다.',
  q20: '토너먼트 칩은 무엇입니까?',
  faq20: 'AG 마스터 토너먼트는 공정하고 정당하며 투명한 원칙을 고수하고, 모든 토너먼트 참가자는 랭킹 결정에 사용되는 토너먼트 칩을 동일하게 지급받습니다. 참가자의 실제 계정의 잔액과는 관련이 없습니다. 칩은 오직 토너먼트에서만 사용 가능하며, 각 섹션 종료마다, 토너먼트 칩은 제거됩니다.',
  q21: '관객도 토너먼트에 베팅 할 수 있나요?',
  faq21: '오직 등록한 플레이어만 베팅 가능합니다. 관객은 베팅 할 수 없으며 사이드라인에서 관전만 가능합니다. 참가를 원하는 경우, 토너먼트 로비에서 \'등록\' 버튼을 클릭하면 됩니다.',
  q22: '온라인은 무엇을 의미하나요?',
  faq22: '토너먼트에 등록한 총 플레이어의 수를 말합니다.',
  q23: '각 베팅 영역의 베팅 한도는 얼마입니까?',
  betLimit: '베팅 한도',
  bankerPair: '뱅커 페어',
  playerPair: '플레이어 페어',
  q24: '\'페어\'와 \'타이\'에 베팅할 수 없는 이유는 무엇인가요?',
  faq24: '모든 참가자는 페어와 타이에 베팅하기 전에 뱅커 또는 플레이어에 베팅해야만 합니다.',
  q25: '블라인드는 무엇인가요?',
  faq25: '블라인드 베팅은 참가자가 자신의 베팅 금액을 숨기는 전략적 베팅 기술 중 하나입니다. 블라인드 베팅은 핸드가 완료되기 전에 공개되어야 합니다.',
  q26: '패스는 무엇인가요?',
  faq26: '패스는 현재 라운드의 베팅을 건너 뜁니다. 현재 라운드에 베팅하고 싶지 않다면, “패스” 버튼을 클릭하면 됩니다.',
  q27: '패스와 블라인드 횟수는 누적되지 않나요?',
  faq27: '모든 사용된 또는 사용되지 않은 카드는 각 토너먼트에서만 유효합니다. 토너먼트가 종료되면, 남아있는 모든 패스와 블라인드는 다음 라운드를 위해 초기화됩니다.',
  q28: '마스터 토너먼트에서 어떻게 승격 또는 탈락이 결정되나요?',
  faq28: '플레이어 간의 공정성과 투명성을 보장하기 위해, 마스터 토너먼트는 다음과 같은 승격 및 탈락 규칙을 사용합니다:\n게임이 진행되는 동안, 시스템은 각 라운드의 가상 테이블마다 각 경기에 설정된 규칙을 적용하여 지정된 숫자의 플레이어를 승격시키고, 승격되지 않은 플레이어는 자동으로 탈락합니다. 예시: 토너먼트에 참가한 56명의 참가자는, 8개의 가상 테이블(A、B、C、D、E、F、G 、H )로 나누어 지며, 경기는 4개의 섹션(각 섹션마다 5번의 라운드)으로 구성됩니다. 그 다음, 각 섹션에서 상위 3명의 참가자를 승격시킵니다. (즉, A부터 H 테이블까지, 토너먼트가 끝날때 까지 상위 3명의 플레이어가 다음 라운드로 승격하게 됩니다.)',
  q29: '플레이어의 랭킹은 어떻게 결정되나요?',
  faq29: '랭킹 규칙은 다음 우선 순위가 적용됩니다:\n승격된 참가자가 다른 탈락한 참가자보다 높은 랭크를 차지하며, 최종 남은 토너먼트 칩을 토대로 랭크가 결정됩니다;\n참가자들의 남아있는 토너먼트 칩이 같을 경우, 총 유효한 토너먼트 칩이 많은 참가자가 더 높은 랭크를 획득합니다;\n위의 두 가지 규칙 외에도 같은 랭킹을 획득한 참가자가 두 명 이상일 경우, 먼저 등록한 참가자가 더 높은 랭크를 획득합니다;',
  q30: '상금이나 현금을 어떻게 분배하나요?',
  faq30: '자세한 내용은 \'상세정보\' 버튼을 클릭하십시오.',
  q31: '토너먼트에서 획득한 상금은 어떻게 받을 수 있나요?',
  faq31: '토너먼트에서 획득하신 모든 보너스는 검토 즉시 플레이어의 계정에 지급됩니다.',
  q32: '마스터 토너먼트 두번째 라운드 시작 시에 왜 다른 플레이어는 나보다 더 많은 칩을 가지고 있나요?',
  faq32: '경쟁력 향상을 위해, 모든 플레이어의 칩은 다음 라운드 시작 전에 증가합니다.',
  q33: '경기가 취소되면, 경기에 사용된 포인트 또는 마스터 티켓은 환불되나요?',
  faq33: '포인트 또는 티켓은 어떤 추가 비용없이 환불됩니다.',
  q34: '베팅 기록은 어디에서 찾을 수 있나요?',
  faq34: '플레이어는 다음과 같은 방법으로 확인 가능합니다: \'고객 서비스\' > \'계정 기록\' > 그리고 \'경기 베팅 기록\'',
  q35: '\'최고 마스터\'는 무엇인가요?',
  faq35: '최고 마스터에는 각 마스터 토너먼트에서 1위를 차지한 우승자의 이름과 상금이 표시됩니다. 플레이어의 이름을 클릭하면 플레이어가 참가한 토너먼트 섹션과 획득한 상금을 확인할 수 있습니다.',
  q36: '토너먼트 경기 중에 네트워크가 갑자기 끊어지면 어떻게 해야 하나요?',
  faq36: '토너먼트 진행 중에, 네트워크가 끊어졌거나 실패했다면, 시스템은 자동으로 \'플레이어\'에 최소 금액 베팅을 진행합니다. 플레이어가 동일한 토너먼트 테이블에 5라운드 이내에 돌아오지 않는다면, 플레이어는 탈락합니다. 또한 시스템은 플레이어가 베팅한 라운드 및 세션을 바탕으로 랭킹과 상금 획득 기회를 결정합니다.',
  topMasterCongrats: '모든 AG 마스터 토너먼트 승자에게 축하인사를 전합니다',
  tournamentSetting: '환경',
  others: '다른',
  regQ3Faq: '토너먼트 기간 동안 모든 AG 실제 현금 계정은 등록 및 취급 수수료를 지불할 필요가 없습니다. 플레이어가 마스터 토너먼트에 등록하려면 충분한 포인트 또는 마스터 토너먼트 티켓이 있어야 합니다.'
};

import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import blind1en from '../assets/faq/blind1.webp';
import blind2en from '../assets/faq/blind2.webp';
import pass1en from '../assets/faq/pass1.webp';
import pass2en from '../assets/faq/pass2.webp';
import blind1cnSimp from '../assets/faq/web_mt_txt_faq_ts_06a_hans.webp';
import blind1cnTrad from '../assets/faq/web_mt_txt_faq_ts_06a_hant.webp';
import blind2cnSimp from '../assets/faq/web_mt_txt_faq_ts_06b_hans.webp';
import blind2cnTrad from '../assets/faq/web_mt_txt_faq_ts_06b_hant.webp';
import pass1cnSimp from '../assets/faq/web_mt_txt_faq_ts_07a_hans.webp';
import pass1cnTrad from '../assets/faq/web_mt_txt_faq_ts_07a_hant.webp';
import pass2cnSimp from '../assets/faq/web_mt_txt_faq_ts_07b_hans.webp';
import pass2cnTrad from '../assets/faq/web_mt_txt_faq_ts_07b_hant.webp';
// scss file is from parent component Faq.scss
import { type ITournamentSetting } from '../interfaces';
import { languageCodes } from '../languages/languages';

import 'react-lazy-load-image-component/src/effects/blur.css';

export const TournamentSetting =
  ({ t, i18n }: ITournamentSetting): JSX.Element => {
    const animationDuration = 300;
    const initialQuestionsObj = {
      q1: false,
      q2: false,
      q3: false,
      q4: false,
      q5: false,
      q6: false,
      q7: false,
      q8: false
    };

    const passIconWidth = '76px';
    const passIconHeight = '76px';

    const [questionsObj, setQuestionsObj] =
    useState<Record<string, boolean>>(initialQuestionsObj);

    const handleQuestionsObj = (question: string): void => {
      if (!questionsObj[question]) {
        setQuestionsObj({ ...initialQuestionsObj, [question]: true });

        const questionNumber: string = question.split('')[1];
        const element: HTMLElement | null =
         document.getElementById(`ts${questionNumber}`);

        if (element !== null) {
          setTimeout(() => {
            element.scrollIntoView(true);
          }, animationDuration);
        }
      }

      if (questionsObj[question]) {
        setQuestionsObj({ ...initialQuestionsObj });
      }
    };
    const iconStyle = { color: 'white', height: '48px', width: '48px' };
    const isCNTrad: boolean =
      i18n.resolvedLanguage === languageCodes.chineseTraditional;
    const isCNSimp: boolean =
      i18n.resolvedLanguage === languageCodes.chineseSimplified;

    return (
      <div className="tournament-setting-container">

        <div className={`tournament-setting-q1-container ${questionsObj.q1 ? 'back-shadow' : ''}`}>
          <button id="ts1" onClick={() => { handleQuestionsObj('q1'); }}>
            <div className={`label-container ${questionsObj.q1 ? 'no-border' : ''}`}>
              <strong>{`1. ${t('q19')}`}</strong>
              {questionsObj.q1
                ? <KeyboardArrowDownIcon sx={iconStyle} />
                : <KeyboardArrowUpIcon sx={iconStyle} />}
            </div>
          </button>
          <AnimateHeight duration={animationDuration} height={ questionsObj.q1 ? 'auto' : 0 } >
            <section>
              <p>{t('faq19')}</p>
            </section>
          </AnimateHeight>
        </div>

        <div className={`tournament-setting-q2-container ${questionsObj.q2 ? 'back-shadow' : ''}`}>
          <button id="ts2" onClick={() => { handleQuestionsObj('q2'); }}>
            <div className={`label-container ${questionsObj.q2 ? 'no-border' : ''}`}>
              <strong>{`2. ${t('q20')}`}</strong>
              {questionsObj.q2
                ? <KeyboardArrowDownIcon sx={iconStyle} />
                : <KeyboardArrowUpIcon sx={iconStyle} />}
            </div>
          </button>
          <AnimateHeight duration={animationDuration} height={ questionsObj.q2 ? 'auto' : 0 } >
            <section>
              <p>{t('faq20')}</p>
            </section>
          </AnimateHeight>
        </div>

        <div className={`tournament-setting-q3-container ${questionsObj.q3 ? 'back-shadow' : ''}`}>
          <button id="ts3" onClick={() => { handleQuestionsObj('q3'); }}>
            <div className={`label-container ${questionsObj.q3 ? 'no-border' : ''}`}>
              <strong>{`3. ${t('q32')}`}</strong>
              {questionsObj.q3
                ? <KeyboardArrowDownIcon sx={iconStyle} />
                : <KeyboardArrowUpIcon sx={iconStyle} />}
            </div>
          </button>
          <AnimateHeight duration={animationDuration} height={ questionsObj.q3 ? 'auto' : 0 } >
            <section>
              <p>{t('faq32')}</p>
            </section>
          </AnimateHeight>
        </div>

        <div className={`tournament-setting-q4-container ${questionsObj.q4 ? 'back-shadow' : ''}`}>
          <button id="ts4" onClick={() => { handleQuestionsObj('q4'); }}>
            <div className={`label-container ${questionsObj.q4 ? 'no-border' : ''}`}>
              <strong>{`4. ${t('q23')}`}</strong>
              {questionsObj.q4
                ? <KeyboardArrowDownIcon sx={iconStyle} />
                : <KeyboardArrowUpIcon sx={iconStyle} />}
            </div>
          </button>
          <AnimateHeight duration={animationDuration} height={ questionsObj.q4 ? 'auto' : 0 } >

            <section className="custom-table">
              <div className="table-first-row">
                <p>{t('betItem')}</p>
                <p>{t('betLimit')}</p>
              </div>
              <div className="table-other-rows">
                <div className="table-row">
                  <p>{`${t('banker')}, ${t('player')}`}</p>
                  <p>1,000 - 10,000</p>
                </div>
                <div className="table-row">
                  <p>{`${t('tie')}, ${t('bankerPair')}, ${t('playerPair')}`}</p>
                  <p>100 - 1,000</p>
                </div>
              </div>
            </section>
          </AnimateHeight>
        </div>

        <div className={`tournament-setting-q5-container ${questionsObj.q5 ? 'back-shadow' : ''}`}>
          <button id="ts5" onClick={() => { handleQuestionsObj('q5'); }}>
            <div className={`label-container ${questionsObj.q5 ? 'no-border' : ''}`}>
              <strong>{`5. ${t('q24')}`}</strong>
              {questionsObj.q5
                ? <KeyboardArrowDownIcon sx={iconStyle} />
                : <KeyboardArrowUpIcon sx={iconStyle} />}
            </div>
          </button>
          <AnimateHeight duration={animationDuration} height={ questionsObj.q5 ? 'auto' : 0 } >
            <section>
              <p>{t('faq24')}</p>
            </section>
          </AnimateHeight>
        </div>

        <div className={`tournament-setting-q6-container ${questionsObj.q6 ? 'back-shadow' : ''}`}>
          <button id="ts6" onClick={() => { handleQuestionsObj('q6'); }}>
            <div className={`label-container ${questionsObj.q6 ? 'no-border' : ''}`}>
              <strong>{`6. ${t('q25')}`}</strong>
              {questionsObj.q6
                ? <KeyboardArrowDownIcon sx={iconStyle} />
                : <KeyboardArrowUpIcon sx={iconStyle} />}
            </div>
          </button>
          <AnimateHeight duration={animationDuration} height={ questionsObj.q6 ? 'auto' : 0 } >
            <section>
              <p>{t('faq25')}</p>
              <div className="images-container-row">
                {!isCNSimp && !isCNTrad &&
                  <>
                    <LazyLoadImage src={blind1en} alt="Blind Icon 1" width={passIconHeight} height={passIconHeight} effect="blur"/>
                    <LazyLoadImage src={blind2en} alt="Blind Icon 2" width="auto" height={passIconHeight} effect="blur"/>
                  </>}
                {isCNSimp &&
                  <>
                    <LazyLoadImage src={blind1cnSimp} alt="Blind Icon 1" width={passIconHeight} height={passIconHeight} effect="blur"/>
                    <LazyLoadImage src={blind2cnSimp} alt="Blind Icon 2" width="auto" height={passIconHeight} effect="blur"/>
                  </>}
                {isCNTrad &&
                  <>
                    <LazyLoadImage src={blind1cnTrad} alt="Blind Icon 1" width={passIconHeight} height={passIconHeight} effect="blur"/>
                    <LazyLoadImage src={blind2cnTrad} alt="Blind Icon 2" width="auto" height={passIconHeight} effect="blur"/>
                  </>}
              </div>
            </section>
          </AnimateHeight>
        </div>

        <div className={`tournament-setting-q7-container ${questionsObj.q7 ? 'back-shadow' : ''}`}>
          <button id="ts7" onClick={() => { handleQuestionsObj('q7'); }}>
            <div className={`label-container ${questionsObj.q7 ? 'no-border' : ''}`}>
              <strong>{`7. ${t('q26')}`}</strong>
              {questionsObj.q7
                ? <KeyboardArrowDownIcon sx={iconStyle} />
                : <KeyboardArrowUpIcon sx={iconStyle} />}
            </div>
          </button>
          <AnimateHeight duration={animationDuration} height={ questionsObj.q7 ? 'auto' : 0 } >
            <section>
              <p>{t('faq26')}</p>
              <div className="images-container-row">
                {!isCNSimp && !isCNTrad &&
                  <>
                    <LazyLoadImage src={pass1en} alt="Pass Icon 1" height={passIconWidth} width={passIconWidth} effect="blur"/>
                    <LazyLoadImage src={pass2en} alt="Pass Icon 2" height={passIconHeight} width="auto" effect="blur"/>
                  </>}
                {isCNTrad &&
                  <>
                    <LazyLoadImage src={pass1cnTrad} alt="Pass Icon 1" height={passIconWidth} width={passIconWidth} effect="blur"/>
                    <LazyLoadImage src={pass2cnTrad} alt="Pass Icon 2" height={passIconHeight} width="auto" effect="blur"/>
                  </>}
                {isCNSimp &&
                  <>
                    <LazyLoadImage src={pass1cnSimp} alt="Pass Icon 1" height={passIconWidth} width={passIconWidth} effect="blur"/>
                    <LazyLoadImage src={pass2cnSimp} alt="Pass Icon 2" height={passIconHeight} width="auto" effect="blur"/>
                  </>}
              </div>
            </section>
          </AnimateHeight>
        </div>

        <div className={`tournament-setting-q8-container ${questionsObj.q8 ? 'back-shadow' : ''}`}>
          <button id="ts8" onClick={() => { handleQuestionsObj('q8'); }}>
            <div className={`label-container ${questionsObj.q8 ? 'no-border' : ''}`}>
              <strong>{`8. ${t('q27')}`}</strong>
              {questionsObj.q8
                ? <KeyboardArrowDownIcon sx={iconStyle} />
                : <KeyboardArrowUpIcon sx={iconStyle} />}
            </div>
          </button>
          <AnimateHeight duration={animationDuration} height={ questionsObj.q8 ? 'auto' : 0 } >
            <section>
              <p>{t('faq27')}</p>
            </section>
          </AnimateHeight>
        </div>
      </div>
    );
  };

/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import MenuIcon from '@mui/icons-material/Menu';
// import MusicNoteIcon from '@mui/icons-material/MusicNote';
// import MusicOffIcon from '@mui/icons-material/MusicOff';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {
  Box,
  Button,
  ClickAwayListener,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@mui/material';
import Slider from '@mui/material/Slider';

import backgroundAudio from '../assets/audio/BGM_004_tournament_website_bgm_v13_final_mix_compressed.mp3';
import { HomeLogo } from '../assets/preheader/HomeLogo';
import enMtLogo from '../assets/preheader/web_mt_txt_menu_logo_en.webp';
import { type IPreHeader } from '../interfaces';
import { languageCodes, languages, websiteLanguageCodes } from '../languages/languages';

import '../styles/PreHeader.scss';

interface ILinksMenuComponent {
  anchor: 'left' | 'right'
}

export function PreHeader ({
  isMobileView, setShowRankings, showRankings, t, i18n, isChinese
}: IPreHeader): JSX.Element {
  const [play, setPlay] = useState<boolean>(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [isHoverLangIcon, setIsHoverLangIcon] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [volumeAnchorEl, setVolumeAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openVolumeMenu = Boolean(volumeAnchorEl);
  const textHighlightColor = '#FFD566';
  const MAX: number = 100;
  const [value, setValue] = React.useState<number>(0.25 * MAX);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleCloseVolumeMenu = (): void => {
    setVolumeAnchorEl(null);
  };

  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    function navHighlighter (): void {
      const sections: NodeListOf<HTMLElement> = document.querySelectorAll('section[id]');
      const scrollY: number = window.scrollY;

      sections.forEach((current) => {
        if (current instanceof HTMLElement) {
          const sectionHeight: number = current.offsetHeight;
          const sectionTop: number = current.offsetTop - 220;
          const sectionId: string | null = current.getAttribute('id');

          if (sectionId === null) return;
          if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
            document.querySelector(`.navigation a[href*=${sectionId}]`)
              ?.classList.add('active-link');
          } else {
            document.querySelector(`.navigation a[href*=${sectionId}]`)
              ?.classList.remove('active-link');
          }
        }
      });
    }

    window.addEventListener('scroll', navHighlighter);

    if (audioRef?.current !== null) audioRef.current.volume = 0.25;

    return () => {
      window.removeEventListener('scroll', navHighlighter);
    };
  }, []);

  const toggleDrawer = (): void => { setIsDrawerOpen(prev => !prev); };

  const changeLanguage = (languageCode: string): void => {
    void i18n.changeLanguage(languageCode);
    const params = new URLSearchParams(window.location.search);
    params.delete('lang');
    let newLangCode = languageCode;
    if (newLangCode === languageCodes.chineseSimplified) newLangCode = websiteLanguageCodes.chineseSimplified;
    if (newLangCode === languageCodes.chineseTraditional) newLangCode = websiteLanguageCodes.chineseTraditional;
    if (newLangCode === languageCodes.japanese) newLangCode = websiteLanguageCodes.japanese;
    if (newLangCode === languageCodes.vietnamese) newLangCode = websiteLanguageCodes.vietnamese;

    params.append('lang', newLangCode);

    const baseURL = window.location.origin;
    const pathName = window.location.pathname;
    const newURL = baseURL + pathName + `?${params.toString()}`;
    const websiteTitle = 'Asia Gaming';
    window.history.pushState({ Title: websiteTitle, Url: newURL }, websiteTitle, newURL);
  };
  const defaultTextColor = '#c7c6c6';
  const textColor: string = isHoverLangIcon ? textHighlightColor : defaultTextColor;

  const linksArray: string[] = ['gog', 'topMaster', 'introduction', 'details', 'faq'];

  const id = open ? 'simple-popper' : undefined;

  const handleTopMaster = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setShowRankings(true);
  };

  const toggleAudio = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setVolumeAnchorEl(event.currentTarget);
    if (play) {
      audioRef.current?.pause();
      setPlay(false);
    } else {
      void audioRef.current?.play();
      setPlay(true);
    }
  };

  const handleSliderChange = (event: Event, newValue: number | number[]): void => {
    setValue(newValue as number);
    const volume = newValue as number / MAX;
    if ((audioRef?.current) == null) return;
    audioRef.current.volume = volume;
  };

  function preventHorizontalKeyboardNavigation (event: React.KeyboardEvent): void {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
    }
  }

  const audioComponentMenu = (): JSX.Element => (
    <div className="audio-container">
      <button onClick={toggleAudio} className="audio-btn">
        {play ? <VolumeUpIcon sx={{ color: defaultTextColor }}/> : <VolumeOffIcon sx={{ color: defaultTextColor }}/>}
      </button>
      <Popper id="volume-menu"
        anchorEl={volumeAnchorEl}
        open={openVolumeMenu}
        sx={{ zIndex: 5, width: '5px', display: 'flex', justifyContent: 'center' }}>
        <ClickAwayListener onClickAway={handleCloseVolumeMenu}>
          <MenuItem>
            <Box sx={{ height: '100px', width: '5px', display: 'flex', justifyContent: 'center' }}>
              <Slider
                aria-label="Volume"
                value={value}
                orientation="vertical"
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                size="small"
                sx={{
                  color: defaultTextColor,
                  '& input[type="range"]': {
                    WebkitAppearance: 'slider-vertical'
                  }
                }}
                onKeyDown={preventHorizontalKeyboardNavigation}
              />
            </Box>
          </MenuItem>
        </ClickAwayListener>
      </Popper>
    </div>
  );

  const languageComponentMenu = (): JSX.Element => (
    <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: 5 }}>
      <Paper sx={{ background: 'black', color: defaultTextColor }}>
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList id="basic-menu">
            {languages.map(language => (
              <MenuItem key={language.name}
                sx={{ '&:hover': { color: textHighlightColor } }}
                onClick={() => {
                  handleClose();
                  changeLanguage(language.languageCode);
                }}>
                {language.name}
              </MenuItem>
            ))}
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );

  const linksMenuComponent = ({ anchor }: ILinksMenuComponent): JSX.Element => (
    <>
      <Button className="menu-icon-container" onClick={toggleDrawer}>
        <MenuIcon sx={{ color: defaultTextColor, height: '40px', width: '40px' }} />
      </Button>
      <Drawer anchor={anchor} open={isDrawerOpen} onClose={toggleDrawer}>
        <Box sx={{
          height: '100vh',
          width: '200px',
          overflow: 'hidden',
          color: defaultTextColor,
          background: 'black'
        }}
        role="presentation"
        onClick={toggleDrawer}
        onKeyDown={toggleDrawer}
        >
          <List>
            {linksArray.map(link => (
              <ListItem key={link} sx={{
                margin: '16px 0px',
                '&:hover a': {
                  color: textHighlightColor
                }
              }} disablePadding>
                <ListItemButton>
                  <a href={`#${link !== 'topMaster' && link !== 'gog' ? link : ''}`} onClick={() => {
                    setShowRankings(link === 'topMaster');
                  } }>{t(link)}</a>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );

  return (
    <div className='preheader' style={{ justifyContent: !isMobileView ? 'flex-end' : 'space-between' }}>
      <audio id="audio-background" ref={audioRef} preload="true" loop className="audio-background">
        <source src={backgroundAudio} type="audio/mpeg"/>
            Audio not supported
      </audio>
      {isMobileView && (linksMenuComponent({ anchor: 'left' }))}
      {!isMobileView && (
        <div className="right-container">
          <nav className="navigation" aria-label="Primary">
            <ul>
              <li>
                <a href="#" onClick={() => { setShowRankings(false); }}>
                  <div style={{ marginTop: '2px' }}>
                    {isChinese
                      ? <HomeLogo height="44px" width="66px"/>
                      : <img src={enMtLogo} alt="Master Tournament" height="42px" width="134px"/>}
                  </div>
                </a>
              </li>
              <li>
                <a href="#" onClick={handleTopMaster} className={`${showRankings ? 'active-link' : ''}`}>
                  {t('topMaster')}
                </a>
              </li>

              {linksArray.filter(item => item !== 'gog' && item !== 'topMaster').map(link => (
                <li key={link}>
                  <a href={`#${link}`}
                    onClick={() => { setShowRankings(false); }}>
                    {t(link)}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
          {audioComponentMenu()}
          <div className="drop-down" data-testid="languages-dropdown-btn">
            <IconButton
              id="basic-button"
              aria-controls={open ? 'simple-popper' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              aria-describedby={id}
              onMouseOver={() => { setIsHoverLangIcon(true); }}
              onMouseOut={() => { setIsHoverLangIcon(false); }}
              onClick={handleClick}
            >
              <LanguageIcon sx={{ color: textColor }}/>
              <p className={ isHoverLangIcon ? 'colored-text' : '' }>
                {languages.find(el => el.languageCode === i18n.resolvedLanguage)?.name}
              </p>
            </IconButton>
            {languageComponentMenu()}
          </div>
        </div>
      )}
      {isMobileView && (
        <div className="drop-down">
          {languageComponentMenu()}
          {audioComponentMenu()}
          <IconButton
            id="basic-button"
            aria-controls={open ? 'simple-popper' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            aria-describedby={id}
            onMouseOver={() => { setIsHoverLangIcon(true); }}
            onMouseOut={() => { setIsHoverLangIcon(false); }}
            onClick={handleClick}
          >
            <LanguageIcon sx={{ color: textColor }}/>
            <p style={{ color: textColor }}>
              {languages.find(el => el.languageCode === i18n.resolvedLanguage)?.name}
            </p>
          </IconButton>
        </div>
      )}
    </div>
  );
};
